import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Grid, IconButton, Paper, Typography } from '@mui/material';
import { flightStatusForCheckList } from "adoms-common-lib";
import React from 'react';

type FlightCheckListStatusProps = {
    setSelectFlightStatusIndex: React.Dispatch<React.SetStateAction<number>>
    selectFlightStatusIndex: number
}

/**
 * チェックリスト用フライトステータス表示
 * @param props 
 * @returns 
 */
export const FlightCheckListStatus: React.FC<FlightCheckListStatusProps> = (props) => {

    const handleNextFlightStatus = () => {
        props.setSelectFlightStatusIndex((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBackFlightStatus = () => {
        props.setSelectFlightStatusIndex((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <Grid item container alignItems={"center"} sm={12}>
            <Grid item>
                <IconButton
                    color='primary'
                    onClick={handleBackFlightStatus}
                    disabled={props.selectFlightStatusIndex === 0}
                    sx={{ padding: 0 }}
                >
                    <KeyboardArrowLeft fontSize='large' />
                </IconButton>
            </Grid>
            <Grid item>
                <Paper elevation={0}
                    sx={{
                        textAlign: "center",
                        display: "inline-block",
                        color: "#2196f3",
                        minWidth: "155px",
                        border: "1px solid #2196f3"
                    }}>
                    <Typography
                        variant='subtitle1'
                        sx={{
                            lineHeight: "2",
                            padding: "4px 10px"
                        }}>
                        {flightStatusForCheckList[props.selectFlightStatusIndex]}</Typography>
                </Paper>
            </Grid>
            <Grid item>
                <IconButton
                    color='primary'
                    onClick={handleNextFlightStatus}
                    disabled={props.selectFlightStatusIndex
                        === flightStatusForCheckList.length - 1}
                    sx={{ padding: 0 }}>
                    <KeyboardArrowRight fontSize='large' />
                </IconButton>
            </Grid>
        </Grid>
    );
};