import { Container, Typography } from "@mui/material";
import CssBaseline from '@mui/material/CssBaseline';
import { makeStyles } from 'tss-react/mui';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import React from 'react';
import OperationMenuBar from "../../components/organisms/OperationMenuBar";

const drawerWidth = 260;

const useStyles = makeStyles()((theme: any) => ({
    root: {
        display: 'flex',
        backgroundColor: theme.palette.background.default
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth
    },
    /**
     * メニューヘッダー
     */
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    main: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(2),
        textAlign: 'center'
    },
    typography: {
        whiteSpace: "break-spaces"
    }
}));

export default function Forbidden403Page() {
    const { classes, cx } = useStyles();
    const [isMenuOpen, setMenuOpen] = React.useState(false);

    /**
     * メニューバーが開かれたときのハンドラ
    */
    const handleDrawerOpen = () => {
        setMenuOpen(true);
    };

    /**
     * メニューバーが閉じられた時のハンドラ
     */
    const handleDrawerClose = () => {
        setMenuOpen(false);
    };

    return (
        <div className={classes.root}>
            <CssBaseline />
            {/**メニューバーを表示する */}
            <OperationMenuBar
                onChangeDrawerOpen={handleDrawerOpen}
                onChangeDrawerClose={handleDrawerClose}
                open={isMenuOpen}
                title="403 ERROR"
            />

            {/** コンテンツ部分 */}
            <main
                className={cx(classes.content, {
                    /** メニューバーがオープン・クローズされたときのスタイルの変更*/
                    [classes.contentShift]: isMenuOpen,
                })}
            >
                <div className={classes.drawerHeader} />
                <Container component="main" className={classes.main} maxWidth="sm">
                    <ReportProblemOutlinedIcon style={{ fontSize: 80 }} />
                    <Typography variant="h4" gutterBottom>
                        アクセスが禁止されています
                    </Typography>
                    <Typography variant="body1" className={classes.typography}>
                        入力されたURLをご確認ください。<br />アクセスできない場合は管理者へお知らせください。
                    </Typography>
                    <Typography variant="body1" style={{ opacity: 0.5 }}>
                        403 ERROR FORBIDDEN
                    </Typography>
                </Container>
            </main >
        </div>
    );
}