import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { BusinessPartnerInfo, OperationPartnerInfo, PartnerKind, UserPartnerInfo } from 'adoms-common-lib';
import React from "react";

type UserPartnerIdEditDialogProps = {
    isPartnerIdEditDialogOpen: boolean;
    handlePartnerIdEditDialogClose: () => void;
    userPartnerInfo: UserPartnerInfo | undefined;
    allOperationPartnerInfoList: OperationPartnerInfo[];
    allBusinessPartnerInfoList: BusinessPartnerInfo[];
    partnerIdErrorMessage: string | undefined;
    handleConfirmPartnerId: (event: any) => void;
    selectedPartnerId: string;
    setSelectedPartnerId: React.Dispatch<React.SetStateAction<string>>;
    selectedPartnerKind: PartnerKind
    setSelectedPartnerKind: React.Dispatch<React.SetStateAction<PartnerKind>>;
}

export const UserPartnerIdEditDialog: React.FC<UserPartnerIdEditDialogProps> = (props) => {

    /**
    * パートナー種別が変更された時
    * @param event 
    */
    const handlePartnerKindChange = (event: SelectChangeEvent<string>, child: React.ReactNode) => {
        props.setSelectedPartnerKind(event.target.value as PartnerKind);
    };

    /**
     * パートナーIDが変更された時
     * @param event 
     */
    const handlePartnerIdChange = (event: SelectChangeEvent<string>, child: React.ReactNode) => {
        props.setSelectedPartnerId(event.target.value as string);
    };

    /**
    * パートナーID登録のボタンdisableチェック
    */
    const checkPartnerIdSaveDisabled = (): boolean => {

        if (typeof props.selectedPartnerKind === "undefined" || typeof props.selectedPartnerId === "undefined") {
            return true;
        } else if (props.selectedPartnerKind === PartnerKind.Operation
            && ((props.userPartnerInfo?.partnerInfo as OperationPartnerInfo).operationPartnerId === props.selectedPartnerId
                || !props.allOperationPartnerInfoList.some(operationPartnerInfo => operationPartnerInfo.operationPartnerId === props.selectedPartnerId))) {
            // パートナー種別がオペレーションパートナーの場合、変更があること、オペレーションパートナーIDであることを確認する
            return true;
        } else if (props.selectedPartnerKind === PartnerKind.Business
            && ((props.userPartnerInfo?.partnerInfo as BusinessPartnerInfo).businessPartnerId === props.selectedPartnerId
                || !props.allBusinessPartnerInfoList.some(businessPartnerInfo => businessPartnerInfo.businessPartnerId === props.selectedPartnerId))) {
            // パートナー種別がビジネスパートナーの場合、変更があること、ビジネスパートナーIDであることを確認する
            return true;
        }
        return false;
    }

    /**
     * パートナーID選択Select
     */
    const partnerIdSelect = () => {

        if (props.selectedPartnerKind === PartnerKind.Operation) {
            return (
                <Select
                    variant="outlined"
                    labelId="partner-id-select-label"
                    id="partner-id-select"
                    value={props.selectedPartnerId}
                    label="パートナーID"
                    onChange={handlePartnerIdChange}>
                    {props.allOperationPartnerInfoList.map((operationPartnerInfo, i) => (
                        <MenuItem key={i} value={operationPartnerInfo.operationPartnerId}>{operationPartnerInfo.operationPartnerId}</MenuItem>
                    ))}
                </Select>
            );
        } else if (props.selectedPartnerKind === PartnerKind.Business) {
            return (
                <Select
                    variant="outlined"
                    labelId="partner-id-select-label"
                    id="partner-id-select"
                    value={props.selectedPartnerId}
                    label="パートナーID"
                    onChange={handlePartnerIdChange}>
                    {props.allBusinessPartnerInfoList.map((businessPartnerInfo, i) => (
                        <MenuItem key={i} value={businessPartnerInfo.businessPartnerId}>{businessPartnerInfo.businessPartnerId}</MenuItem>
                    ))}
                </Select>
            );
        } else {
            return (
                <Select
                    variant="outlined"
                    labelId="partner-id-select-label"
                    id="partner-id-select"
                    label="パートナーID"
                    disabled>
                </Select>
            );
        }
    };

    return (
        <Dialog
            open={props.isPartnerIdEditDialogOpen}
            onClose={props.handlePartnerIdEditDialogClose}>
            <DialogTitle
                sx={{ padding: "16px 24px 0px" }}
                id="dialog-title">パートナーID選択
                <Divider />
            </DialogTitle>
            <DialogContent
                id="dialog-content"
                style={{ padding: "16px 24px 20px" }}
            >
                <Grid container spacing={2} direction="column">
                    {props.partnerIdErrorMessage ?
                        <Grid item>
                            <Alert
                                severity="error">{props.partnerIdErrorMessage}</Alert>
                        </Grid>
                        : undefined
                    }
                    <Grid item>
                        <FormControl sx={{ minWidth: 250 }}>
                            <InputLabel id="partner-kind-select-label">パートナー種別</InputLabel>
                            <Select
                                variant="outlined"
                                labelId="partner-kind-select-label"
                                id="partner-kind-select"
                                value={props.selectedPartnerKind}
                                label="パートナー種別"
                                onChange={handlePartnerKindChange}>
                                <MenuItem value={PartnerKind.Operation}>オペレーションパートナー</MenuItem>
                                <MenuItem value={PartnerKind.Business}>ビジネスパートナー</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl sx={{ width: 250 }}>
                            <InputLabel id="partner-id-select-label">パートナーID</InputLabel>
                            {partnerIdSelect()}
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    color="secondary"
                    onClick={props.handleConfirmPartnerId}
                    disabled={checkPartnerIdSaveDisabled()}
                >
                    登録
                </Button>
            </DialogActions>
        </Dialog >
    );
}