import React from "react";
import { Backdrop, CircularProgress } from "@mui/material"

type LoadingMarkProps = {
    isDisplayLoadingMark: boolean
};

export const LoadingMark: React.FC<LoadingMarkProps> = (props) => {
    return (
        <Backdrop
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: '#fff' }}
            open={props.isDisplayLoadingMark}>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
};