import { Alert, Box, Checkbox, Chip, Collapse, Divider, IconButton, List, ListItem, ListItemText, Stack, Tab, Table, TableBody, TableCell, TableRow, Tabs, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { APIConnector } from "../../connector/APIConnector";
import { BusinessPartnerInfo, CertifiedUserInfo, GroupInfo, OperationPartnerInfo, PartnerKind, UserInfo, UserPartnerInfo, isCertifiedUserInfo } from 'adoms-common-lib';
import axios from 'axios';
import React from "react";
import { TabPanel } from '../atoms/TabPanel';
import { UserGroupEditDialog } from './UserGroupEditDialog';
import { UserPartnerIdEditDialog } from './UserPartnerIdEditDialog';
import { UserCertificateNumEditDialog } from './UserCertificationNumEditDialog';

const useStyles = makeStyles()((theme: Theme) =>
({
    list: {
        minWidth: 420,
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
        maxHeight: 170,
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
    tabRoot: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        height: 234,
        width: 650,
    },
    chip: {
        margin: 2,
    }
}));

type userDetailTableProps = {
    userInfo: UserInfo;
    allGroupInfoList: GroupInfo[];
    allOperationPartnerInfoList: OperationPartnerInfo[];
    allBusinessPartnerInfoList: BusinessPartnerInfo[];
    isSaveUserInfo: boolean;
    setSaveUserInfo: React.Dispatch<React.SetStateAction<boolean>>;
    selectedUserInfoList: UserInfo[];
    setSelectedUserInfoList: React.Dispatch<React.SetStateAction<UserInfo[]>>;
    isDisplayCheckBox: boolean;
    isDisableCheckBox: boolean;
    canUpdateUserInfoLoginUser: boolean;
}

/**
 * ユーザー詳細情報テーブル表示用コンポーネント
 * @param props:参照用プロパティ
 */
export const UserDetailTableRow: React.FC<userDetailTableProps> = (props) => {

    const { classes } = useStyles();

    // パートナーID編集用
    const [selectedPartnerKind, setSelectedPartnerKind] = React.useState<PartnerKind>(props.userInfo.userPartnerInfo?.partnerKind);
    const [isPartnerIdEditDialogOpen, setPartnerIdEditDialogOpen] = React.useState(false);
    const [partnerIdErrorMessage, setPartnerIdErrorMessage] = React.useState<string | undefined>();
    const [selectedPartnerId, setSelectedPartnerId] = React.useState<string>("");

    // グループ編集用
    const [isGroupEditDialogOpen, setGroupEditDialogOpen] = React.useState(false);
    const [checkedGroupInfoList, setCheckedGroupInfoList] = React.useState<GroupInfo[]>([...props.userInfo.groupInfoList]);
    const [checkedGroupInfoDisplaySubIdList, setCheckedGroupInfoDisplaySubIdList] = React.useState<string[]>([]);
    const [groupErrorMessage, setGroupErrorMessage] = React.useState<string | undefined>("");

    // 技能証明書番号編集用
    const [isCertificateNumEditDialogOpen, setCertificateNumEditDialogOpen] = React.useState(false);
    const [certificateNum, setCertificateNum] = React.useState<string>(isCertifiedUserInfo(props.userInfo)
        ? (props.userInfo as CertifiedUserInfo).competenceCertificateNumber : "");
    const [certificateNumErrorMessage, setCertificateNumErrorMessage] = React.useState<string | undefined>("");

    const [isCellOpen, setCellOpen] = React.useState(false);
    const [tabValue, setTabValue] = React.useState(0);

    /**
    * パートナーIDを編集するダイアログを開く
    */
    const handlePartnerIdEditDialogOpen = () => {
        if (selectedPartnerKind === PartnerKind.Operation) {
            setSelectedPartnerId((props.userInfo.userPartnerInfo.partnerInfo as OperationPartnerInfo).operationPartnerId)
        } else if (selectedPartnerKind === PartnerKind.Business) {
            setSelectedPartnerId((props.userInfo.userPartnerInfo.partnerInfo as BusinessPartnerInfo).businessPartnerId)
        };
        setPartnerIdEditDialogOpen(true);
    };

    /**
    * パートナーIDを編集するダイアログを閉じる
    */
    const handlePartnerIdEditDialogClose = () => {
        setPartnerIdEditDialogOpen(false);
        setPartnerIdErrorMessage(undefined);
        setSelectedPartnerKind(props.userInfo.userPartnerInfo?.partnerKind);
        if (selectedPartnerKind === PartnerKind.Operation) {
            setSelectedPartnerId((props.userInfo.userPartnerInfo.partnerInfo as OperationPartnerInfo).operationPartnerId)
        } else if (selectedPartnerKind === PartnerKind.Business) {
            setSelectedPartnerId((props.userInfo.userPartnerInfo.partnerInfo as BusinessPartnerInfo).businessPartnerId)
        };
    };

    /**
     * パートナーID登録
     */
    const handleConfirmPartnerId = () => {
        setPartnerIdErrorMessage(undefined);

        let userPartnerInfo: UserPartnerInfo = {
            partnerKind: PartnerKind.Operation,
            partnerInfo: {
                operationPartnerId: "",
                operationPartnerName: "",
                tenantId: "",
                visibleBusinessPartnerInfoList: [],
                sortNumber: 0
            }
        };
        if (selectedPartnerKind === PartnerKind.Operation) {
            // パートナー種別「Operation」を登録する
            userPartnerInfo.partnerKind = PartnerKind.Operation;
            // 選ばれたパートナーIDと同じパートナー情報を設定する
            userPartnerInfo.partnerInfo = props.allOperationPartnerInfoList.find((operationPartnerInfo) => {
                return operationPartnerInfo.operationPartnerId === selectedPartnerId;
            })!;
        } else if (selectedPartnerKind === PartnerKind.Business) {
            // パートナー種別「Business」を登録する
            userPartnerInfo.partnerKind = PartnerKind.Business;
            // 選ばれたパートナーIDと同じパートナー情報を設定する
            userPartnerInfo.partnerInfo = props.allBusinessPartnerInfoList.find((businessPartnerInfo) => {
                return businessPartnerInfo.businessPartnerId === selectedPartnerId;
            })!;
        };

        const fetchData = async () => {
            let c: APIConnector = APIConnector.instance;
            let userInfo: UserInfo = {
                sub: props.userInfo.sub,
                tenantId: props.userInfo.tenantId,
                email: props.userInfo.email,
                name: props.userInfo.name,
                groupInfoList: props.userInfo.groupInfoList,
                userPartnerInfo: userPartnerInfo,
                privilege: props.userInfo.privilege
            }
            try {
                await c.putUserPartnerIdList(userInfo);
                setPartnerIdEditDialogOpen(false);
                props.setSaveUserInfo(true);
            } catch (e) {
                console.log("error: " + e);
                if (axios.isAxiosError(e)
                    && typeof e.response !== "undefined"
                    && e.response.status === 403) {
                    setPartnerIdErrorMessage("権限がありません");
                } else {
                    setPartnerIdErrorMessage("システムエラーが発生しました");
                };
            };
        };
        fetchData();
    };

    /**
     * グループを編集し権限を登録するダイアログを開く
     */
    const handleGroupEditDialogOpen = () => {
        setGroupEditDialogOpen(true);
        let groupInfoDisplaySubIdList = new Array();
        for (let groupInfo of props.userInfo.groupInfoList) {
            groupInfoDisplaySubIdList.push(groupInfo.data.displaySubId);
        };
        setCheckedGroupInfoDisplaySubIdList([...groupInfoDisplaySubIdList]);
    };

    /**
     * グループを編集し権限を登録するダイアログを閉じる
     */
    const handleGroupEditDialogClose = () => {
        if (!props.isSaveUserInfo) {
            setCheckedGroupInfoList([...props.userInfo.groupInfoList]);
        }
        setGroupEditDialogOpen(false);
        setGroupErrorMessage(undefined);
    };

    /**
     * グループ登録のボタンdisableチェック
     * @returns 
     */
    const checkGroupSaveDisabled = (haveGroupInfoListForCheck: GroupInfo[]): boolean => {
        // 1つ以上groupがあるか確認する
        if (haveGroupInfoListForCheck.length === 0) {
            return true;
        };

        // 変更を確認する
        if (props.userInfo.groupInfoList.length === haveGroupInfoListForCheck.length) {
            let differenceArray = props.userInfo.groupInfoList.filter(
                group => (haveGroupInfoListForCheck.findIndex(haveGroup => haveGroup.id === group.id) === -1)
            );
            if (differenceArray.length === 0) {
                //差分が存在しないためエラー
                return true;
            };
        };
        return false;
    };

    /**
     * グループを編集し権限を登録する
    */
    const handleConfirmGroup = () => {
        setGroupErrorMessage(undefined);

        const fetchData = async () => {
            let c: APIConnector = APIConnector.instance;
            let userInfo: UserInfo = {
                sub: props.userInfo.sub,
                tenantId: props.userInfo.tenantId,
                email: props.userInfo.email,
                name: props.userInfo.name,
                groupInfoList: checkedGroupInfoList,
                userPartnerInfo: props.userInfo.userPartnerInfo,
                privilege: props.userInfo.privilege
            }
            try {
                await c.putUserGroupList(userInfo);
                setGroupEditDialogOpen(false);
                props.setSaveUserInfo(true);
            } catch (e) {
                console.log("error: " + e);
                if (axios.isAxiosError(e)
                    && typeof e.response !== "undefined"
                    && e.response.status === 403) {
                    setGroupErrorMessage("権限がありません");
                } else {
                    setGroupErrorMessage("システムエラーが発生しました");
                };
            };
        };
        fetchData();
    };

    /**
    * パートナーIDを編集するダイアログを開く
    */
    const handleCertificateNumEditDialogOpen = () => {
        setCertificateNumEditDialogOpen(true);
    };

    /**
    * パートナーIDを編集するダイアログを閉じる
    */
    const handleCertificateNumEditDialogClose = () => {
        setCertificateNumEditDialogOpen(false);
        setCertificateNumErrorMessage(undefined);
        setCertificateNum(isCertifiedUserInfo(props.userInfo)
            ? (props.userInfo as CertifiedUserInfo).competenceCertificateNumber : "");
    };

    /**
     * 技能証明書番号登録のボタンdisableチェック
     * @returns 
     */
    const checkCertificateNumSaveDisabled = (): boolean => {
        if (isCertifiedUserInfo(props.userInfo)) {
            // 資格保有者の場合
            if (certificateNum
                !== (props.userInfo as CertifiedUserInfo).competenceCertificateNumber) {
                return false;
            } else {
                return true;
            };
        } else if (certificateNum) {
            // 資格保有者でない場合、かつhaveCertificateNumForCheckが空ではない場合
            return false;
        } else {
            // 資格保有者でない場合、かつhaveCertificateNumForCheckが空の場合
            return true;
        };
    };

    /**
     * 技能証明書番号を編集し権限を登録する
     */
    const handleConfirmCertificateNum = () => {
        setCertificateNumErrorMessage(undefined);

        const fetchData = async () => {
            let c: APIConnector = APIConnector.instance;

            try {
                await c.updateUserInfo(props.userInfo.sub, certificateNum);
                setCertificateNumEditDialogOpen(false);
                props.setSaveUserInfo(true);
            } catch (e) {
                console.log("error: " + e);
                if (axios.isAxiosError(e)
                    && typeof e.response !== "undefined"
                    && e.response.status === 403) {
                    setCertificateNumErrorMessage("権限がありません");
                } else {
                    setCertificateNumErrorMessage("システムエラーが発生しました");
                };
            };
        };
        fetchData();
    };

    /**
     * ユーザーの所有する権限の「使える権限」、「見える情報」のタブを切り替える
     * @param event 
     * @param newTabValue 
     */
    const handleTabChange = (event: React.ChangeEvent<{}>, newTabValue: number) => {
        setTabValue(newTabValue);
    };

    /**
     * idを作成する
     * @param index 
     */
    const a11yProps = (index: any) => {
        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`,
        };
    };

    /**
     * 表示するパートナーIDを設定する
     * @returns パートナー種別、パートナーID
     */
    const displayPartnerId = () => {
        if (props.userInfo.userPartnerInfo?.partnerKind === PartnerKind.Operation) {
            return (
                <React.Fragment>
                    <Chip label="Operation" color="primary" />
                    <Chip label={(props.userInfo.userPartnerInfo.partnerInfo as OperationPartnerInfo).operationPartnerId}
                        variant="outlined" />
                </React.Fragment>
            )
        } else if (props.userInfo.userPartnerInfo?.partnerKind === PartnerKind.Business) {
            return (
                <React.Fragment>
                    <Chip label="Business" color="primary" />
                    <Chip label={(props.userInfo.userPartnerInfo.partnerInfo as BusinessPartnerInfo).businessPartnerId}
                        variant="outlined" />
                </React.Fragment>
            )
        } else {
            return "登録なし"
        };
    };

    /**
     * 表示する閲覧可能なビジネスパートナーIDを設定する
     * @returns パートナーID
     */
    const displayVisibleBusinessPartnerId = (): string => {
        let visibleBusinessPartnerId: string = "";
        if (props.userInfo.userPartnerInfo?.partnerKind === PartnerKind.Operation) {
            // パートナー種別がオペレーションパートナーの場合、閲覧可能なビジネスパートナーIDをコンマでつなげて返す
            let visibleBusinessPartnerIdList = [...(props.userInfo.userPartnerInfo.partnerInfo as OperationPartnerInfo).visibleBusinessPartnerInfoList];
            visibleBusinessPartnerIdList.map((businessPartnerInfo, i) =>
                visibleBusinessPartnerId = visibleBusinessPartnerId + businessPartnerInfo.businessPartnerId
                + (visibleBusinessPartnerIdList.length - 1 !== i ? ", " : ""));
        } else if (props.userInfo.userPartnerInfo?.partnerKind === PartnerKind.Business) {
            visibleBusinessPartnerId = (props.userInfo.userPartnerInfo.partnerInfo as BusinessPartnerInfo).businessPartnerId!;
        } else {
            visibleBusinessPartnerId = "登録なし"
        };
        return visibleBusinessPartnerId;
    };

    /**
     * チェックボックスがクリックされた場合
     * @param event 
     * @param userInfo 
     */
    const handleClickUserInfo = (userInfo: UserInfo) => {
        let selectedUserInfoList = [...props.selectedUserInfoList];
        console.log("selectedUserInfoList:" + JSON.stringify(selectedUserInfoList));
        if (props.selectedUserInfoList.some(selectedUserInfo => {
            return userInfo.sub === selectedUserInfo.sub
        })) {
            // selectedUserInfoListにユーザー情報が存在する場合、ユーザー情報を削除する
            selectedUserInfoList = selectedUserInfoList.filter(selectedUserInfo => {
                return selectedUserInfo.sub !== userInfo.sub
            });
        } else {
            // selectedUserInfoListにユーザー情報が存在しない場合、ユーザー情報を追加する
            selectedUserInfoList.push(userInfo);
            props.setSelectedUserInfoList(selectedUserInfoList);
        };
        props.setSelectedUserInfoList(selectedUserInfoList);
    };

    /**
     * チェックボックスにチェックが入れられているか判定
     */
    const isUserInfoSelected = props.selectedUserInfoList.some(selectedUserInfo => {
        return props.userInfo.sub === selectedUserInfo.sub
    });
    const labelId = `enhanced-table-checkbox-${props.userInfo.sub}`;

    return (
        <React.Fragment>
            <TableRow>
                {props.isDisplayCheckBox ?
                    <TableCell padding="checkbox">
                        <Checkbox
                            checked={isUserInfoSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                            onChange={() => handleClickUserInfo(props.userInfo)}
                            disabled={props.isDisableCheckBox}
                        />
                    </TableCell>
                    : undefined}
                <TableCell align="left">
                    {props.userInfo.name}
                </TableCell>
                <TableCell align="left">
                    {props.userInfo.email}
                </TableCell>
                <TableCell align="left">
                    <Stack direction="row" alignItems={"center"} spacing={1}>
                        {displayPartnerId()}
                        {props.canUpdateUserInfoLoginUser ?
                            <IconButton
                                aria-label="operationPartnerIdEdit"
                                onClick={handlePartnerIdEditDialogOpen}
                                size="large">
                                <EditIcon fontSize="small" />
                            </IconButton>
                            : undefined}
                    </Stack>
                </TableCell>
                <TableCell align="left">
                    {(typeof props.userInfo.groupInfoList !== "undefined" && props.userInfo.groupInfoList?.length !== 0) ?
                        (props.userInfo.groupInfoList?.map((groupInfo, i) =>
                            <Chip
                                key={groupInfo.data.displaySubId}
                                label={groupInfo.data.displaySubId}
                                className={classes.chip}
                            />))
                        : ("登録なし")}
                    {props.canUpdateUserInfoLoginUser ?
                        <IconButton aria-label="groupEdit" onClick={handleGroupEditDialogOpen} size="large">
                            <EditIcon fontSize="small" />
                        </IconButton>
                        : undefined}
                </TableCell>
                <TableCell align="left">
                    {isCertifiedUserInfo(props.userInfo)
                        ? (props.userInfo as CertifiedUserInfo).competenceCertificateNumber
                        : "設定なし"}
                    {props.canUpdateUserInfoLoginUser ?
                        <IconButton aria-label="groupEdit" onClick={handleCertificateNumEditDialogOpen} size="large">
                            <EditIcon fontSize="small" />
                        </IconButton>
                        : undefined}
                </TableCell>
                <TableCell align="right">
                    <IconButton aria-label="expand row" size="small" onClick={() => setCellOpen(!isCellOpen)}>
                        {isCellOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: "#f0f8ff" }} colSpan={7}>
                    <Collapse in={isCellOpen} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Table size="small" aria-label="purchases">
                                <TableBody>
                                    <TableRow>
                                        <TableCell component="th" scope="row" style={{ width: 130 }}><b>閲覧可能なビジネス<br />パートナーID</b></TableCell>
                                        <TableCell>
                                            {displayVisibleBusinessPartnerId()}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row" style={{ width: 130, borderBottom: "0px none" }}><b>権限一覧</b></TableCell>
                                        <TableCell style={{ borderBottom: "0px none", paddingTop: 14 }}>
                                            {props.userInfo.privilege.functionPrivilegeInfoList.length === 0
                                                && props.userInfo.privilege.dataPrivilegeInfoList.length === 0 ?
                                                <Alert severity="info">登録されている権限はありません</Alert>
                                                :
                                                <div className={classes.tabRoot}>
                                                    <Tabs
                                                        orientation="vertical"
                                                        aria-label="Vertical tabs example"
                                                        value={tabValue}
                                                        onChange={handleTabChange}
                                                        className={classes.tabs}
                                                        centered
                                                        indicatorColor="primary"
                                                        variant="fullWidth"
                                                        textColor="primary"
                                                    >
                                                        <Tab label="使える機能" {...a11yProps(0)} />
                                                        <Tab label="見える情報" {...a11yProps(1)} />
                                                    </Tabs>
                                                    <TabPanel value={tabValue} index={0}>
                                                        <List className={classes.list}>
                                                            {props.userInfo.privilege.functionPrivilegeInfoList.length !== 0 ?
                                                                props.userInfo.privilege.functionPrivilegeInfoList.map((functionPrivilegeInfo, i) => (
                                                                    <React.Fragment key={i}>
                                                                        <ListItem>
                                                                            <ListItemText
                                                                                primary={functionPrivilegeInfo.data.sortNumber + functionPrivilegeInfo.data.displaySubId}
                                                                                primaryTypographyProps={{ variant: 'body2' }} />
                                                                        </ListItem>
                                                                        <Divider component="li" style={{ marginRight: "5%" }} />
                                                                    </React.Fragment>
                                                                ))
                                                                :
                                                                <Alert severity="info">権限の必要な機能は使用できません</Alert>
                                                            }
                                                        </List>
                                                    </TabPanel>
                                                    <TabPanel value={tabValue} index={1}>
                                                        <List className={classes.list} >
                                                            {props.userInfo.privilege.dataPrivilegeInfoList.map((dataPrivilegeInfo, i) => (
                                                                <React.Fragment key={i}>
                                                                    <ListItem>
                                                                        <ListItemText
                                                                            primary={dataPrivilegeInfo.data.sortNumber + dataPrivilegeInfo.data.displaySubId}
                                                                            primaryTypographyProps={{ variant: 'body2' }} />
                                                                    </ListItem>
                                                                    <Divider component="li" style={{ marginRight: "5%" }} />
                                                                </React.Fragment>
                                                            ))
                                                            }
                                                        </List>
                                                    </TabPanel>
                                                </div>
                                            }
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>

            <UserPartnerIdEditDialog
                isPartnerIdEditDialogOpen={isPartnerIdEditDialogOpen}
                handlePartnerIdEditDialogClose={handlePartnerIdEditDialogClose}
                userPartnerInfo={props.userInfo.userPartnerInfo}
                allOperationPartnerInfoList={props.allOperationPartnerInfoList}
                allBusinessPartnerInfoList={props.allBusinessPartnerInfoList}
                partnerIdErrorMessage={partnerIdErrorMessage}
                handleConfirmPartnerId={handleConfirmPartnerId}
                selectedPartnerId={selectedPartnerId}
                setSelectedPartnerId={setSelectedPartnerId}
                selectedPartnerKind={selectedPartnerKind}
                setSelectedPartnerKind={setSelectedPartnerKind}
            />
            <UserGroupEditDialog
                allGroupInfoList={props.allGroupInfoList}
                isGroupEditDialogOpen={isGroupEditDialogOpen}
                handleGroupEditDialogClose={handleGroupEditDialogClose}
                checkedGroupInfoList={checkedGroupInfoList}
                setCheckedGroupInfoList={setCheckedGroupInfoList}
                checkedGroupInfoDisplaySubIdList={checkedGroupInfoDisplaySubIdList}
                setCheckedGroupInfoDisplaySubIdList={setCheckedGroupInfoDisplaySubIdList}
                groupErrorMessage={groupErrorMessage}
                handleConfirmGroup={handleConfirmGroup}
                checkGroupSaveDisabled={checkGroupSaveDisabled}
            />

            <UserCertificateNumEditDialog
                isCertificateNumEditDialogOpen={isCertificateNumEditDialogOpen}
                certificateNum={certificateNum}
                setCertificateNum={setCertificateNum}
                handleCertificateNumEditDialogClose={handleCertificateNumEditDialogClose}
                certificateNumErrorMessage={certificateNumErrorMessage}
                checkCertificateNumSaveDisabled={checkCertificateNumSaveDisabled}
                handleConfirmCertificateNum={handleConfirmCertificateNum}
            />
        </React.Fragment >
    );
}