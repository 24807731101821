import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TimelineContent } from '@mui/lab';
import { AssosiateFlightAndOrder, FlightInfo, TimelineStatus } from "adoms-common-lib";
import React from 'react';
import { timelineComponent } from '../organisms/FlightTimeline';

const useStyles = makeStyles()((theme: any) => ({
    paper: {
        padding: '6px 16px',
        display: 'inline-block',
        minWidth: "200px"
    },
    userInfoTypography: {
        wordWrap: "break-word",
        color: theme.palette.text.secondary
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
        marginRight: theme.spacing(1),
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
}));

type FlightTimelineContentProps = {
    timelineComponent: timelineComponent
}

export const FlightTimelineContent: React.FC<FlightTimelineContentProps> = (props) => {
    const { classes } = useStyles();
    const [open, setOpen] = React.useState(false);
    const [openAccordion, setOpenAccordion] = React.useState(false);

    const timelineOperationDetail = (
        status: TimelineStatus,
        assosiateFlightAndOrder?: AssosiateFlightAndOrder,
        newFlightInfo?: FlightInfo,
        oldFlightInfo?: FlightInfo,
    ) => {
        let timelineOperationDetail;
        if (TimelineStatus.NewFlight === status) {
            if (newFlightInfo) {
                timelineOperationDetail = (
                    <Typography variant="subtitle2">
                        <b>[フライト情報]</b>
                        <br />
                        <b>・フライトID：</b>{newFlightInfo.id}
                        <br />
                        <b>・フライトステータス：</b>{newFlightInfo.status}
                        <br />
                        <b>・使用機材：</b>{newFlightInfo.drone.label}
                        <br />
                        <b>・最大ペイロード：</b>{newFlightInfo.drone.payload}
                        <br />
                        <b>・区間：</b >{newFlightInfo.departure.name}⇒{newFlightInfo.arrival.name}
                        <br />
                        <b>・STD - STA：</b >{newFlightInfo.std}-{newFlightInfo.sta}
                        <br />
                        <b>・ETD - ETA：</b >{newFlightInfo.etd}-{newFlightInfo.eta}
                        <br />
                        <b>・フライトディレクター：</b>{newFlightInfo.fd.name}
                    </Typography>
                );
            };
        } else if (TimelineStatus.AddOrder === status || TimelineStatus.RemoveOrder === status) {
            if (assosiateFlightAndOrder) {
                timelineOperationDetail = (
                    <Typography variant="subtitle2">
                        <b>[配送情報]</b>
                        <br />
                        <b>確認番号：</b>{assosiateFlightAndOrder.orderId}
                    </Typography>
                );
            };
        } else if (TimelineStatus.Standby === status || TimelineStatus.BeforePreparation === status
            || TimelineStatus.Preparation === status || TimelineStatus.BeforeTakeoff === status
            || TimelineStatus.Takeoff === status || TimelineStatus.InFlightClimb === status
            || TimelineStatus.InFlightCruise === status || TimelineStatus.InFlightDescent === status
            || TimelineStatus.AfterLanding === status || TimelineStatus.Completed === status
            || TimelineStatus.Reset === status || TimelineStatus.Irregular === status
            || TimelineStatus.Canceled === status) {
            if (newFlightInfo && oldFlightInfo) {
                timelineOperationDetail = (
                    <Typography variant="subtitle2">
                        <b>[フライトステータス]</b>
                        <br />
                        {oldFlightInfo.status}
                        <br />
                        ⇓
                        <br />
                        {newFlightInfo.status}
                    </Typography>
                );
            };
        };
        return timelineOperationDetail;
    };

    const handleAccordionChange = () => {
        setOpenAccordion(!openAccordion);
    }


    return (
        <TimelineContent>
            <Accordion expanded={openAccordion} onChange={handleAccordionChange}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <div style={{ flexWrap: "wrap" }}>
                        <Typography className={classes.heading}><b >{props.timelineComponent.operation}</b ></Typography>
                        <Typography className={classes.secondaryHeading}>{props.timelineComponent.updateUserInfo?.name}</Typography>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        {timelineOperationDetail(
                            props.timelineComponent.status,
                            props.timelineComponent.assosiateFlightAndOrder ? props.timelineComponent.assosiateFlightAndOrder : undefined,
                            props.timelineComponent.newFlightInfo ? props.timelineComponent.newFlightInfo : undefined,
                            props.timelineComponent.oldFlightInfo ? props.timelineComponent.oldFlightInfo : undefined)}
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </TimelineContent>
    )
};