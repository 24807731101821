import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonAddDisabledIcon from '@mui/icons-material/PersonAddDisabled';
import { Alert, Backdrop, Breadcrumbs, Button, Checkbox, CircularProgress, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import { ADOMS_ADOMINISTRATOR_NAME, BusinessPartnerInfo, FunctionPrivilege, GroupInfo, OperationPartnerInfo, UserInfo } from 'adoms-common-lib';
import axios from 'axios';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { havePrivilege } from '../../../common/PrivilegeUtil';
import { useUserInfoContext } from '../../../common/UserContext';
import { UserDetailTableRow } from '../../../components/molecule/UserDetailTableRow';
import OperationMenuBar from '../../../components/organisms/OperationMenuBar';
import { SignUpDialog } from '../../../components/organisms/SignUpDialog';
import { UserDeleteDialog } from '../../../components/organisms/UserDeleteDialog';
import { APIConnector } from '../../../connector/APIConnector';

const drawerWidth = 260;

const useStyles = makeStyles()((theme: any) => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.background.default
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  deliveryTable: {
    minWidth: 1024,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  newButton: {
    position: 'fixed',
    top: theme.spacing(10),
    right: theme.spacing(25),
  },
  deleteButton: {
    position: 'fixed',
    top: theme.spacing(10),
    right: theme.spacing(3),
    backgroundColor: '#ff2d05',
    color: '#ffffff',
  },
  paper: {
    padding: '12px',
    textAlign: 'center',
    height: '100%'
  },
  griditem: {
    marginBottom: theme.spacing(2)
  },
}));

/**
 * ユーザー一覧
 */
export default function UserListView() {
  const { classes, cx } = useStyles();
  const userInfoContext = useUserInfoContext();
  const navigate = useNavigate();

  const [isOpen, setOpen] = React.useState(false);
  const [allUserInfoList, setAllUserInfoList] = React.useState<UserInfo[]>([]);
  const [allGroupInfoList, setAllGroupInfoList] = React.useState<GroupInfo[]>([]);
  const [allOperationPartnerInfoList, setAllOperationPartnerInfoList] = React.useState<OperationPartnerInfo[]>([]);
  const [allBusinessPartnerInfoList, setAllBusinessPartnerInfoList] = React.useState<BusinessPartnerInfo[]>([]);
  const [isSaveUserInfo, setSaveUserInfo] = React.useState(false);
  const [logInUserInfo, setLogInUserInfo] = React.useState<UserInfo | undefined>(userInfoContext.userInfo);
  const [canUpdateUserInfoLoginUser, setUpdateUserInfoLoginUser] = React.useState(false);
  const [selectedUserInfoList, setSelectedUserInfoList] = React.useState<UserInfo[]>([]);
  const [isSignUpDialogOpen, setSignUpDialogOpen] = React.useState(false);
  const [isUserDeleteDialogOpen, setUserDeleteDialogOpen] = React.useState(false);
  const [isDisplayLoadingMarkForUserInfoList, setDisplayLoadingMarkForUserInfoList] = React.useState(false);
  const [isDisplayLoadingMarkForLoginUserInfo, setDisplayLoadingMarkForLoginUserInfo] = React.useState(false);
  const [userInfoListErrorMessage, setUserInfoListErrorMessage] = React.useState<string | undefined>();
  const [masterInfoErrorMessage, setMasterInfoErrorMessage] = React.useState<string | undefined>();
  const [loginUserErrorMessage, setLoginUserErrorMessage] = React.useState<string | undefined>();

  /**
   * ユーザー一覧を取得
   * （ユーザー情報を更新する度に取得される）
   */
  useEffect(() => {
    const fetchData = async () => {
      try {
        setDisplayLoadingMarkForUserInfoList(true);
        setSaveUserInfo(false);

        const c: APIConnector = APIConnector.instance;
        const userInfoList = await c.getUserList();
        console.log("userInfoList: " + JSON.stringify(userInfoList))

        // ADOMS管理者のindexを取得する
        const adomsAdministratorIndex = userInfoList.findIndex((userInfo) =>
          userInfo.name === ADOMS_ADOMINISTRATOR_NAME
        );
        if (adomsAdministratorIndex !== -1) {
          // ADOMS管理者が存在する場合、ADOMS管理者をuserInfoListから取り除く
          const adomsAdministratorUserInfoList = userInfoList.splice(adomsAdministratorIndex, 1);
          // ADOMS管理者を先頭に追加し、ADOMS管理者がユーザー一覧の先頭に来るように並び替える
          userInfoList.splice(0, 0, adomsAdministratorUserInfoList[0]);
        };

        setAllUserInfoList(userInfoList);
        setDisplayLoadingMarkForUserInfoList(false);
        setUserInfoListErrorMessage(undefined);
      } catch (e) {
        console.log("error: " + e);
        setDisplayLoadingMarkForUserInfoList(false);
        if (axios.isAxiosError(e)
          && typeof e.response !== "undefined"
          && e.response.status === 403) {
          handleToForbidden403Page();
        } else {
          setUserInfoListErrorMessage("ユーザー情報の取得に失敗しました。");
        }
      };
    };
    fetchData();
  }, [isSaveUserInfo]);

  /**
  * 1. ログインユーザー情報を取得。
  * 2. マスターとなる、オペレーションパートナー情報、ビジネスパートナー情報、グループ情報を取得。
  * 　（パートナーIDを変更する時とグループを変更する時に使用する）
  */
  useEffect(() => {
    if (userInfoContext.isError) {
      setLoginUserErrorMessage("ログインユーザー情報が取得できませんでした。一部の機能が使えないことがあります。");
      setDisplayLoadingMarkForLoginUserInfo(false);
    } else {
      setLoginUserErrorMessage(undefined);
      if (typeof userInfoContext.userInfo === "undefined") {
        setDisplayLoadingMarkForLoginUserInfo(true);
      } else {
        setDisplayLoadingMarkForLoginUserInfo(false);
      };
    };
    setLogInUserInfo(userInfoContext.userInfo);

    const updateUserInfoLoginUser = havePrivilege(FunctionPrivilege.PrivilegeFunctionUpdateUserInfo, userInfoContext.userInfo);
    setUpdateUserInfoLoginUser(updateUserInfoLoginUser);

    const fetchData = async () => {
      let c: APIConnector = APIConnector.instance;
      let operationPartnerList = c.getOperationPartnerInfoList();
      let businessPartnerList = c.getBusinessPartnerInfoList();
      let groupInfoList = c.getMasterGroupList();

      await Promise.all([operationPartnerList, businessPartnerList, groupInfoList]).then(value => {
        setAllOperationPartnerInfoList(value[0]);
        setAllBusinessPartnerInfoList(value[1]);
        setAllGroupInfoList(value[2]);
        setMasterInfoErrorMessage(undefined);
      }).catch(error => {
        console.log("error: " + error);
        setMasterInfoErrorMessage("システムエラーが発生しました");
      });
    };

    if (updateUserInfoLoginUser) {
      // ユーザー情報を更新できる権限を持っているユーザーのみfetchData()を呼び出す
      fetchData();
    };
  }, [userInfoContext]);

  /**
   * 403ページに遷移させる
   * @param navigate 
   */
  const handleToForbidden403Page = () => {
    navigate(
      "/forbidden403Page",
    );
  };

  /**
   * メニューバーが開かれたときのハンドラ
   */
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  /**
   * メニューバーが閉じられた時のハンドラ
   */
  const handleDrawerClose = () => {
    setOpen(false);
  };

  /**
   * サインアップダイアログが開かれた時のハンドラ
   */
  const handleClickSignUpDialogOpen = () => {
    setSignUpDialogOpen(true);
  }

  /**
   * サインアップダイアログが閉じられた時のハンドラ
   */
  const handleClickSignUpDialogClose = () => {
    setSignUpDialogOpen(false);
  };

  /**
   * ユーザー削除ダイアログが開かれた時のハンドラ
   */
  const handleClickUserDeleteDialogOpen = () => {
    setUserDeleteDialogOpen(true);
  }

  /**
   * ユーザー削除ダイアログが閉じられた時のハンドラ
   */
  const handleClickUserDeleteDialogClose = () => {
    setUserDeleteDialogOpen(false);
  };

  /**
   * 全てのユーザーが選択された時のハンドラ
   */
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const notAdministratorOrLogInUserInfoList = allUserInfoList.filter(userInfo => {
        return userInfo.name !== ADOMS_ADOMINISTRATOR_NAME && userInfo.sub !== logInUserInfo?.sub
      });
      setSelectedUserInfoList(notAdministratorOrLogInUserInfoList);
    } else {
      setSelectedUserInfoList([]);
    };
  };

  /**
   * チェックボックスを表示する
   */
  const displayCheckBox = () => {
    if (logInUserInfo?.name === ADOMS_ADOMINISTRATOR_NAME) {
      // ログインユーザーがADOMS管理者の場合、チェック不可にするチェックボックスは1つになるため、
      // 全てのユーザーの人数から1人マイナスにする。
      return (
        <Checkbox
          inputProps={{ 'aria-label': 'select all desserts' }}
          onChange={handleSelectAllClick}
          indeterminate={selectedUserInfoList.length > 0 && selectedUserInfoList.length < allUserInfoList.length - 1}
          checked={allUserInfoList.length - 1 > 0 && selectedUserInfoList.length === allUserInfoList.length - 1}
        />
      )
    } else {
      // ログインユーザーがADOMS管理者以外の場合、チェック不可にするチェックボックスは
      // ADOMS管理者とログインユーザーの2つになるため、 全てのユーザーの人数から2人マイナスにする。
      return (
        <Checkbox
          inputProps={{ 'aria-label': 'select all desserts' }}
          onChange={handleSelectAllClick}
          indeterminate={selectedUserInfoList.length > 0 && selectedUserInfoList.length < allUserInfoList.length - 2}
          checked={allUserInfoList.length - 2 > 0 && selectedUserInfoList.length === allUserInfoList.length - 2}
        />
      )
    }
  };

  /**
   * TableBodyを表示する
   * @returns TableBody
   */
  const displayTableBody = () => {
    if (allUserInfoList.length !== 0) {
      return (
        <TableBody>
          {allUserInfoList.map((userInfo, i) => (
            <UserDetailTableRow
              key={i}
              userInfo={userInfo}
              allGroupInfoList={allGroupInfoList}
              allOperationPartnerInfoList={allOperationPartnerInfoList}
              allBusinessPartnerInfoList={allBusinessPartnerInfoList}
              isSaveUserInfo={isSaveUserInfo}
              setSaveUserInfo={setSaveUserInfo}
              selectedUserInfoList={selectedUserInfoList}
              setSelectedUserInfoList={setSelectedUserInfoList}
              isDisplayCheckBox={havePrivilege(FunctionPrivilege.PrivilegeFunctionUpdateUserInfo, logInUserInfo)}
              isDisableCheckBox={userInfo?.name === ADOMS_ADOMINISTRATOR_NAME || userInfo.sub === logInUserInfo?.sub}
              canUpdateUserInfoLoginUser={canUpdateUserInfoLoginUser}
            />
          ))}
        </TableBody>
      );
    } else if (typeof userInfoListErrorMessage !== "undefined") {
      return (
        <TableBody>
          <TableRow>
            <TableCell colSpan={8}>
              <Alert severity="error">{userInfoListErrorMessage}</Alert>
            </TableCell>
          </TableRow>
        </TableBody>
      );
    } else if (!isDisplayLoadingMarkForUserInfoList) {
      return (
        <TableBody>
          <TableRow>
            <TableCell colSpan={8}>
              <Alert severity="info">ユーザー情報はありません</Alert>
            </TableCell>
          </TableRow>
        </TableBody>
      );
    };
  };

  return (
    <div className={classes.root}>
      <CssBaseline />

      {/**メニューバーを表示する */}
      <OperationMenuBar
        onChangeDrawerOpen={handleDrawerOpen}
        onChangeDrawerClose={handleDrawerClose}
        open={isOpen}
        title="ユーザー管理"
      />

      {/** コンテンツ部分 */}
      <main
        className={cx(classes.content, {
          /** メニューバーがオープン・クローズされたときのスタイルの変更*/
          [classes.contentShift]: isOpen,
        })}
      >
        <div className={classes.drawerHeader} />

        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="textPrimary" aria-current="page" variant="h5">
            ユーザー一覧
          </Typography>
        </Breadcrumbs>

        <br />
        {havePrivilege(FunctionPrivilege.PrivilegeFunctionUpdateUserInfo, logInUserInfo) ?
          <React.Fragment>
            <Button variant="contained" color="primary"
              size="large"
              onClick={handleClickSignUpDialogOpen}
              className={classes.newButton}
              startIcon={<PersonAddIcon />}>
              ユーザー作成
            </Button>
            <Button variant="contained"
              size="large"
              className={classes.deleteButton}
              startIcon={<PersonAddDisabledIcon />}
              onClick={handleClickUserDeleteDialogOpen}
              disabled={selectedUserInfoList.length === 0}>
              ユーザー削除
            </Button>
          </React.Fragment>
          : undefined}
        {loginUserErrorMessage ?
          <Grid item xs={12} sm={12} className={classes.griditem}>
            <Paper className={classes.paper} elevation={1}>
              <Alert
                severity="error"
              >{loginUserErrorMessage}</Alert>
            </Paper>
          </Grid>
          : undefined
        }
        {masterInfoErrorMessage ?
          <Grid item xs={12} sm={12} className={classes.griditem}>
            <Paper className={classes.paper} elevation={1}>
              <Alert
                severity="error"
              >{masterInfoErrorMessage}</Alert>
            </Paper>
          </Grid>
          : undefined
        }
        <Backdrop className={classes.backdrop} open={(isDisplayLoadingMarkForUserInfoList || isDisplayLoadingMarkForLoginUserInfo)}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <TableContainer component={Paper}>
          <Table className={classes.deliveryTable} aria-label="simple table">
            <TableHead>
              <TableRow>
                {havePrivilege(FunctionPrivilege.PrivilegeFunctionUpdateUserInfo, logInUserInfo) ?
                  <TableCell padding="checkbox">
                    {displayCheckBox()}
                  </TableCell>
                  : undefined}
                <TableCell ><b>ユーザー名</b></TableCell>
                <TableCell ><b>メールアドレス</b></TableCell>
                <TableCell ><b>パートナーID</b></TableCell>
                <TableCell ><b>グループ</b></TableCell>
                <TableCell ><b>技能証明書番号</b></TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            {displayTableBody()}
          </Table>
        </TableContainer>

        <SignUpDialog
          isSignUpDialogOpen={isSignUpDialogOpen}
          handleClickSignUpDialogClose={handleClickSignUpDialogClose}
          allGroupInfoList={allGroupInfoList}
          allOperationPartnerInfoList={allOperationPartnerInfoList}
          allBusinessPartnerInfoList={allBusinessPartnerInfoList}
          setSaveUserInfo={setSaveUserInfo} />

        <UserDeleteDialog
          isUserDeleteDialogOpen={isUserDeleteDialogOpen}
          handleClickUserDeleteDialogClose={handleClickUserDeleteDialogClose}
          selectedUserInfoList={selectedUserInfoList}
          setSelectedUserInfoList={setSelectedUserInfoList}
          setSaveUserInfo={setSaveUserInfo}
        />
      </main>
    </div>
  );
}