import { initializeApp } from 'firebase/app';
import { Messaging, getMessaging, getToken } from 'firebase/messaging';
import { APIConnector } from '../connector/APIConnector';

export class PushNotificationUtil {
    private static _instance: PushNotificationUtil;

    public messaging: Messaging;
    public isFailLastUpdateToken: boolean;
    private firebaseConfig: {
        apiKey: string
        authDomain: string
        projectId: string
        storageBucket: string
        messagingSenderId: string
        appId: string
    };
    private firebaseConfigString: string;
    private serviceWorkerUrl: string;
    private apiConnecter: APIConnector;
    private isProcessingUpdateToken: boolean;

    /** プライベートコンストラクタ */
    private constructor() {
        //firebase.initializeAppをfirebase.messaginより先に行う必要がある
        this.firebaseConfig = {
            apiKey: process.env.REACT_APP_FCM_API_KEY!,
            authDomain: process.env.REACT_APP_FCM_APP_NAME! + ".firebaseapp.com",
            projectId: process.env.REACT_APP_FCM_APP_NAME!,
            storageBucket: process.env.REACT_APP_FCM_APP_NAME! + ".appspot.com",
            messagingSenderId: process.env.REACT_APP_FCM_MESSAGING_SENDER_ID!,
            appId: process.env.REACT_APP_FCM_APP_ID!
        }
        const app = initializeApp(this.firebaseConfig);

        this.messaging = getMessaging(app);
        this.isFailLastUpdateToken = false;

        this.firebaseConfigString = new URLSearchParams(this.firebaseConfig).toString();
        this.serviceWorkerUrl = `${process.env.PUBLIC_URL}/adomsServiceWorker.js?${this.firebaseConfigString}`;
        this.apiConnecter = APIConnector.instance;
        this.isProcessingUpdateToken = false;
    }

    /** インスタンスの取得 */
    public static get instance(): PushNotificationUtil {
        if (!this._instance) {
            console.log("create ConfigInstance");
            this._instance = new PushNotificationUtil();
        }

        // 生成済みのインスタンスを返す
        return this._instance;
    }

    /**
     * FCMから割り当てられたトークンを取得し、変更があった場合は割り当てる
     * @returns FCMから割り当てられたトークン
     */
    public async updateToken(): Promise<string> {
        this.isProcessingUpdateToken = true;
        return await getToken(
            this.messaging,
            {
                vapidKey: process.env.REACT_APP_FCM_VAPID_KEY,
                serviceWorkerRegistration: await navigator.serviceWorker.register(this.serviceWorkerUrl).then(serviceWorker => {
                    return serviceWorker;
                })
            }
        ).then(async token => {
            await this.apiConnecter.postPushNotificationInformation(token);
            this.isFailLastUpdateToken = false;
            return token;
        }).catch(error => {
            console.log('An error occurred while retrieving token. ', error);
            //後々イベント化したい
            this.isFailLastUpdateToken = true;
            throw error;
        }).finally(() => {
            this.isProcessingUpdateToken = false;
        });
    }

    /**
     * ログアウト時など、プッシュ通知の受信を停止するときに必要な操作を行う関数。
     * プッシュ通知受信用のServiceWorkerをSTOPさせ、AWSに登録したFCMトークンを削除する
     * （ブラウザから登録を外す）
     */
    public async unregisterPushNotificationParameter() {
        /*
            updateToken処理が終わっていない場合、
            AWSからトークンを削除できず不正扱いとなるため
            終了まで待つ
        */
        while (this.isProcessingUpdateToken) {
            await new Promise<void>((resolve, reject) => {
                setTimeout(() => resolve(), 1000);
            });
        }

        //以前のプッシュ通知受信待機有無を確認するため先にService Workerを削除
        let hasDeleteServiceWorker = false;
        const registrationServiceWorker = await navigator.serviceWorker.getRegistrations();
        let unregisterServiceWorkerPromise = Promise.all(
            registrationServiceWorker.map(async serviceWorker => {
                if (serviceWorker.active?.scriptURL.indexOf('adomsServiceWorker.js') !== -1) {
                    return serviceWorker.unregister().then(() => {
                        console.log('success service worker unregister');
                        hasDeleteServiceWorker = true;
                    }).catch(error => {
                        console.log('failure unregister service worker');
                        console.log(error);
                    });
                }
            }
            )
        );
        //もし削除されたService Workerが存在する場合、Cookieに登録されたトークンをAWSから削除する
        await unregisterServiceWorkerPromise.then(result => {
            if (hasDeleteServiceWorker) {
                APIConnector.instance.deletePushNotificationInformation();
            }
        })
    };
};

//isIOSはFirebase SDKの初期化操作前に実施する必要があり、クラスから分離
/**
 * iOS（iPadOS）かどうかの判定を行う
 * @returns 接続端末がiOS or iPadOS端末かどうか
 */
export const isIos = (): boolean => {
    return /iP(hone|(o|a)d)/.test(navigator.userAgent);
};