import { Alert, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material"
import { makeStyles } from 'tss-react/mui';
import React from "react"
import { FlightInfo, DateValidator } from "adoms-common-lib"
import axios from 'axios';
import { APIConnector } from "../../connector/APIConnector";

type ChangeFlightScheduleDialogProps = {
    isOpen: boolean,
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
    handleScheduleChangeCancel: Function,
    flight: FlightInfo
}

const useStyles = makeStyles()((theme: any) => ({
    dialogHeader: {
        color: 'black',
        margin: theme.spacing(1)
    },
    alert: {
        margin: theme.spacing(1)
    },
    scheduleTextField: {
        margin: theme.spacing(1)
    },
    remarksTextfield: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '96.4%'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    }
}));

export const ChangeFlightScheduleDialog: React.FC<ChangeFlightScheduleDialogProps> = (props) => {
    const { classes } = useStyles();
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
    const [isDisplayLoadingMark, setDisplayLoadingMark] = React.useState(false);
    const [isDisplayValidate, setDisplayValidate] = React.useState<Map<string, boolean>>(new Map<string, boolean>());
    const [inputStd, setInputStd] = React.useState<string>(props.flight.flightDate + 'T' + props.flight.std);
    const [inputSta, setInputSta] = React.useState<string>(props.flight.flightDate + 'T' + props.flight.sta);
    const [inputEtd, setInputEtd] = React.useState<string>(props.flight.flightDate + 'T' + props.flight.etd);
    const [inputEta, setInputEta] = React.useState<string>(props.flight.flightDate + 'T' + props.flight.eta);
    const [inputRemarks, setInputRemarks] = React.useState<string>(props.flight.remarks);

    /**
     * テキストフィールド内の現在の入力値を取得するためのハンドラ
     * @param event フライトスケジュールを入力するテキストフィールドからのonChangeイベント
     * @param kindOfText 入力したスケジュールの種類
     */
    const changeTextfieldValue = (event: any, kindOfText: string) => {
        if (/sta/.test(kindOfText)) {
            setInputSta(event.target.value);
        } else if (/std/.test(kindOfText)) {
            setInputStd(event.target.value);
        } else if (/eta/.test(kindOfText)) {
            setInputEta(event.target.value);
        } else if (/etd/.test(kindOfText)) {
            setInputEtd(event.target.value);
        } else if (/remarks/.test(kindOfText)) {
            setInputRemarks(event.target.value);
        }
    };

    /**
     * スケジュールをサーバーに保存するハンドラ
     */
    const handleChangeSchedule = async () => {
        if (checkTextfieldValidate()) {
            let apiConnector = APIConnector.instance
            let changeScheduleParam = {
                id: props.flight.id,
                sta: inputSta.match(/\d{2}:\d{2}/)![0],
                std: inputStd.match(/\d{2}:\d{2}/)![0],
                eta: inputEta.match(/\d{2}:\d{2}/)![0],
                etd: inputEtd.match(/\d{2}:\d{2}/)![0],
                remarks: inputRemarks
            }
            let putFlightScheduleChangePromise = apiConnector.putFlightScheduleChange(changeScheduleParam);
            setDisplayLoadingMark(true);
            await putFlightScheduleChangePromise.then(() => {
                setErrorMessage(undefined);
                props.setIsOpen(false);
            }).catch(error => {
                console.error("error: " + JSON.stringify(error));
                if (axios.isAxiosError(error)
                    && typeof error.response !== "undefined"
                    && error.response.status === 403) {
                    setErrorMessage("権限がありません。");
                } else {
                    setErrorMessage("スケジュールを保存できませんでした。");
                };
            })
            setDisplayLoadingMark(false);
        }
    };

    /**
     * スケジュール変更ダイアログが表示される前に、
     * すべてのスケジュールが正しく入力されていることを
     * 確認するハンドラ
     * @returns すべてのスケジュールが正しく入力されているかどうか
     */
    const checkTextfieldValidate = (): boolean => {
        let makingDisplayValidate = new Map<string, boolean>();
        makingDisplayValidate.set('std', !DateValidator.checkISO8601DateAndSimpleTime(inputStd));
        makingDisplayValidate.set('sta', !DateValidator.checkISO8601DateAndSimpleTime(inputSta));
        makingDisplayValidate.set('etd', !DateValidator.checkISO8601DateAndSimpleTime(inputEtd));
        makingDisplayValidate.set('eta', !DateValidator.checkISO8601DateAndSimpleTime(inputEta));
        console.log(makingDisplayValidate);

        setDisplayValidate(makingDisplayValidate);

        console.log(Array.from(makingDisplayValidate.values()).every((value): boolean => { return value === false }));
        return Array.from(makingDisplayValidate.values()).every((value): boolean => { return value === false });
    }

    /**
     * キャンセルボタンが押された際のハンドラ
     */
    const handleCancel = () => {
        console.log(inputStd);
        props.setIsOpen(false)
    };

    return (
        <Dialog open={props.isOpen} aria-labelledby="form-dialog-title">
            <DialogContent>
                <DialogContentText className={classes.dialogHeader}>
                    フライトスケジュールを保存します。<br />確認の上、備考を入力し保存ボタンを押してください。
                </DialogContentText>
                {
                    errorMessage
                        ?
                        <Alert className={classes.alert} severity="error">
                            {errorMessage}
                        </Alert>
                        :
                        null
                }
                <TextField
                    variant="standard"
                    error={isDisplayValidate.get('std')}
                    helperText={isDisplayValidate.get('std') ? '日付を入力してください' : ''}
                    className={classes.scheduleTextField}
                    id="stdTextfield"
                    label="STD"
                    type="datetime-local"
                    value={inputStd}
                    onChange={event => { changeTextfieldValue(event, 'std') }}
                    InputLabelProps={{
                        shrink: true,
                    }} />
                <TextField
                    variant="standard"
                    error={isDisplayValidate.get('sta')}
                    helperText={isDisplayValidate.get('sta') ? '日付を入力してください' : ''}
                    className={classes.scheduleTextField}
                    id="staTextfield"
                    label="STA"
                    type="datetime-local"
                    value={inputSta}
                    onChange={event => { changeTextfieldValue(event, 'sta') }}
                    InputLabelProps={{
                        shrink: true,
                    }} />
                <br />
                <TextField
                    variant="standard"
                    error={isDisplayValidate.get('etd')}
                    helperText={isDisplayValidate.get('etd') ? '日付を入力してください' : ''}
                    className={classes.scheduleTextField}
                    id="etdTextfield"
                    label="ETD"
                    type="datetime-local"
                    value={inputEtd}
                    onChange={event => { changeTextfieldValue(event, 'etd') }}
                    InputLabelProps={{
                        shrink: true,
                    }} />
                <TextField
                    variant="standard"
                    error={isDisplayValidate.get('eta')}
                    helperText={isDisplayValidate.get('eta') ? '日付を入力してください' : ''}
                    className={classes.scheduleTextField}
                    id="etaTextfield"
                    label="ETA"
                    type="datetime-local"
                    value={inputEta}
                    onChange={event => { changeTextfieldValue(event, 'eta') }}
                    InputLabelProps={{
                        shrink: true,
                    }} />
                <br />
                <TextField
                    variant="standard"
                    className={classes.remarksTextfield}
                    id="remarksTextfield"
                    label="備考"
                    value={inputRemarks}
                    onChange={event => { changeTextfieldValue(event, 'remarks') }}
                    multiline
                    maxRows="3" />
                <Backdrop className={classes.backdrop} open={isDisplayLoadingMark}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color="primary">
                    キャンセル
                </Button>
                <Button onClick={handleChangeSchedule} color="primary">
                    保存
                </Button>
            </DialogActions>
        </Dialog>
    );
}