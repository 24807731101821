import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import AddCircle from '@mui/icons-material/AddCircle';
import AddShoppingCart from '@mui/icons-material/AddShoppingCart';
import CancelIcon from '@mui/icons-material/Cancel';
import DoneIcon from '@mui/icons-material/Done';
import FlightIcon from '@mui/icons-material/Flight';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import RefreshIcon from '@mui/icons-material/Refresh';
import RemoveShoppingCart from '@mui/icons-material/RemoveShoppingCart';
import StoreIcon from '@mui/icons-material/Store';
import { Timeline, TimelineConnector, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@mui/lab';
import { AssosiateFlightAndOrder, createDefault, EventName, FlightInfo, OperationHistory, OperationHistoryUtil, OrderInfo, RecordType, TimelineStatus, UserInfo } from "adoms-common-lib";
import React, { useEffect } from 'react';
import { APIConnector } from "../../connector/APIConnector";
import { FlightTimelineContent } from '../molecule/FlightTimelineContent';

const useStyles = makeStyles()((theme: any) => ({
    root: {
        display: 'flex',
    },
    title: {
        flexGrow: 1,
        textAlign: 'center',
        color: 'white',
        backgroundColor: theme.palette.primary.main,
    },
    paper: {
        padding: '12px',
        height: '100%',
        alignItems: 'center'
    },
    oppositeContent: {
        flex: 0.4
    }
}));

type FlightTimelineProps = {
    flightId: string,
    needUpdateTimeline: boolean,
    setNeedUpdateTimeline: React.Dispatch<React.SetStateAction<boolean>>
    displayFlightHistoryFlag: boolean
    setDisplayFlightHistoryFlag: React.Dispatch<React.SetStateAction<boolean>>
}

export type timelineComponent = {
    date: string,
    time: string,
    status: TimelineStatus,
    operation: string,
    changedColumn?: string,
    assosiateFlightAndOrder?: AssosiateFlightAndOrder,
    newFlightInfo?: FlightInfo,
    oldFlightInfo?: FlightInfo,
    newOrderInfo?: OrderInfo,
    oldOrderInfo?: OrderInfo,
    updateUserInfo?: UserInfo
}

export const FlightTimeline: React.FC<FlightTimelineProps> = (props) => {
    const [timelineComponentList, setTimelineComponentList] = React.useState<timelineComponent[]>([] as timelineComponent[]);
    const { classes } = useStyles();

    //
    const isAllocationRecord = (history: OperationHistory): boolean => {
        return (
            history.id.startsWith("flight-") &&
            history.subId.startsWith("flight-") &&
            history.modifiedColumn?.length === 2 &&
            history.modifiedColumn?.includes("allocated") &&
            history.modifiedColumn?.includes("recent_update"));
    }

    useEffect(() => {
        const fetchData = async () => {
            let apigw: APIConnector = APIConnector.instance;
            let result: OperationHistory[] = await apigw.getFlightHistory(props.flightId);
            let timelineComponentList: timelineComponent[] = new Array();
            for (const operationHistory of result) {
                if (isAllocationRecord(operationHistory)) {
                    //flightに対するallocatedの変更だけのレコードは無視する
                    continue;
                }

                const dateList = operationHistory.updateDateTime.match(/\d{4}-\d{2}-\d{2}/);
                const date = dateList ? dateList[0] as string : undefined;
                const timeList = operationHistory.updateDateTime.match(/\d{2}:\d{2}/);
                const time = timeList ? timeList[0] as string : undefined;
                let timelineComponent: timelineComponent = {
                    date: date ? date : "",
                    time: time ? time : "",
                    status: TimelineStatus.Empty,
                    operation: "",
                    assosiateFlightAndOrder: {
                        orderId: "",
                        flightId: ""
                    },
                    newFlightInfo: createDefault(),
                    oldFlightInfo: createDefault(),
                    updateUserInfo: operationHistory.updateUserInfo
                };


                // previousDate = date;
                if (operationHistory.tableName.endsWith("-adoms-order-flight-junction")) {
                    let recordType = OperationHistoryUtil.getRecordType(operationHistory);

                    // tableがadoms-flightの場合
                    if (EventName.INSERT === operationHistory.eventName) {
                        if (recordType === RecordType.Flight) {
                            // 新規フライトが作成された場合
                            timelineComponent.status = TimelineStatus.NewFlight;
                            timelineComponent.operation = "フライト新規作成";
                            if (timelineComponent.newFlightInfo) {
                                timelineComponent.newFlightInfo = operationHistory.newItem?.body as FlightInfo;
                            }
                        } else if (recordType === RecordType.FOJunction) {
                            //フライトに配送物が紐づけられた場合
                            timelineComponent.status = TimelineStatus.AddOrder;
                            timelineComponent.operation = "配送物割り当て";
                            if (timelineComponent.assosiateFlightAndOrder) {
                                timelineComponent.assosiateFlightAndOrder = {
                                    flightId: operationHistory.newItem?.id.replace("flight-", ""),
                                    orderId: operationHistory.newItem?.subId.replace("order-", ""),
                                } as AssosiateFlightAndOrder;
                            }
                        } else {
                            throw new Error("Invalid record");
                        }
                    } else if (EventName.MODIFY === operationHistory.eventName) {
                        // eventNameがMODIFYの場合
                        if (operationHistory.modifiedColumn?.includes("flight-status")) {
                            const afterChangeStatus = (operationHistory.newItem?.body as FlightInfo).status;

                            if (timelineComponent.oldFlightInfo
                                && timelineComponent.newFlightInfo) {
                                timelineComponent.oldFlightInfo = operationHistory.oldItem?.body as FlightInfo;
                                timelineComponent.newFlightInfo = operationHistory.newItem?.body as FlightInfo;
                                timelineComponent.operation = "『" + timelineComponent.newFlightInfo?.status + "』に変更";
                            }

                            if (afterChangeStatus?.includes(TimelineStatus.Standby)) {
                                timelineComponent.status = TimelineStatus.Standby
                            } else if (afterChangeStatus?.includes(TimelineStatus.BeforePreparation)) {
                                timelineComponent.status = TimelineStatus.BeforePreparation
                            } else if (afterChangeStatus?.includes(TimelineStatus.Preparation)) {
                                timelineComponent.status = TimelineStatus.Preparation
                            } else if (afterChangeStatus?.includes(TimelineStatus.BeforeTakeoff)) {
                                timelineComponent.status = TimelineStatus.BeforeTakeoff
                            } else if (afterChangeStatus?.includes(TimelineStatus.Takeoff)) {
                                timelineComponent.status = TimelineStatus.Takeoff
                            } else if (afterChangeStatus?.includes(TimelineStatus.InFlightClimb)) {
                                timelineComponent.status = TimelineStatus.InFlightClimb
                            } else if (afterChangeStatus?.includes(TimelineStatus.InFlightCruise)) {
                                timelineComponent.status = TimelineStatus.InFlightCruise
                            } else if (afterChangeStatus?.includes(TimelineStatus.InFlightDescent)) {
                                timelineComponent.status = TimelineStatus.InFlightDescent
                            } else if (afterChangeStatus?.includes(TimelineStatus.AfterLanding)) {
                                timelineComponent.status = TimelineStatus.AfterLanding
                            } else if (afterChangeStatus?.includes(TimelineStatus.Completed)) {
                                timelineComponent.status = TimelineStatus.Completed
                            } else if (afterChangeStatus?.includes(TimelineStatus.Reset)) {
                                timelineComponent.status = TimelineStatus.Reset
                            } else if (afterChangeStatus?.includes(TimelineStatus.Irregular)) {
                                timelineComponent.status = TimelineStatus.Irregular
                            } else if (afterChangeStatus?.includes(TimelineStatus.Canceled)) {
                                timelineComponent.status = TimelineStatus.Canceled
                            }
                        }
                    } else if (EventName.REMOVE === operationHistory.eventName) {
                        if (recordType === RecordType.FOJunction) {
                            // eventNameがREMOVEの場合
                            timelineComponent.status = TimelineStatus.RemoveOrder;
                            timelineComponent.operation = "配送物割り当て解除";
                            if (timelineComponent.assosiateFlightAndOrder) {
                                timelineComponent.assosiateFlightAndOrder = {
                                    flightId: operationHistory.oldItem?.id.replace("flight-", ""),
                                    orderId: operationHistory.oldItem?.subId.replace("order-", ""),
                                } as AssosiateFlightAndOrder;
                            }
                        } else {
                            throw new Error("Invalid record");
                        }
                    }
                }
                //暫定対応。対応していない変更履歴を画面に出力しない措置
                if (timelineComponent.operation != '') {
                    timelineComponentList.push(timelineComponent);
                }
            }

            // フライト新規作成と配送情報追加が同じ時間に実施された場合、
            // フライト新規作成⇒配送情報追加の順でタイムラインに表示されるように
            // timelineComponentListの順を入れ替える
            let newflightCreateTimelineComponent;
            for (let timelineComponent of timelineComponentList) {
                if (timelineComponent.status === TimelineStatus.NewFlight) {
                    newflightCreateTimelineComponent = timelineComponent;
                }
            }
            for (let timelineComponent of timelineComponentList) {
                if (newflightCreateTimelineComponent
                    && timelineComponent.status === TimelineStatus.AddOrder
                    && JSON.stringify(timelineComponent.date) === JSON.stringify(newflightCreateTimelineComponent.date)
                    && JSON.stringify(timelineComponent.time) === JSON.stringify(newflightCreateTimelineComponent.time)) {
                    if (timelineComponentList.indexOf(newflightCreateTimelineComponent)
                        > timelineComponentList.indexOf(timelineComponent)) {
                        timelineComponentList.splice(
                            timelineComponentList.indexOf(timelineComponent),
                            2,
                            newflightCreateTimelineComponent,
                            timelineComponent
                        );
                    }
                }
            }
            setTimelineComponentList(timelineComponentList);
            props.setNeedUpdateTimeline(false);
        }
        fetchData();
    }, [props.needUpdateTimeline]);

    const timelineIcon = (status: TimelineStatus) => {
        let timelineIcon;
        if (TimelineStatus.NewFlight === status) {
            timelineIcon = (
                <TimelineDot color="primary">
                    <AddCircle />
                </TimelineDot>
            )
        } else if (TimelineStatus.AddOrder === status) {
            timelineIcon = (
                <TimelineDot color="primary">
                    <AddShoppingCart />
                </TimelineDot>
            )
        } else if (TimelineStatus.RemoveOrder === status) {
            timelineIcon = (
                <TimelineDot color="grey">
                    <RemoveShoppingCart />
                </TimelineDot>
            )
        } else if (TimelineStatus.Standby === status
            || TimelineStatus.BeforePreparation === status
            || TimelineStatus.Preparation === status
            || TimelineStatus.BeforeTakeoff === status) {
            timelineIcon = (
                <TimelineDot color="primary">
                    <StoreIcon />
                </TimelineDot>
            )
        } else if (TimelineStatus.Takeoff === status
            || TimelineStatus.InFlightClimb === status) {
            timelineIcon = (
                <TimelineDot color="primary">
                    <FlightTakeoffIcon />
                </TimelineDot>
            )
        } else if (TimelineStatus.InFlightCruise === status) {
            timelineIcon = (
                <TimelineDot color="primary">
                    <FlightIcon />
                </TimelineDot>
            )
        } else if (TimelineStatus.InFlightDescent === status
            || TimelineStatus.AfterLanding === status) {
            timelineIcon = (
                <TimelineDot color="primary">
                    <FlightLandIcon />
                </TimelineDot>
            )
        } else if (TimelineStatus.Completed === status) {
            timelineIcon = (
                <TimelineDot color="secondary">
                    <DoneIcon />
                </TimelineDot>
            )
        } else if (TimelineStatus.Reset === status) {
            timelineIcon = (
                <TimelineDot color="secondary">
                    <RefreshIcon />
                </TimelineDot>
            )
        } else if (TimelineStatus.Irregular === status) {
            timelineIcon = (
                <TimelineDot color="secondary">
                    <PriorityHighIcon />
                </TimelineDot>
            )
        } else if (TimelineStatus.Canceled === status) {
            timelineIcon = (
                <TimelineDot color="grey">
                    <CancelIcon />
                </TimelineDot>
            )
        }
        return timelineIcon;
    }

    return (
        <div>
            <Typography className={classes.title} variant="h6" component="div">
                フライト情報タイムライン
            </Typography>
            {/** フライト単位のタイムライン */}
            <Timeline position="left">
                {timelineComponentList.map((timelineComponent, i) => (
                    <TimelineItem>
                        <TimelineOppositeContent className={classes.oppositeContent}>
                            <Typography variant="body2" color="inherit">
                                {i == 0 || (i > 0 && JSON.stringify(timelineComponentList[i - 1].date) != JSON.stringify(timelineComponent.date))
                                    ? timelineComponent.date : null}
                                <br />
                                {timelineComponent.time}
                            </Typography>
                        </TimelineOppositeContent >
                        <TimelineSeparator>
                            {i == 0 ?
                                null : <TimelineConnector />}
                            {timelineIcon(timelineComponent.status)}
                            {i == timelineComponentList.length - 1 ?
                                null : <TimelineConnector />}
                        </TimelineSeparator>
                        <FlightTimelineContent timelineComponent={timelineComponent} />
                    </TimelineItem>
                ))}
            </Timeline>
        </div>
    );
}