import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Snackbar } from '@mui/material';
import React from 'react';

type SixSecondsDisplaySnackbarProps = {
    message: string
    snackbarOpen: boolean
    setSnackbarOpen: React.Dispatch<React.SetStateAction<boolean>>
};

/**
 * スナックバーを6秒間表示する
 * @param props 
 */
export const SixSecondsDisplaySnackbar: React.FC<SixSecondsDisplaySnackbarProps> = (props) => {

    /**
     * スナックバーを閉じる
     */
    const handleSnackBarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        props.setSnackbarOpen(false);
    };

    return (
        <Snackbar
            open={props.snackbarOpen}
            autoHideDuration={6000}
            onClose={handleSnackBarClose}
            message={props.message}
            action={<IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleSnackBarClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>}
        />
    );
};