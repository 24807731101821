import { UserInfo } from "adoms-common-lib";
import React, { ReactNode, useContext, useEffect } from 'react';
import { APIConnector } from "../connector/APIConnector";

export interface UserInfoContextType {
    isError: boolean,
    userInfo?: UserInfo
}

export const UserInfoContext = React.createContext<UserInfoContextType>({
    isError: false,
    userInfo: undefined
});

export function useUserInfoContext() {
    return useContext(UserInfoContext);
}

export function UserInfoProvider({ children }: {
    children: ReactNode
}) {
    const [userInfo, setUserInfo] = React.useState<UserInfoContextType>({
        isError: false,
        userInfo: undefined
    });

    useEffect(() => {
        const getUserInfo = async () => {
            const apiConnector = APIConnector.instance;
            await apiConnector.getUserInfo().then(userInfo => {
                console.log("userInfo: " + JSON.stringify(userInfo));
                setUserInfo({
                    isError: false,
                    userInfo: userInfo
                });
            }).catch(error => {
                console.log('getUserInfo error: ', error);
                setUserInfo({
                    isError: true,
                    userInfo: undefined
                });
            });
        }
        getUserInfo();
    }, []);

    return <UserInfoContext.Provider value={userInfo}>{children}</UserInfoContext.Provider>;
}