import { Alert, Backdrop, Card, CardActions, CardContent, CardHeader, CircularProgress, IconButton, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RefreshIcon from '@mui/icons-material/Refresh';
import { APIConnector } from "../../connector/APIConnector";
import { OrderInfo } from 'adoms-common-lib';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ReceiveStatusInfo from '../atoms/ReceiveStatusInfo';
import OrderGoodsTable from './OrderGoodsTable';

const useStyles = makeStyles()((theme: any) => ({
  card: {
    marginBottom: 15,
    backgroundColor: theme.palette.common.white
  },

  pos: {
    marginBottom: 12,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  paper: {
    padding: '12px',
    textAlign: 'center',
    height: '100%'
  }
}));


type OrderCardProps = {
  order: OrderInfo,
  onDelete?: (order: OrderInfo | undefined) => void,
}


const OrderCard: React.FC<OrderCardProps> = (props) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [order, setOrder] = React.useState(props.order);
  const [isDisplayLoadingMark, setDisplayLoadingMark] = React.useState<boolean>(false);
  const [isErrorFlag, setErrorFlag] = React.useState(false);

  const onDeleteClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (props.onDelete !== undefined) {
      props.onDelete(props.order);
    }
  }


  const onDetailClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    navigate("/orderdetailview" ,
      { 
        state: {
          orderID: props.order.orderID 
        }
      }
    )
  }

  const onRefreshClick = async () => {
    setDisplayLoadingMark(true);
    let c: APIConnector = APIConnector.instance;
    await c.getOrderForOperation(order.orderID).then(refreshOrder => {
      setOrder(refreshOrder);
      setDisplayLoadingMark(false);
      setErrorFlag(false);
    }).catch(error => {
      console.log("error:" + error.message);
      setDisplayLoadingMark(false);
      setErrorFlag(true);
    })
  }

  return (
    <Card className={classes.card}>
      <Backdrop className={classes.backdrop} open={isDisplayLoadingMark}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <CardHeader
        action={
          <div>
            <IconButton aria-label="settings" onClick={onRefreshClick} size="large">
              <RefreshIcon />
            </IconButton>
            <IconButton aria-label="settings" onClick={onDetailClick} size="large">
              <MoreVertIcon />
            </IconButton>
          </div>
        }
        title={(props.order.destination.lastname + " " + props.order.destination.firstname + "様")}
        subheader={
          <React.Fragment>
            <Typography>{("☎ " + order.destination.tel)}</Typography>
            <ReceiveStatusInfo status={order.receiveStatus} />
          </React.Fragment>
        }
      />
      <CardContent>
        {
          isErrorFlag ? (
            <React.Fragment>
              <Alert severity="error">更新できませんでした。ページを再読み込みして操作をやりなおしてください。</Alert>
              <br />
            </React.Fragment>
          ) : (null)
        }
        パートナーID: {props.order.businessPartnerId} / 確認番号: {props.order.orderID} {props.order.externalOrderID !== undefined ? "/ 外部連携ID: " + props.order.externalOrderID : ""}
        <OrderGoodsTable order={props.order} />
      </CardContent>
      {
        props.onDelete !== undefined ? (
          <CardActions>
            <IconButton
              aria-label="settings"
              onClick={onDeleteClick}
              style={{ marginLeft: "auto" }}
              size="large">
              <DeleteForeverIcon />
            </IconButton>
          </CardActions>
        ) : null
      }
    </Card>
  );
}

export default OrderCard