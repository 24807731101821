import { Backdrop, Breadcrumbs, CircularProgress, Paper, Tab, Tabs } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import { DataPrivilegeInfo, FunctionPrivilegeInfo, GroupDataPrivilegeInfo, GroupFunctionPrivilegeInfo, GroupInfo } from 'adoms-common-lib/build/model/PrivilegeInfomation';
import axios from 'axios';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TabPanel } from '../../../components/atoms/TabPanel';
import OperationMenuBar from '../../../components/organisms/OperationMenuBar';
import { PrivilegeDetailTable } from '../../../components/organisms/PrivilegeDetailTable';
import { APIConnector } from '../../../connector/APIConnector';

const drawerWidth = 260;

const useStyles = makeStyles()((theme: any) => ({
    root: {
        display: 'flex',
        backgroundColor: theme.palette.background.default
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    deliveryTable: {
        minWidth: 1024,
        width: "100%",
        tableLayout: "fixed"
    },
    alert: {
        marginBottom: theme.spacing(2)
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default function PrivilegeListView() {

    const { classes, cx } = useStyles();
    const navigate = useNavigate();

    const [isOpen, setOpen] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>();
    const [isDisplayLoadingMark, setDisplayLoadingMark] = React.useState(false);
    const [masterFunctionPrivilegeList, setMasterFunctionPrivilegeList] = React.useState<FunctionPrivilegeInfo[]>([]);
    const [masterDataPrivilegeList, setMasterDataPrivilegeList] = React.useState<DataPrivilegeInfo[]>([]);
    const [groupInfoListForFunctionPage, setGroupInfoListForFunctionPage] = React.useState<GroupInfo[]>([]);
    const [functionPrivilegeInfoListPerGroup, setfunctionPrivilegeInfoListPerGroup] = React.useState<FunctionPrivilegeInfo[][]>([]);
    const [groupInfoListForDataPage, setGroupInfoListForDataPage] = React.useState<GroupInfo[]>([]);
    const [dataPrivilegeInfoListPerGroup, setdataPrivilegeInfoListPerGroup] = React.useState<DataPrivilegeInfo[][]>([]);
    const [tabValue, setTabValue] = React.useState(0);

    useEffect(() => {
        const fetchData = async () => {
            setDisplayLoadingMark(true);

            let c: APIConnector = APIConnector.instance;
            let privilegeList = c.getMasterPrivilegeList();
            let groupPrivilegeList = c.getPrivilegeListPerGroup();

            await Promise.all([privilegeList, groupPrivilegeList]).then(value => {
                let groupInfoListForFunctionPageParam: GroupInfo[] = [];
                let functionPrivilegeInfoListPerGroupParam: FunctionPrivilegeInfo[][] = [];
                // 使用可能な機能のマスター権限リストを設定
                setMasterFunctionPrivilegeList([...value[0].functionPrivilegeInfoList]);
                // 使用可能な機能の権限を設定
                let groupFunctionPrivilegeInfoList: GroupFunctionPrivilegeInfo[] = value[1].groupFunctionPrivilegeInfoList;
                for (let groupFunctionPrivilegeInfo of groupFunctionPrivilegeInfoList) {
                    groupInfoListForFunctionPageParam.push(groupFunctionPrivilegeInfo.groupInfo);
                    functionPrivilegeInfoListPerGroupParam.push(groupFunctionPrivilegeInfo.functionPrivilegeInfo);
                }
                // マスターグループリスト（機能に関する権限表示用）を設定
                setGroupInfoListForFunctionPage([...groupInfoListForFunctionPageParam]);
                // グループごとの権限リスト（機能に関する権限表示用）を設定
                setfunctionPrivilegeInfoListPerGroup([...functionPrivilegeInfoListPerGroupParam]);

                let groupInfoListForDataPageParam: GroupInfo[] = [];
                let dataPrivilegeInfoListPerGroupParam: DataPrivilegeInfo[][] = [];
                // 閲覧可能な情報のマスター権限リストを設定
                setMasterDataPrivilegeList([...value[0].dataPrivilegeInfoList]);
                // 閲覧可能な情報の権限を設定
                let groupDataPrivilegeInfoList: GroupDataPrivilegeInfo[] = value[1].groupDataPrivilegeInfoList;
                for (let groupDataPrivilegeInfo of groupDataPrivilegeInfoList) {
                    groupInfoListForDataPageParam.push(groupDataPrivilegeInfo.groupInfo);
                    dataPrivilegeInfoListPerGroupParam.push(groupDataPrivilegeInfo.dataPrivilegeInfo);
                }
                // マスターグループリスト（データに関する権限表示用）を設定
                setGroupInfoListForDataPage([...groupInfoListForDataPageParam]);
                // グループごとの権限リスト（データに関する権限表示用）を設定
                setdataPrivilegeInfoListPerGroup([...dataPrivilegeInfoListPerGroupParam]);

                setDisplayLoadingMark(false);
                setErrorMessage(undefined);

            }).catch(error => {
                if (axios.isAxiosError(error)
                    && typeof error.response !== "undefined"
                    && error.response.status === 403) {
                    handleToForbidden403Page();
                } else {
                    console.log("error: " + error);
                    setDisplayLoadingMark(false);
                    setErrorMessage("権限情報の取得に失敗しました。");
                };
            });
        };
        fetchData();
    }, []);

    /**
     * 403ページに遷移させる
     * @param navigate 
     */
    const handleToForbidden403Page = () => {
        navigate(
            "/forbidden403Page",
        );
    };

    /**
     * メニューバーが開かれたときのハンドラ
     */
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    /**
     * メニューバーが閉じられた時のハンドラ
     */
    const handleDrawerClose = () => {
        setOpen(false);
    };

    /**
     * tabのidをセットする
     */
    const a11yProps = (index: any) => {
        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`,
        };
    };

    /**
     * タブが変更された時のハンドラ
     */
    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabValue(newValue);
    };

    return (
        <div className={classes.root}>
            <CssBaseline />

            {/**メニューバーを表示する */}
            <OperationMenuBar
                onChangeDrawerOpen={handleDrawerOpen}
                onChangeDrawerClose={handleDrawerClose}
                open={isOpen}
                title="ユーザー管理"
            />

            {/** コンテンツ部分 */}
            <main
                className={cx(classes.content, {
                    /** メニューバーがオープン・クローズされたときのスタイルの変更*/
                    [classes.contentShift]: isOpen,
                })}
            >
                <div className={classes.drawerHeader} />

                <Breadcrumbs aria-label="breadcrumb">
                    <Typography color="textPrimary" aria-current="page" variant="h5">
                        権限一覧
                    </Typography>
                </Breadcrumbs>

                <br />
                <Backdrop className={classes.backdrop} open={isDisplayLoadingMark}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <Paper>
                    <Paper square>
                        <Tabs
                            value={tabValue}
                            onChange={handleTabChange}
                            aria-label="basic tabs example"
                            indicatorColor="primary"
                            variant="fullWidth"
                            textColor="primary"
                        >
                            <Tab label="使える機能" {...a11yProps(0)} />
                            <Tab label="見える情報" {...a11yProps(1)} />
                        </Tabs>
                    </Paper>
                    <TabPanel value={tabValue} index={0}>
                        <PrivilegeDetailTable
                            groupList={groupInfoListForFunctionPage}
                            privilegeListPerGroup={functionPrivilegeInfoListPerGroup}
                            privilegeInfoList={masterFunctionPrivilegeList}
                            errorMessage={errorMessage}
                            isDisplayLoadingMark={isDisplayLoadingMark}
                        />
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        <PrivilegeDetailTable
                            groupList={groupInfoListForDataPage}
                            privilegeListPerGroup={dataPrivilegeInfoListPerGroup}
                            privilegeInfoList={masterDataPrivilegeList}
                            errorMessage={errorMessage}
                            isDisplayLoadingMark={isDisplayLoadingMark}
                        />
                    </TabPanel>
                </Paper>
            </main>
        </div>
    );
}