import { Alert } from '@mui/material';
import React, { useEffect } from 'react';
import { isIos, PushNotificationUtil } from '../../common/PushNotificationUtil';

/**
 * このダイアログが置かれたページでプッシュ通知の受信が許可されていないことを検知した場合、
 * 許可を求める旨メッセージを表示する
 * @returns プッシュ通知の受信が許可されていない場合、許可を求めるAlert
 */
export const NotificationWarning: React.FC = () => {
    const [isDeniedNotification, setIsDeniedNotification] = React.useState<Boolean>(false);

    useEffect(() => {
        /*
         iOS端末判定を先に行うこと！ 
         Notification.permissionの設定値をiOS端末に要求するとページを表示できない！
        */
        if (isIos() || Notification.permission !== 'granted') {
            setIsDeniedNotification(true);
        } else {
            if (PushNotificationUtil.instance.isFailLastUpdateToken) {
                setIsDeniedNotification(true);
            } else {
                setIsDeniedNotification(false);
            }
        }
    });

    const makeErrorWords = (): JSX.Element => {
        if (isIos()) {
            return (<div>
                iOS端末はWebプッシュ通知機能に対応していません。<br />
                プッシュ通知を受信するにはPCまたはAndroid端末をご利用ください。
            </div>)
        } else if (Notification.permission !== 'granted') {
            return (<div>
                プッシュ通知の受信が許可されていません。<br />
                受信するにはOS・ブラウザで受信を許可してください。<br />
                詳細はマニュアルをご覧ください。
            </div>)
        } else {
            return (<div>
                プッシュ通知の受信に必要な情報の取得に失敗しています。<br />
                通信状況を確認し、ブラウザを更新してください。<br />
                複数回このメッセージが出力される場合は通知サービスが提供できなくなっている可能性があります。
            </div>)
        }
    }

    return isDeniedNotification ? 
        <div>
            <Alert severity="warning">
                {
                    makeErrorWords()
                }
            </Alert>
            <br />
        </div>
        : null
}