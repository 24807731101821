/* eslint-disable import/first */

import { makeStyles } from 'tss-react/mui';
import { FlightStatus } from 'adoms-common-lib';
import { Paper, Zoom, Typography } from '@mui/material';
import { red } from '@mui/material/colors';
import React from 'react';

enum FlightStatusColor {
    /**
     * 空
     */
    Empty = "#4791db",
    /**
     * 準備中
     */
    Standby = "#ea9245",
    /**
     * 往路
     */
    OB = "#156dba",
    /**
     * 復路
     */
    RT = "#00695f",
    /**
     * イレギュラー
     */
    Irregular = "#e33371",
    /**
     * 完了
     */
    Completed = "#b28704",
    /**
     * キャンセル
     */
    Canceled = "#9e9e9e",
    /**
     * キャンセル
     */
    Reset = "#f44336",

}




const useStyles = makeStyles()((theme: any) => ({

    /**
     * 空
     */
    Empty: {
        borderRadius: 3,
        backgroundColor: FlightStatusColor.Empty,
        color: theme.palette.primary.contrastText,
        padding: 3,
    },

    /**
     * 受付中
     */
    Standby: {
        borderRadius: 3,
        backgroundColor: FlightStatusColor.Standby,
        color: theme.palette.primary.contrastText,
        padding: 3,
    },

    /**
     * 往路
     */
    OB: {
        borderRadius: 3,
        backgroundColor: FlightStatusColor.OB,
        color: theme.palette.primary.contrastText,
        padding: 3,
    },


    /**
     * 集荷待ち
     */
    RT: {
        borderRadius: 3,
        backgroundColor: FlightStatusColor.RT,
        color: theme.palette.primary.contrastText,
        padding: 3,
    },

    /**
     * 集荷して準備中の状態
     */
    Irregular: {
        borderRadius: 3,
        backgroundColor: FlightStatusColor.Irregular,
        color: theme.palette.primary.contrastText,
        padding: 3,
    },

    /**
     * 完了
     */
    Completed: {
        borderRadius: 3,
        backgroundColor: FlightStatusColor.Completed,
        color: theme.palette.primary.contrastText,
        padding: 3,
    },


    /**
     * キャンセル
     */
    Canceled: {
        borderRadius: 3,
        backgroundColor: FlightStatusColor.Canceled,
        color: theme.palette.primary.contrastText,
        padding: 3,
    },

    /**
     * リセット
     */
    Reset: {
        borderRadius: 3,
        backgroundColor: FlightStatusColor.Reset,
        color: theme.palette.primary.contrastText,
        padding: 3,
    },


    /**
     * 不明
     */
    unkown: {
        borderRadius: 3,
        backgroundColor: red[500],
        color: theme.palette.primary.contrastText,
        padding: 3,
    },



}));

/**
 * フライトステータスのサイズ
 */
export const StatusSize = {
    /**
     * 小
     */
    Small: "small",
    /**
     * 中
     */
    Medium: "medium"
} as const;
export type StatusSize = typeof StatusSize[keyof typeof StatusSize];

type FlightStatusInfoProps = {
    status: FlightStatus,
    statusSize?: StatusSize
}

/**
 * 配送依頼のステータス情報を表示する
 * @param props status
 */
const FlightStatusInfo: React.FC<FlightStatusInfoProps> = (props) => {
    const status = props.status as FlightStatus;
    const { classes } = useStyles();
    const [isEdit, setEdit] = React.useState(true);

    let contents;
    if (status == FlightStatus.Empty) {
        contents = { style: classes.Empty, label: "Empty" };
    } else if (status === FlightStatus.OBStandby) {
        contents = { style: classes.Standby, label: "StandBy" };
    } else if (status === FlightStatus.OBBeforePreparation) {
        contents = { style: classes.OB, label: "BeforePreparation" };
    } else if (status === FlightStatus.OBPreparation) {
        contents = { style: classes.OB, label: "Preparation" };
    } else if (status === FlightStatus.OBBeforeTakeoff) {
        contents = { style: classes.OB, label: "BeforeTakeoff" };
    } else if (status === FlightStatus.OBTakeoff) {
        contents = { style: classes.OB, label: "Takeoff" };
    } else if (status === FlightStatus.OBInFlightClimb) {
        contents = { style: classes.OB, label: "InFlight-Climb" };
    } else if (status === FlightStatus.OBInFlightCruise) {
        contents = { style: classes.OB, label: "InFlight-Cruise" };
    } else if (status === FlightStatus.OBInFlightDescent) {
        contents = { style: classes.OB, label: "InFlight-Descent" };
    } else if (status === FlightStatus.OBAfterLanding) {
        contents = { style: classes.OB, label: "AfterLanding" };
    } else if (
        status === FlightStatus.RTStandby ||
        status === FlightStatus.RTBeforePreparation ||
        status === FlightStatus.RTPreparation ||
        status === FlightStatus.RTBeforeTakeoff ||
        status === FlightStatus.RTTakeoff
    ) {
        contents = { style: classes.RT, label: "Takeoff" };
    } else if (status === FlightStatus.RTInFlightClimb) {
        contents = { style: classes.RT, label: "InFlight-Climb" };
    } else if (status === FlightStatus.RTInFlightCruise) {
        contents = { style: classes.RT, label: "InFlight-Cruise" };
    } else if (status === FlightStatus.RTInFlightDescent) {
        contents = { style: classes.RT, label: "InFlight-Descent" };
    } else if (status === FlightStatus.RTAfterLanding) {
        contents = { style: classes.RT, label: "AfterLanding" };
    } else if (status === FlightStatus.Completed) {
        contents = { style: classes.Completed, label: "Completed" };
    } else if (status === FlightStatus.Canceled) {
        contents = { style: classes.Canceled, label: "Canceled" };
    } else if (status === FlightStatus.Irregular) {
        contents = { style: classes.Irregular, label: "Irregular" };
    } else if (status === FlightStatus.Reset) {
        contents = { style: classes.Reset, label: "Reset" };
    } else {
        contents = { style: classes.unkown, label: "不明" };
    }

    if (props.statusSize === StatusSize.Small) {
        return (
            <Paper elevation={0} style={{ textAlign: "left", width: 160 }}>
                <Typography variant='caption' style={{ lineHeight: "2", padding: "6px" }} className={contents.style}>{contents.label}</Typography>
            </Paper>
        );
    } else if (props.statusSize === StatusSize.Medium) {
        return (
            <Paper elevation={0} style={{ textAlign: "left", display: "inline-block" }}>
                <Typography variant='subtitle1' style={{ lineHeight: "2", padding: "4px 10px" }} className={contents.style}>{contents.label}</Typography>
            </Paper>
        );
    } else {
        return (
            <Paper elevation={0} style={{ textAlign: "center", width: 160 }}>
                <Typography style={{ lineHeight: "2" }} className={contents.style}>{contents.label}</Typography>
            </Paper>
        );
    };
}

export default FlightStatusInfo;