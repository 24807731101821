import CancelIcon from '@mui/icons-material/Cancel';
import { Alert, Avatar, Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material';
import { blue } from '@mui/material/colors';
import { OrderInfo, ReceiveStatus } from 'adoms-common-lib';
import React, { useEffect } from "react";
import { APIConnector } from '../../connector/APIConnector';
import ReceiveStatusInfo from '../atoms/ReceiveStatusInfo';
import { LoadingMark } from '../atoms/LoadingMark';

type ReceiveStatusChangeDialogProps = {
    isReceiveStatusDialogOpen: boolean
    setReceiveStatusDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
    displayOrderInfo: OrderInfo
    setSavedOrderInfo: React.Dispatch<React.SetStateAction<boolean>>
}

/**
 * 配送物の受領ステータス変更ダイアログ
 * @param props 
 * @returns 
 */
export const ReceiveStatusChangeDialog: React.FC<ReceiveStatusChangeDialogProps> = (props) => {
    const ReceiveStatusList: ReceiveStatus[] = [
        ReceiveStatus.WaitingForPickup,
        ReceiveStatus.Preparation,
        ReceiveStatus.Loaded,
        ReceiveStatus.Received
    ];
    const [selectedReceiveStatus, setSelectedReceiveStatus] = React.useState<ReceiveStatus | undefined>(props.displayOrderInfo.receiveStatus);
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
    const [selectedOrderId, setSelectedOrderId] = React.useState<string | undefined>();
    const [isDisplayLoadingMark, setDisplayLoadingMark] = React.useState<boolean>(false);

    useEffect(() => {
        setSelectedOrderId(props.displayOrderInfo.orderID);
        if (selectedOrderId !== props.displayOrderInfo.orderID) {
            setSelectedReceiveStatus(props.displayOrderInfo.receiveStatus)
        };
    }, [props.displayOrderInfo]);

    const ReceiveStatusAvatar = (receiveStatus: ReceiveStatus, avatarValue: number | JSX.Element) => {
        return (
            <ListItemButton
                key={receiveStatus}
                onClick={() => setSelectedReceiveStatus(receiveStatus)}>
                <ListItemAvatar>
                    <Avatar sx={{ bgcolor: selectedReceiveStatus === receiveStatus ? blue[500] : undefined, width: 30, height: 30 }}>{avatarValue}</Avatar>
                </ListItemAvatar>
                <ListItemText primary={<ReceiveStatusInfo status={receiveStatus} />} />
            </ListItemButton>
        )
    }

    const handleReceiveStatusDialogClose = () => {
        setSelectedReceiveStatus(props.displayOrderInfo.receiveStatus);
        props.setReceiveStatusDialogOpen(false);
    };

    /**
     * 配送物の受領ステータスを更新する
     * @param newFlightInfo 
     */
    const handleUpdateOrderInfo = async () => {
        if (selectedReceiveStatus) {
            setDisplayLoadingMark(true);
            setErrorMessage(undefined);

            let newOrderInfo = structuredClone(props.displayOrderInfo);
            newOrderInfo.receiveStatus = selectedReceiveStatus;

            let apiConnector: APIConnector = APIConnector.instance;
            await apiConnector.putOrderChange(newOrderInfo).then(async value => {
                props.setSavedOrderInfo(true);
                props.setReceiveStatusDialogOpen(false);
                setDisplayLoadingMark(false);
            }).catch(error => {
                console.log(error);
                setErrorMessage("配送物の受領ステータスが更新できませんでした。");
                setDisplayLoadingMark(false);
            });
        };
    };

    return (
        <Dialog
            open={props.isReceiveStatusDialogOpen}
            onClose={handleReceiveStatusDialogClose}
        >
            <DialogTitle id="dialog-title">
                配送物の受領ステータス選択
            </DialogTitle>
            <DialogContent sx={{ paddingBottom: 0 }}>
                {errorMessage ?
                    <Alert severity="error" >{errorMessage}</Alert>
                    : undefined
                }
                <List>
                    {ReceiveStatusList.map((ReceiveStatus, index) => (
                        ReceiveStatusAvatar(ReceiveStatus, index + 1)
                    ))}
                    {ReceiveStatusAvatar(ReceiveStatus.Cancel, <CancelIcon />)}
                </List>
                <LoadingMark isDisplayLoadingMark={isDisplayLoadingMark} />
            </DialogContent>
            <DialogActions>
                <Button
                    color="secondary"
                    disabled={selectedReceiveStatus === props.displayOrderInfo.receiveStatus}
                    onClick={handleUpdateOrderInfo}>
                    登録
                </Button>
            </DialogActions>
        </Dialog>
    )
}