import { FunctionPrivilege, UserInfo } from "adoms-common-lib";

/**
 * 指定された機能の権限をユーザーが持っているか判定
 * @param functionPrivilege 機能用権限
 * @param userInfo ユーザー情報
 * @returns true:権限あり　false:権限なし
 */
export const havePrivilege = (functionPrivilege: FunctionPrivilege, userInfo?: UserInfo) => {
    if (userInfo?.privilege.functionPrivilegeInfoList.some(function (functionPrivilegeInfo) {
        return functionPrivilegeInfo.id === functionPrivilege
    })) {
        return true;
    } else {
        return false;
    };
};