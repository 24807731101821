import React, { useState, useEffect } from "react";
import { Alert, Divider, Select, MenuItem, InputLabel, FormControl, DialogContent, DialogTitle, TextField, Grid } from "@mui/material";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import { Port, PortValidator } from "adoms-common-lib";
import { prefecturesList } from "../../common/PrefecturesList";

// propsの型を定義
type PortAddressContentProps = {
  port: Port;
  setPort: React.Dispatch<React.SetStateAction<Port>>;
  setIsConfirmDisable: React.Dispatch<React.SetStateAction<boolean>>;
  errorMessage: string | undefined;
};

// ポート住所ダイアログの中身
const PortAddressContent: React.FC<PortAddressContentProps> = (props) => {
  const [portZipcodeState, setPortZipcodeState] = useState<string>(props.port.zipcode);
  const [portPrefectureState, setPortPrefectureState] = useState<string>(props.port.prefecture);
  const [portCityState, setportCityState] = useState<string>(props.port.city);
  const [portAddressState, setPortAddressState] = useState<string>(props.port.address);
  const [portLatitudeState, setPortLatitudeState] = useState<string>(props.port.latitude);
  const [portLongitudeState, setPortLongitudeState] = useState<string>(props.port.longitude);
  // エラーメッセージstate
  const [zipCodeErrorMessage, setZipCodeErrorMessage] = useState<string | undefined>(undefined);
  const [cityErrorMessage, setCityErrorMessage] = useState<string | undefined>(undefined);
  const [addressErrorMessage, setAddressErrorMessage] = useState<string | undefined>(undefined);
  const [latitudeErrorMessage, setLatitudeErrorMessage] = useState<string | undefined>(undefined);
  const [longitudeErrorMessage, setLongitudeErrorMessage] = useState<string | undefined>(undefined);

  // 郵便番号の入力チェック
  const portZipcodeCheck = (e: string) => {
    const portZipcode = e ? (e as string) : undefined;
    if (portZipcode == null) {
      setPortZipcodeState("");
      setZipCodeErrorMessage("郵便番号を入力してください");
      props.setPort({
        ...props.port,
        zipcode: "",
        inputDefaultZipcode: "",
      });
    } else if (!PortValidator.isZipCodeFormat(portZipcode)) {
      setZipCodeErrorMessage("郵便番号は半角数字7桁で入力してください");
      props.setPort({
        ...props.port,
        zipcode: "",
        inputDefaultZipcode: "",
      });
    } else {
      // state更新
      setZipCodeErrorMessage(undefined);
      props.setPort({
        ...props.port,
        zipcode: portZipcode,
        inputDefaultZipcode: portZipcode,
      });
    }
  };

  // 市区町村の入力チェック
  const portCityCheck = (e: string) => {
    const portCity = e ? (e as string) : undefined;
    if (portCity == null) {
      setportCityState("");
      setCityErrorMessage("市区町村を入力してください");
      props.setPort({
        ...props.port,
        city: "",
        inputDefaultCity: "",
      });
    } else if (!PortValidator.isCityFormat(portCity)) {
      setCityErrorMessage("市区町村は日本語で入力してください");
      props.setPort({
        ...props.port,
        city: "",
        inputDefaultCity: "",
      });
    } else {
      // state更新
      setCityErrorMessage(undefined);
      props.setPort({
        ...props.port,
        city: portCity,
        inputDefaultCity: portCity,
      });
    }
  };

  // 住所の入力チェック
  const portAddressCheck = (e: string) => {
    const portAddress = e ? (e as string) : undefined;
    if (portAddress == null) {
      setPortAddressState("");
      setAddressErrorMessage("住所を入力してください");
      props.setPort({
        ...props.port,
        address: "",
        inputDefaultAddress: "",
      });
    } else {
      // state更新
      setAddressErrorMessage(undefined);
      props.setPort({
        ...props.port,
        address: portAddress,
        inputDefaultAddress: portAddress,
      });
    }
  };

  // 緯度の入力チェック
  const portLatitudeCheck = (e: string) => {
    const portLatitude = e ? (e as string) : undefined;
    if (portLatitude == null) {
      setPortLatitudeState("");
      setLatitudeErrorMessage("緯度を入力してください");
      props.setPort({
        ...props.port,
        latitude: "",
      });
      return;

    } else if (!PortValidator.isLatLonFormat(portLatitude)) {
      setLatitudeErrorMessage("緯度は半角数字、符号あり小数で入力してください");
      props.setPort({
        ...props.port,
        latitude: "",
      });
      return;
    }
    if (!(parseInt(portLatitude) >= -90 && parseInt(portLatitude) <= 90)) {
      setLatitudeErrorMessage("-90度から90度の間で入力してください")
      props.setPort({
        ...props.port,
        latitude: "",
      });
      return;
    }
    // state更新
    setLatitudeErrorMessage(undefined);
    props.setPort({
      ...props.port,
      latitude: portLatitude,
    });
  };

  // 経度の入力チェック
  const portLongitudeCheck = (e: string) => {
    const portLongitude = e ? (e as string) : undefined;
    if (portLongitude == null) {
      setLongitudeErrorMessage("経度を入力してください");
      props.setPort({
        ...props.port,
        longitude: "",
      });
      return;

    } else if (!PortValidator.isLatLonFormat(portLongitude)) {
      setLongitudeErrorMessage("経度は半角数字、符号あり小数で入力してください");
      props.setPort({
        ...props.port,
        longitude: "",
      });
      return;
    }
    if (!(parseInt(portLongitude) >= -180 && parseInt(portLongitude) <= 180)) {
      setLongitudeErrorMessage("-180度から180度の間で入力してください");
      props.setPort({
        ...props.port,
        longitude: "",
      });
      return;
    }
    // state更新
    setLongitudeErrorMessage(undefined);
    props.setPort({
      ...props.port,
      longitude: portLongitude,
    });
  };

  // すべて入力されているかのチェック
  useEffect(() => {
    if (
      props.port.zipcode !== "" &&
      props.port.prefecture !== "" &&
      props.port.city !== "" &&
      props.port.address !== "" &&
      props.port.latitude !== "" &&
      props.port.longitude !== ""
    ) {
      // 全ての項目が入力されている場合、ボタンを有効にする
      props.setIsConfirmDisable(false);
    } else {
      props.setIsConfirmDisable(true);
    }
  }, [
    props.port.zipcode,
    props.port.prefecture,
    props.port.city,
    props.port.address,
    props.port.latitude,
    props.port.longitude
  ]);

  return (
    <>
      {/* ダイアログタイトル */}
      <DialogTitle id="responsive-dialog-title">
        <AddLocationAltIcon style={{ marginBottom: -4, marginRight: 5 }} />
        ポート住所登録
        <Divider />
      </DialogTitle>

      {/* ダイアログコンテンツ */}
      <DialogContent>
        <Grid container spacing={2} direction="column" maxWidth={"500px"}>

          {/* DB登録エラー時 */}
          <Grid item>
            {props.errorMessage ? <Alert severity="error">{props.errorMessage}</Alert> : null}
          </Grid>

          <Grid
            item
            container
            spacing={2}
            direction="row"
          >
            {/* 郵便番号 */}
            <Grid item xs={6} >
              <TextField
                fullWidth
                size="small"
                variant="filled"
                id="date"
                label="郵便番号"
                placeholder="ハイフンなし半角7桁"
                type="text"
                inputProps={{ maxLength: "7" }}
                value={portZipcodeState}
                error={zipCodeErrorMessage ? true : false}
                helperText={zipCodeErrorMessage ? zipCodeErrorMessage : null}
                onChange={(e) => {
                  setPortZipcodeState(e.target.value);
                  setZipCodeErrorMessage(undefined);
                }}
                onBlur={(e) => {
                  portZipcodeCheck(e.target.value);
                }}
              />
            </Grid>
          </Grid>

          <Grid
            item
            container
            spacing={2}
            direction="row"
          >
            {/* 都道府県 */}
            <Grid item xs={6} >
              <FormControl variant="filled" size="small" fullWidth>
                <InputLabel>都道府県</InputLabel>
                <Select
                  value={portPrefectureState}
                  onChange={(e) => {
                    // state更新
                    props.setPort({
                      ...props.port,
                      prefecture: e.target.value,
                      inputDefaultPrefecture: e.target.value,
                    });
                    setPortPrefectureState(e.target.value)
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 48 * 4.5,
                        width: 200,
                      },
                    },
                  }}
                >
                  {prefecturesList.map((prefecture, i) => (
                    <MenuItem
                      key={prefecture}
                      value={prefecture}
                    >
                      {prefecture}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* 市区町村 */}
            <Grid item xs={6} >
              <TextField
                fullWidth
                size="small"
                variant="filled"
                id="date"
                label="市区町村"
                type="text"
                value={portCityState}
                error={cityErrorMessage ? true : false}
                helperText={cityErrorMessage ? cityErrorMessage : null}
                onChange={(e) => {
                  setportCityState(e.target.value);
                  setCityErrorMessage(undefined);
                }}
                onBlur={(e) => {
                  portCityCheck(e.target.value);
                }}
              />
            </Grid>
          </Grid>

          {/* 住所 */}
          <Grid item xs={"auto"} >
            <TextField
              size="small"
              variant="filled"
              label="住所"
              type="text"
              fullWidth
              value={portAddressState}
              error={addressErrorMessage ? true : false}
              helperText={addressErrorMessage ? addressErrorMessage : null}
              onChange={(e) => {
                setPortAddressState(e.target.value);
                setAddressErrorMessage(undefined);
              }}
              onBlur={(e) => {
                portAddressCheck(e.target.value);
              }}
            />
          </Grid>

          <Grid
            item
            container
            spacing={2}
            direction="row"
          >
            {/* 緯度 */}
            <Grid item xs={6}>
              <TextField
                size="small"
                variant="filled"
                fullWidth
                label="緯度"
                type="text"
                value={portLatitudeState}
                error={latitudeErrorMessage ? true : false}
                helperText={latitudeErrorMessage ? latitudeErrorMessage : null}
                onChange={(e) => {
                  setPortLatitudeState(e.target.value);
                  setLatitudeErrorMessage(undefined);
                }}
                onBlur={(e) => {
                  portLatitudeCheck(e.target.value);
                }}
              />
            </Grid>

            {/* 経度 */}
            <Grid item xs={6}>
              <TextField
                size="small"
                variant="filled"
                fullWidth
                label="経度"
                type="text"
                value={portLongitudeState}
                error={longitudeErrorMessage ? true : false}
                helperText={longitudeErrorMessage ? longitudeErrorMessage : null}
                onChange={(e) => {
                  setPortLongitudeState(e.target.value);
                  setLongitudeErrorMessage(undefined);
                }}
                onBlur={(e) => {
                  portLongitudeCheck(e.target.value);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
};

export default PortAddressContent;