import { Alert, Theme, Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, InputLabel, ListItemIcon, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent, Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { GroupInfo } from 'adoms-common-lib/build/model/PrivilegeInfomation';
import React from "react";

const useStyles = makeStyles()((theme: Theme) =>
({
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    }
}));

type UserGroupEditDialogProps = {
    allGroupInfoList: GroupInfo[]
    isGroupEditDialogOpen: boolean;
    handleGroupEditDialogClose: () => void;
    checkedGroupInfoList: GroupInfo[];
    setCheckedGroupInfoList: React.Dispatch<React.SetStateAction<GroupInfo[]>>;
    checkedGroupInfoDisplaySubIdList: string[];
    setCheckedGroupInfoDisplaySubIdList: React.Dispatch<React.SetStateAction<string[]>>;
    groupErrorMessage: string | undefined;
    handleConfirmGroup: () => void;
    checkGroupSaveDisabled: (haveGroupInfoListForCheck: GroupInfo[]) => boolean;
}

export const UserGroupEditDialog: React.FC<UserGroupEditDialogProps> = (props) => {

    const { classes } = useStyles();
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;

    /**
     * グループが選択された時
     * @param event 
     */
    const handleGroupChange = (event: SelectChangeEvent<string[]>, child: React.ReactNode) => {
        const groupInfoDisplaySubIdList = event.target.value as string[];
        props.setCheckedGroupInfoDisplaySubIdList([...groupInfoDisplaySubIdList]);
        props.setCheckedGroupInfoList(props.allGroupInfoList.filter(groupInfo => {
            return groupInfoDisplaySubIdList.includes(groupInfo.data.displaySubId);
        }));
    };

    /**
     * グループが削除された時
     * @param displaySubId 
     */
    const chipDelete = (displaySubId: string) => {
        props.setCheckedGroupInfoDisplaySubIdList([...props.checkedGroupInfoDisplaySubIdList.filter(
            checkedGroupInfoDisplaySubId => checkedGroupInfoDisplaySubId !== displaySubId)]);
        props.setCheckedGroupInfoList([...props.checkedGroupInfoList.filter(checkedGroupInfo =>
            checkedGroupInfo.data.displaySubId !== displaySubId)
        ]);
    };

    return (
        <Dialog
            open={props.isGroupEditDialogOpen}
            onClose={props.handleGroupEditDialogClose}
        >
            <DialogTitle
                id="dialog-title"
                sx={{ padding: "16px 24px 0px" }}>グループ選択<Divider /></DialogTitle>
            <DialogContent
                id="dialog-content"
                style={{ padding: "16px 24px 20px" }}>
                <Grid container spacing={2} direction="column">
                    {props.groupErrorMessage ?
                        <Grid item>
                            <Alert
                                severity="error">
                                {props.groupErrorMessage}</Alert>
                        </Grid>
                        : undefined
                    }
                    <Grid item>
                        <FormControl sx={{ minWidth: 300 }}>
                            <InputLabel
                                id="group-select-label"
                                required>
                                グループ
                            </InputLabel>
                            <Select
                                labelId="group-multiple-checkbox-label"
                                id="group-multiple-checkbox"
                                label="グループ"
                                multiple
                                value={props.checkedGroupInfoDisplaySubIdList}
                                onChange={handleGroupChange}
                                renderValue={(selected) => (
                                    <div className={classes.chips}>
                                        {(selected as string[]).map((value) => (
                                            <Chip
                                                size='small'
                                                key={value}
                                                label={value}
                                                className={classes.chip}
                                                onDelete={(event) => chipDelete(value)}
                                                onMouseDown={(event) => { event.stopPropagation(); }} />
                                        ))}
                                    </div>
                                )}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                            width: 250
                                        }
                                    }
                                }}
                                input={<OutlinedInput label="グループ" />}>
                                {props.allGroupInfoList.map((groupInfo: GroupInfo) => {
                                    const labelId = `transfer-list-item-${groupInfo.id}-label`;
                                    return (
                                        <MenuItem key={groupInfo.data.displaySubId} value={groupInfo.data.displaySubId}>
                                            <ListItemIcon>
                                                <Checkbox
                                                    checked={props.checkedGroupInfoList.findIndex(checkedGroupInfo => checkedGroupInfo.id === groupInfo.id) !== -1}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                />
                                            </ListItemIcon>
                                            <ListItemText id={labelId} primary={groupInfo.data.displaySubId} />
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    color="secondary"
                    onClick={props.handleConfirmGroup}
                    disabled={props.checkGroupSaveDisabled(props.checkedGroupInfoList)}>
                    登録
                </Button>
            </DialogActions>
        </Dialog>
    );
}