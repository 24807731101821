import { Alert, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, InputLabel, ListItemIcon, ListItemText, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import { makeStyles } from 'tss-react/mui';
import TextField from '@mui/material/TextField';
import { BusinessPartnerInfo, GroupInfo, OperationPartnerInfo, PartnerKind, UserPartnerInfo, UserValidator } from 'adoms-common-lib';
import axios from 'axios';
import React from 'react';
import { APIConnector } from '../../connector/APIConnector';

const useStyles = makeStyles()((theme: any) => ({
    dialogActions: {
        padding: "8px 24px",
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    dialog: {
        maxWidth: 530,
    },
    paper: {
        height: 230,
        overflow: 'auto'
    },
    dialogContent: {
        overflow: "visible"
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    alert: {
        marginBottom: theme.spacing(2)
    },
}));

type SignUpDialogProps = {
    isSignUpDialogOpen: boolean;
    handleClickSignUpDialogClose: () => void;
    allGroupInfoList: GroupInfo[];
    allOperationPartnerInfoList: OperationPartnerInfo[];
    allBusinessPartnerInfoList: BusinessPartnerInfo[];
    setSaveUserInfo: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * ユーザー登録ダイアログ
 */
export const SignUpDialog: React.FC<SignUpDialogProps> = (props) => {
    const { classes } = useStyles();
    const [lastName, setLastName] = React.useState<string | undefined>();
    const [firstName, setFirstName] = React.useState<string | undefined>();
    const [email, setEmail] = React.useState<string | undefined>();
    const [selectedPartnerKind, setSelectedPartnerKind] = React.useState<string>("");
    const [selectedPartnerId, setSelectedPartnerId] = React.useState<string>("");
    const [checkedGroupInfoList, setCheckedGroupInfoList] = React.useState<GroupInfo[]>([]);
    const [checkedGroupInfoDisplaySubIdList, setCheckedGroupInfoDisplaySubIdList] = React.useState<string[]>([]);
    const [lastNameErrorMessage, setLastNameErrorMessage] = React.useState<string | undefined>();
    const [firstNameErrorMessage, setFirstNameErrorMessage] = React.useState<string | undefined>();
    const [emailErrorMessage, setEmailErrorMessage] = React.useState<string | undefined>();
    const [groupErrorMessage, setGroupErrorMessage] = React.useState<string | undefined>();
    const [signUpErrorMessage, setSignUpErrorMessage] = React.useState<string | undefined>();

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;

    /**
     * パートナーID選択Select
     */
    const partnerIdSelect = () => {
        if (selectedPartnerKind === PartnerKind.Operation) {
            return (
                <Select
                    variant="outlined"
                    labelId="partner-id-select-label"
                    id="partner-id-select"
                    value={selectedPartnerId}
                    label="パートナーID"
                    onChange={handlePartnerIdChange}
                    required>
                    {props.allOperationPartnerInfoList.map((operationPartnerInfo, i) => (
                        <MenuItem value={operationPartnerInfo.operationPartnerId}>{operationPartnerInfo.operationPartnerId}</MenuItem>
                    ))}
                </Select>
            );
        } else if (selectedPartnerKind === PartnerKind.Business) {
            return (
                <Select
                    variant="outlined"
                    labelId="partner-id-select-label"
                    id="partner-id-select"
                    value={selectedPartnerId}
                    label="パートナーID"
                    onChange={handlePartnerIdChange}
                    required>
                    {props.allBusinessPartnerInfoList.map((businessPartnerInfo, i) => (
                        <MenuItem value={businessPartnerInfo.businessPartnerId}>{businessPartnerInfo.businessPartnerId}</MenuItem>
                    ))}
                </Select>
            );
        } else {
            return (
                <Select
                    variant="outlined"
                    labelId="partner-id-select-label"
                    id="partner-id-select"
                    label="パートナーID"
                    value={selectedPartnerId}
                    disabled>
                </Select>
            );
        }
    };

    /**
    * 姓が変更された時
    * @param event 
    */
    const handleLastNameChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        console.log("event.target.value: " + event.target.value);

        const lastNameParam = event.target.value ? event.target.value as string : undefined;
        console.log("lastNameParam: " + lastNameParam);
        if (typeof lastNameParam === "undefined") {
            setLastNameErrorMessage("姓を入力してください");
        } else {
            setLastNameErrorMessage(undefined);
        }
        setLastName(lastNameParam);
    };

    /**
    * 名が変更された時
    * @param event 
    */
    const handleFirstNameChange = (event: React.ChangeEvent<{ value: unknown }>) => {

        const firstNameParam = event.target.value ? event.target.value as string : undefined;
        if (typeof firstNameParam === "undefined") {
            setFirstNameErrorMessage("名を入力してください");
        } else {
            setFirstNameErrorMessage(undefined);
        }
        setFirstName(event.target.value as string);
    };

    /**
    * メールアドレスが変更された時
    * @param event 
    */
    const handleEmailChange = (event: React.ChangeEvent<{ value: unknown }>) => {

        const emailParam = event.target.value ? event.target.value as string : undefined;
        if (typeof emailParam === "undefined") {
            setEmailErrorMessage("メールアドレスを入力してください");
        } else if (typeof emailParam !== "undefined" && !UserValidator.isMailAddress(emailParam)) {
            setEmailErrorMessage("メールアドレスの形式を確認してください");
        } else {
            setEmailErrorMessage(undefined);
        }
        setEmail(emailParam);
    };

    /**
    * パートナー種別が変更された時
    * @param event 
    */
    const handlePartnerKindChange = (event: SelectChangeEvent<string>, child: React.ReactNode) => {
        setSelectedPartnerKind(event.target.value ? event.target.value as string : "");
        setSelectedPartnerId("");
    };

    /**
     * パートナーIDが変更された時
     * @param event 
     */
    const handlePartnerIdChange = (event: SelectChangeEvent<string>, child: React.ReactNode) => {
        setSelectedPartnerId(event.target.value ? event.target.value as string : "");
    };

    /**
     * グループが選択された時
     * @param event 
     */
    const handleGroupChange = (event: SelectChangeEvent<string[]>, child: React.ReactNode) => {

        const groupInfoDisplaySubIdList = event.target.value ? event.target.value as string[] : [];
        if (typeof groupInfoDisplaySubIdList === "undefined" || groupInfoDisplaySubIdList.length === 0) {
            setGroupErrorMessage("グループを選択してください");
        } else {
            setGroupErrorMessage(undefined);
        }
        setCheckedGroupInfoDisplaySubIdList([...groupInfoDisplaySubIdList]);
        setCheckedGroupInfoList(props.allGroupInfoList.filter(groupInfo => {
            return groupInfoDisplaySubIdList.includes(groupInfo.data.displaySubId);
        }));
    };

    /**
     * グループが削除された時
     * @param displaySubId 
     */
    const handleChipDelete = (displaySubId: string) => {
        const filterGroupInfoDisplaySubIdList = checkedGroupInfoDisplaySubIdList.filter(
            checkedGroupInfoDisplaySubId => checkedGroupInfoDisplaySubId !== displaySubId);
        if (filterGroupInfoDisplaySubIdList.length === 0) {
            setGroupErrorMessage("グループを選択してください");
        } else {
            setGroupErrorMessage(undefined);
        }
        setCheckedGroupInfoDisplaySubIdList([...filterGroupInfoDisplaySubIdList]);
        setCheckedGroupInfoList([...checkedGroupInfoList.filter(groupInfo =>
            groupInfo.data.displaySubId !== displaySubId)
        ]);
    };

    /**
     * 登録ボタンを押した時
     */
    const handleClickSignUpButton = () => {
        let selectedUserPartnerInfo: UserPartnerInfo | undefined;
        if (selectedPartnerKind === PartnerKind.Operation) {
            const selectedOperationPartnerInfo = props.allOperationPartnerInfoList.find(
                operationPartnerInfo => { return operationPartnerInfo.operationPartnerId === selectedPartnerId });
            selectedUserPartnerInfo = {
                partnerKind: selectedPartnerKind,
                partnerInfo: selectedOperationPartnerInfo as OperationPartnerInfo
            };
        } else if (selectedPartnerKind === PartnerKind.Business) {
            const selectedBusinessPartnerInfo = props.allBusinessPartnerInfoList.find(
                businessPartnerInfo => { return businessPartnerInfo.businessPartnerId === selectedPartnerId });
            selectedUserPartnerInfo = {
                partnerKind: selectedPartnerKind,
                partnerInfo: selectedBusinessPartnerInfo as BusinessPartnerInfo
            };
        };

        const apigw: APIConnector = APIConnector.instance;
        apigw.signUp(
            lastName + " " + firstName,
            email!,
            selectedUserPartnerInfo!,
            checkedGroupInfoList).then(() => {
                props.handleClickSignUpDialogClose();

                // ユーザー一覧を更新する
                props.setSaveUserInfo(true);

                // 入力フォームを空にする
                setLastName(undefined);
                setFirstName(undefined);
                setEmail(undefined);
                setSelectedPartnerKind("");
                setSelectedPartnerId("");
                setCheckedGroupInfoList([]);
                setCheckedGroupInfoDisplaySubIdList([]);

            }).catch((error) => {
                if (axios.isAxiosError(error)
                    && typeof error.response !== "undefined"
                    && error.response.status === 403) {
                    setSignUpErrorMessage("ユーザー登録の権限がありません");
                } else {
                    setSignUpErrorMessage("ユーザー登録に失敗しました");
                };
            });
    };

    /**
     * ボタンを非有効化にするかどうか判定を行う
     * @returns true:ボタン非有効化 false:ボタン有効化
     */
    const isDisableSingUpButton = (): boolean => {
        if (typeof lastName !== "undefined"
            && typeof firstName !== "undefined"
            && typeof email !== "undefined"
            && selectedPartnerKind !== ""
            && selectedPartnerId !== ""
            && checkedGroupInfoList.length !== 0) {
            // 全ての項目が空ではない場合
            if (typeof emailErrorMessage === "undefined"
                && typeof lastNameErrorMessage === "undefined"
                && typeof firstNameErrorMessage === "undefined"
                && typeof groupErrorMessage === "undefined") {
                // 全ての項目でエラーが出ていない場合
                return false;
            };
        };
        return true;
    };

    return (
        <Dialog
            open={props.isSignUpDialogOpen}
            onClose={props.handleClickSignUpDialogClose}
            aria-labelledby="sign-up-dialog-title">
            <div className={classes.dialog}>
                <DialogTitle id="form-dialog-title">新規ユーザー登録</DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    {signUpErrorMessage ?
                        <Alert
                            severity="error"
                            className={classes.alert}>{signUpErrorMessage}</Alert>
                        : undefined
                    }
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="lastName"
                                label="姓"
                                name="姓"
                                placeholder="空野"
                                onChange={handleLastNameChange}
                                value={lastName}
                                error={lastNameErrorMessage ? true : false}
                                helperText={lastNameErrorMessage ? lastNameErrorMessage : undefined}
                                autoFocus
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="firstName"
                                label="名"
                                name="名"
                                placeholder="太郎"
                                value={firstName}
                                onChange={handleFirstNameChange}
                                error={firstNameErrorMessage ? true : false}
                                helperText={firstNameErrorMessage ? firstNameErrorMessage : undefined}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="email"
                                label="メールアドレス"
                                name="メールアドレス"
                                placeholder="abc@example.co.jp"
                                onChange={handleEmailChange}
                                value={email}
                                error={emailErrorMessage ? true : false}
                                helperText={emailErrorMessage ? emailErrorMessage : undefined}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel id="partner-kind-select-label" required>パートナー種別</InputLabel>
                                <Select
                                    variant="outlined"
                                    labelId="partner-kind-select-label"
                                    id="partner-kind-select"
                                    label="パートナー種別"
                                    onChange={handlePartnerKindChange}
                                    value={selectedPartnerKind}>
                                    <MenuItem value={PartnerKind.Operation}>オペレーションパートナー</MenuItem>
                                    <MenuItem value={PartnerKind.Business}>ビジネスパートナー</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel id="partner-id-select-label" required>パートナーID</InputLabel>
                                {partnerIdSelect()}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl
                                variant="outlined"
                                fullWidth
                                error={groupErrorMessage ? true : false}>
                                <InputLabel id="group-select-label" required>
                                    グループ
                                </InputLabel>
                                <Select
                                    variant="outlined"
                                    labelId="group-multiple-checkbox-label"
                                    id="group-multiple-checkbox"
                                    label="グループ"
                                    multiple
                                    value={checkedGroupInfoDisplaySubIdList}
                                    onChange={handleGroupChange}
                                    renderValue={(selected) => (
                                        <div className={classes.chips}>
                                            {(selected as string[]).map((value) => (
                                                <Chip
                                                    size='small'
                                                    key={value}
                                                    label={value}
                                                    className={classes.chip}
                                                    onDelete={(event) => handleChipDelete(value)}
                                                    onMouseDown={(event) => { event.stopPropagation() }} />
                                            ))}
                                        </div>
                                    )}
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                width: 250
                                            }
                                        }
                                    }}>
                                    {props.allGroupInfoList.map((groupInfo: GroupInfo) => {
                                        const labelId = `transfer-list-item-${groupInfo.id}-label`;
                                        return (
                                            <MenuItem key={groupInfo.data.displaySubId} value={groupInfo.data.displaySubId}>
                                                <ListItemIcon>
                                                    <Checkbox
                                                        checked={checkedGroupInfoList.findIndex(checkedGroupInfo => checkedGroupInfo.id === groupInfo.id) !== -1}
                                                        tabIndex={-1}
                                                        disableRipple
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText id={labelId} primary={groupInfo.data.displaySubId} />
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                                {groupErrorMessage ?
                                    <FormHelperText>{groupErrorMessage}</FormHelperText> : undefined
                                }
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        onClick={handleClickSignUpButton}
                        disabled={isDisableSingUpButton()}
                    >
                        登録
                    </Button>
                </DialogActions>
            </div>
        </Dialog>
    );
}