import { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import type { FillLayer, LineLayer, SymbolLayer } from 'react-map-gl';

/**
 * 分数を10分毎になるよう繰り上げる
 * @param dayjs 
 * @returns 
 */
export const changeMinutes10MinuteIncrements = (dayjs: Dayjs): Dayjs => {

    const unit = "minute";
    const amount = 10;
    const minutesNumber = Number(dayjs.get(unit));
    if (minutesNumber % amount === 0) {
        return dayjs.startOf(unit);
    } else {
        return dayjs.add(amount - (dayjs.get(unit) % amount), unit)
            .startOf(unit);
    };
};

/**
 * 降水量の配色見本
 */
export const rainSampleColorList = [
    {
        color: "#f90000",
        value: 64
    },
    {
        color: "#f99700",
        value: 32
    },
    {
        color: "#edf900",
        value: 16
    },
    {
        color: "#35f900",
        value: 8
    },
    {
        color: "#0ce4f6",
        value: 4
    },
    {
        color: "#6666ff",
        value: 2
    },
    {
        color: "#ccccff",
        value: 1
    },
    {
        color: "#ffffff",
        value: 0
    }
];

/**
 * 風速の配色見本
 */
export const windSpeedSampleColorList = [
    {
        color: "#f90000",
        value: 11
    },
    {
        color: "#f99700",
        value: 9
    },
    {
        color: "#edf900",
        value: 7
    },
    {
        color: "#35f900",
        value: 5
    },
    {
        color: "#6666ff",
        value: 3
    },
    {
        color: "#ffffff",
        value: 0
    }
];

export const lineLayerStyle: LineLayer = {
    id: 'line',
    type: 'line',
    paint: {
        "line-color": "#ea9245",
        "line-width": 3
    }
};

export const rainFillLayerStyle: FillLayer = {
    id: 'rain',
    type: 'fill',
    paint: {
        "fill-color": [
            "interpolate",
            ["linear"],
            ["get", "rain"],
            0, "transparent",
            1, rainSampleColorList[6].color,
            2, rainSampleColorList[5].color,
            4, rainSampleColorList[4].color,
            8, rainSampleColorList[3].color,
            16, rainSampleColorList[2].color,
            32, rainSampleColorList[1].color,
            64, rainSampleColorList[0].color
        ],
        "fill-opacity": 0.5,
        "fill-outline-color": "transparent"
    }
};

export const windSymbolLayerStyle = (): SymbolLayer => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    return {
        id: 'wind-icons',
        type: 'symbol',
        layout: {
            'icon-image': 'navigation',
            'icon-rotate': ['+', ['get', 'windDirection'], 180],
            'icon-rotation-alignment': 'map',
            'icon-allow-overlap': true,
            'icon-size': [
                'interpolate',
                ['linear'],
                ['zoom'],
                10, // ズームレベル10のときのアイコンサイズ
                0,
                12, // ズームレベル12のときのアイコンサイズ
                isMobile ? 0.5 : 0.1,
                15, // ズームレベル15のときのアイコンサイズ
                isMobile ? 0.7 : 0.2,
                20, // ズームレベル20のときのアイコンサイズ
                isMobile ? 1 : 0.3
            ],

        },
        paint: {
            'icon-color': [
                'interpolate',
                ['linear'],
                ['get', 'windSpeed'],
                0,
                "transparent",
                3,
                windSpeedSampleColorList[4].color,
                5,
                windSpeedSampleColorList[3].color,
                7,
                windSpeedSampleColorList[2].color,
                9,
                windSpeedSampleColorList[1].color,
                11,
                windSpeedSampleColorList[0].color,
            ],
        }
    };
}

export const isWeatherAPIAccessible = (): boolean => {
    const nowMinutes = Number(dayjs().format("mm"));

    if (21 <= nowMinutes
        && nowMinutes <= 23) {
        // 毎時21分～23分の場合
        return false;
    } else {
        return true;
    };
};