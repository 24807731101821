import { Button, Dialog, DialogActions, DialogContent, Backdrop, CircularProgress} from "@mui/material"
import { makeStyles } from 'tss-react/mui';
import React, { useEffect } from "react"
import { FlightCheckListItem } from "../../pages/operation/flight/FlightDetailView";
import { ListForCheckListAndProcedure } from "./ListForCheckListAndProcedure";

type FlightCheckListDialogProps = {
    isOpen: boolean,
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
    displayCheckListMap: Map<string, FlightCheckListItem[]>,
    handleSave: () => Promise<void>;
}

const useStyles = makeStyles()((theme: any) => ({
    text: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    dialogHeader: {
        color: 'black',
        margin: theme.spacing(1)
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    }
}));

export const FlightCheckListDialog: React.FC<FlightCheckListDialogProps> = (props) => {
    const { classes } = useStyles();
    const [isDisplayLoadingMark, setDisplayLoadingMark] = React.useState(false);
    const [haveBeenCheckForKey, setHaveBeenCheckForKey] = React.useState<Map<string, boolean>>(new Map<string, boolean>());

    useEffect(() => {
        if (!props.isOpen) {
            console.log('close');
            if (haveBeenCheckForKey.size !== 0) {
                setHaveBeenCheckForKey(new Map<string, boolean>());
            }
        }
    }, [props.isOpen]);
       
    const displayAllFlightCheckList = () => {
        let returnFlightCheckList: JSX.Element[] = [];
        props.displayCheckListMap.forEach((checkList, key) => {
            returnFlightCheckList.push(
                <ListForCheckListAndProcedure
                    flightStatus={key}
                    displayFlightCheckList={checkList}
                />
            );
        });
        
        return returnFlightCheckList;
    };

    /**
     * キャンセルボタンが押された際のハンドラ
     */
    const handleCancel = () => {
        props.setIsOpen(false)
    };

    const handleSave = async () => {
        setDisplayLoadingMark(true);
        await props.handleSave();
        props.setIsOpen(false);
        setDisplayLoadingMark(false);
    }

    return <Dialog open={props.isOpen} aria-labelledby="form-dialog-title">
        <DialogContent>
            <div className={classes.text}>
                以下チェックリストを再度確認し、チェックを入れてください。<br />
            </div>
            {displayAllFlightCheckList()}
            <Backdrop className={classes.backdrop} open={isDisplayLoadingMark}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleCancel} color="primary">
                キャンセル
            </Button>
            <Button disabled={false} onClick={(event: any) => {handleSave()}} color="primary">
                保存
            </Button>
        </DialogActions>
    </Dialog>
}