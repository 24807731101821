import CheckIcon from '@mui/icons-material/Check';
import { Alert, Box, Button, ButtonGroup, Chip, CircularProgress, Grid, Hidden, Paper, Typography, styled } from '@mui/material';
import { FlightInfo, flightStatusForCheckList } from "adoms-common-lib";
import React, { useEffect } from 'react';
import { csvReader } from "../../common/CsvUtil";
import { FlightCheckListItem } from '../../pages/operation/flight/FlightDetailView';
import { FlightCheckListStatus } from '../molecule/FlightCheckListStatus';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { red } from '@mui/material/colors';

type FlightCheckListGridProps = {
    selectedFlightInfo: FlightInfo | undefined
}

export const CheckListKind = {
    RP: "RP",
    NonNormal: "NonNormal",
    TakeoffSupporter: "TakeoffSupporter",
    LandingSupporter: "LandingSupporter",
} as const;
export type CheckListKind = typeof CheckListKind[keyof typeof CheckListKind];

const FlightCheckListBox = styled(Box)(({ theme }) => ({
    padding: 3,
    height: `calc(100vh - 302px)`,
    overflow: "auto"
}));

/**
 * チェックリスト表示
 */
export const FlightCheckListGrid: React.FC<FlightCheckListGridProps> = (props) => {
    const [fdCheckListMap, setFdCheckListMap] = React.useState<Map<string, FlightCheckListItem[]>>(new Map<string, FlightCheckListItem[]>())
    const [landingSupporterCheckListMap, setLandingSupporterCheckListMap] =
        React.useState<Map<string, FlightCheckListItem[]>>(new Map<string, FlightCheckListItem[]>())
    const [takeoffSupporterCheckListMap, setTakeoffSupporterCheckListMap] =
        React.useState<Map<string, FlightCheckListItem[]>>(new Map<string, FlightCheckListItem[]>())
    const [nonNormalProcedure, setNonNormalProcedure] = React.useState<FlightCheckListItem[]>([])
    const [selectCheckListKind, setSelectCheckListKind] = React.useState<string>(CheckListKind.RP);
    const [selectFlightStatusIndex, setSelectFlightStatusIndex] = React.useState(0);
    const [displayCircularProgress, setDisplayCircularProgress] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
    const [selectedFlightId, setSelectedFlightId] = React.useState<string | undefined>(undefined);

    useEffect(() => {
        setSelectedFlightId(props.selectedFlightInfo?.id);
        if (selectedFlightId !== props.selectedFlightInfo?.id) {
            setSelectCheckListKind(CheckListKind.RP);
            fetchCheckList();
        };
    }, [props.selectedFlightInfo]);

    const fetchCheckList = async () => {
        if (props.selectedFlightInfo) {
            setDisplayCircularProgress(true);
            await csvReader(props.selectedFlightInfo).then(checkList => {
                setErrorMessage(undefined);
                if (checkList) {
                    setFdCheckListMap(checkList.fdCheckListMap);
                    setLandingSupporterCheckListMap(checkList.landingSupporterCheckListMap);
                    setTakeoffSupporterCheckListMap(checkList.takeoffSupporterCheckListMap);
                    setNonNormalProcedure(checkList.nonNormalProcedureList);
                };
                setDisplayCircularProgress(false);
            }).catch(reason => {
                console.log(reason);
                setErrorMessage("チェックリストを読み込めませんでした。");
                setDisplayCircularProgress(false);
            });
        };
    };

    const displayCheckList = (nonNormalFlg: boolean, flightCheckListItemList?: FlightCheckListItem[]) => {
        if (flightCheckListItemList && flightCheckListItemList?.length > 0) {
            return (
                <Grid container spacing={2} direction="column">
                    {flightCheckListItemList?.map((flightCheckListItem, index) => (
                        <Grid item key={index}>
                            {nonNormalFlg ?
                                <Paper sx={{ padding: 1 }} variant='outlined'>
                                    <Grid container direction="column">
                                        <Grid item container direction="row" spacing={1} alignItems={"center"}>
                                            <Grid item xs="auto" marginTop="8px">
                                                <CheckBoxIcon />
                                            </Grid>
                                            <Grid item xs sx={{ whiteSpace: 'pre-wrap', minWidth: "100px" }}>
                                                <Typography>{flightCheckListItem.CheckList}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item container xs="auto">
                                            <Grid item whiteSpace='pre-wrap'>
                                                <Chip
                                                    sx={{
                                                        height: 'auto',
                                                        '& .MuiChip-label': {
                                                            display: 'inline-block',
                                                            whiteSpace: 'pre-wrap',
                                                            fontSize: "16px"
                                                        },
                                                    }}
                                                    label={flightCheckListItem.Value}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                                :
                                <Grid item xs>
                                    <Paper sx={{ padding: 1 }} variant='outlined'>
                                        <Grid container spacing={2} direction={"row"} alignItems={"center"}>
                                            <Grid item marginTop="8px" xs={"auto"}>
                                                <CheckBoxIcon />
                                            </Grid>
                                            <Grid item container spacing={2} direction={"row"} alignItems={"center"} xs justifyContent={"space-between"}>
                                                <Grid item whiteSpace="pre-wrap">
                                                    <Typography
                                                        sx={{
                                                            color: flightCheckListItem.Critical
                                                                ? red[500] : undefined,
                                                            fontWeight: flightCheckListItem.Critical
                                                                ? 'bold' : undefined
                                                        }}>{flightCheckListItem.CheckList}</Typography>
                                                </Grid>
                                                {flightCheckListItem.Value ?
                                                    <Grid item>
                                                        <Chip
                                                            sx={{
                                                                height: 'auto',
                                                                '& .MuiChip-label': {
                                                                    display: 'block',
                                                                    whiteSpace: 'pre-wrap',
                                                                    fontSize: "16px"
                                                                },
                                                            }}
                                                            label={flightCheckListItem.Value}
                                                        />
                                                    </Grid>
                                                    : undefined}
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            }
                        </Grid>
                    ))}
                </Grid>
            )
        } else if (displayCircularProgress) {
            return (
                <Grid item textAlign={"center"}>
                    <CircularProgress />
                </Grid >
            )
        } else if (errorMessage) {
            return (
                <Alert severity="error">{errorMessage}</Alert>
            )
        } else {
            return (
                <Alert severity="info">チェックリストが設定されていません。</Alert>
            )
        }
    }

    const flightStatusButtonGroup = () => {
        const flightCheckListKindButton = (checkListKind: CheckListKind, disableFlg: boolean) => {
            return (
                <Button
                    onClick={() => setSelectCheckListKind(checkListKind)}
                    startIcon={selectCheckListKind === checkListKind
                        ? <CheckIcon /> : undefined}
                    value={checkListKind}
                    disabled={disableFlg}
                    sx={{
                        textTransform: 'none',
                        backgroundColor: selectCheckListKind === checkListKind ? '#d2eafc' : 'initial',
                        '&:hover': {
                            backgroundColor: selectCheckListKind === checkListKind ? '#d2eafc' : 'initial',
                        },
                    }}>
                    {checkListKind}
                </Button>
            )
        };

        return (
            <React.Fragment>
                {flightCheckListKindButton(CheckListKind.RP, fdCheckListMap.size === 0)}
                {flightCheckListKindButton(CheckListKind.NonNormal, nonNormalProcedure.length === 0)}
                {flightCheckListKindButton(CheckListKind.TakeoffSupporter, takeoffSupporterCheckListMap.size === 0)}
                {flightCheckListKindButton(CheckListKind.LandingSupporter, landingSupporterCheckListMap.size === 0)}
            </React.Fragment>
        )
    }

    return (
        props.selectedFlightInfo ?
            <Grid container spacing={2}>
                <Grid item sx={{ overflow: "auto" }}>
                    <Hidden only={["sm", "xs"]}>
                        <ButtonGroup size='small'>
                            {flightStatusButtonGroup()}
                        </ButtonGroup >
                    </Hidden>
                    <Hidden only={["md", "lg", "xl"]}>
                        <ButtonGroup size='small' orientation="vertical">
                            {flightStatusButtonGroup()}
                        </ButtonGroup >
                    </Hidden>
                </Grid>
                {selectCheckListKind === CheckListKind.RP && fdCheckListMap ?
                    <Grid item xs={12}>
                        <FlightCheckListBox >
                            <Grid container spacing={2}>
                                <FlightCheckListStatus
                                    selectFlightStatusIndex={selectFlightStatusIndex}
                                    setSelectFlightStatusIndex={setSelectFlightStatusIndex} />
                                <Grid item sm={12}>
                                    {displayCheckList(false, fdCheckListMap.get(flightStatusForCheckList[selectFlightStatusIndex]))}
                                </Grid>
                            </Grid>
                        </FlightCheckListBox>
                    </Grid>
                    :
                    undefined
                }
                {selectCheckListKind === CheckListKind.TakeoffSupporter && takeoffSupporterCheckListMap ?
                    <Grid item xs={12}>
                        <FlightCheckListBox >
                            <Grid container spacing={2}>
                                <FlightCheckListStatus
                                    selectFlightStatusIndex={selectFlightStatusIndex}
                                    setSelectFlightStatusIndex={setSelectFlightStatusIndex} />
                                <Grid item sm={12}>
                                    {displayCheckList(false, takeoffSupporterCheckListMap.get(flightStatusForCheckList[selectFlightStatusIndex]))}
                                </Grid>
                            </Grid>
                        </FlightCheckListBox>
                    </Grid>
                    :
                    undefined
                }
                {selectCheckListKind === CheckListKind.LandingSupporter && landingSupporterCheckListMap ?
                    <Grid item xs={12}>
                        <FlightCheckListBox >
                            <Grid container spacing={2}>
                                <FlightCheckListStatus
                                    selectFlightStatusIndex={selectFlightStatusIndex}
                                    setSelectFlightStatusIndex={setSelectFlightStatusIndex} />
                                <Grid item sm={12}>
                                    {displayCheckList(false, landingSupporterCheckListMap.get(flightStatusForCheckList[selectFlightStatusIndex]))}
                                </Grid>
                            </Grid>
                        </FlightCheckListBox>
                    </Grid>
                    :
                    undefined
                }
                {selectCheckListKind === CheckListKind.NonNormal && nonNormalProcedure ?
                    <Grid item xs={12}>
                        <FlightCheckListBox>
                            <Grid container spacing={2}>
                                <Grid item sm={12}>
                                    {displayCheckList(true, nonNormalProcedure)}
                                </Grid>
                            </Grid>
                        </FlightCheckListBox>
                    </Grid>
                    :
                    undefined
                }
            </Grid >
            :
            <Alert severity="info" >フライトを選択してください</Alert>
    )
}