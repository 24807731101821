import { InputAdornment, List, ListItem, ListItemSecondaryAction, ListItemText, ListSubheader, TextField } from "@mui/material"
import { makeStyles } from 'tss-react/mui';
import React from "react"
import { FlightCheckListItem } from "../../pages/operation/flight/FlightDetailView";

const useStyles = makeStyles()((theme: any) => ({
    listSubheader: {
        fontWeight: 'bold',
        color: 'black',
        textAlign: 'left',
        fontSize: '125%'
    },

    listItemTextLeft: {
        fontSize: '85%',
        marginRight: 12
    },

    checkListItemLeft: {
        flex: 2,
        whiteSpace: 'pre-wrap'
    },

    nonNormalProcedureItemLeft: {
        flex: 1
    },

    listItemTextRight: {
        fontSize: '85%'
    },

    checkListItemRight: {
        flex: 1
    },

    criticalChecklist: {
        color: 'red'
    },

    nonNormalProcedureItemRight: {
        flex: 2,
        whiteSpace: 'pre-wrap'
    },


    listSecondaryAction: {
        width: '32%',
        flex: 1
    },

    listSecondaryActionTextfield: {
        width: '100%',
    }
}));

type ListForCheckListAndProcedure = {
    flightStatus: string,
    displayFlightCheckList: FlightCheckListItem[]
}

export const ListForCheckListAndProcedure: React.FC<ListForCheckListAndProcedure> = (props) => {
    const { classes } = useStyles();

    /**
     * チェックリストの1項目ごとにリスト表示用の部品を作成する関数
     * @param checkList チェックリストの1項目
     * @returns リスト表示用の部品
     */
    const makeListItem = (checkList: FlightCheckListItem, index: number) => {
        let secondary: JSX.Element = checkList.Input ?
            <ListItemSecondaryAction
                key={`${props.flightStatus}ListItemSecondaryAction${index}`}
                className={classes.listSecondaryAction}
            >
                <TextField
                    variant="standard"
                    className={classes.listSecondaryActionTextfield}
                    key={`${props.flightStatus}ListItemTextSecondaryTextField${index}`}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            {checkList.Value}
                        </InputAdornment>
                    }} />
            </ListItemSecondaryAction> :
            <ListItemText
                primary={checkList.Value}
                className={`${classes.listItemTextRight} ${
                    props.flightStatus !== 'NonNormal Procedure' ? 
                    classes.checkListItemRight : 
                    classes.nonNormalProcedureItemRight
                } ${
                    checkList.Critical ?
                    classes.criticalChecklist : 
                    ''
                }
                
                `}
                primaryTypographyProps={
                    checkList.Critical ? 
                    {
                        style: {
                            textAlign: 'left',
                            fontSize: '150%',
                            fontWeight: 'bold'
                        }
                    } :
                    {
                        style: {
                            textAlign: 'left',
                            fontSize: '120%'
                        }
                    }
                }
            />

        return <ListItem key={`${props.flightStatus}ListItem${index}`} divider={props.flightStatus === 'NonNormal Procedure'}>
            <ListItemText
                key={`${props.flightStatus}ListItemTextPrimary${index}`}
                primary={checkList.CheckList}
                className={`${classes.listItemTextRight} ${
                    props.flightStatus !== 'NonNormal Procedure' ? 
                    classes.checkListItemLeft : 
                    classes.nonNormalProcedureItemLeft
                } ${
                    checkList.Critical ?
                    classes.criticalChecklist : 
                    ''
                }`}
                primaryTypographyProps={
                    checkList.Critical ? 
                    {
                        style: {
                            fontSize: '150%',
                            fontWeight: 'bold'
                        }
                    } :
                    {
                        style: {
                            fontSize: '120%'
                        }
                    }
                }
            />
            {secondary}
        </ListItem>
    };

    return <List key={`${props.flightStatus}List`}>
        <ListSubheader key={`${props.flightStatus}ListSubheader`} className={classes.listSubheader} disableSticky={true}>
            {props.flightStatus}
        </ListSubheader>
        {props.displayFlightCheckList.map((checkList, index) => (
            makeListItem(checkList, index)
        ))}
    </List>
}