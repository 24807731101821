import React, { useState, useEffect } from "react";
import { Dialog, Button, MobileStepper, styled } from "@mui/material";
import { Port, PortType, createEmptyPort } from "adoms-common-lib";
import PortUpdateInformationContent from "../molecule/PortUpdateInformationContent";
import axios from "axios";
import { APIConnector } from "../../connector/APIConnector";
import PortAddressDialog from "../molecule/PortAddressContent";

// propsの型を定義
type PortUpdateDialogProps = {
  isOpen: boolean;
  closeHandler: () => void;
  port?: Port;
  allPortList?: Port[];
  setEditPortList: React.Dispatch<React.SetStateAction<boolean>>;
}

// MobileStepperのスタイル
const PortMobileStepper = styled(MobileStepper)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  backgroundColor: theme.palette.background.paper,
}));

const PortUpdateDialog: React.FC<PortUpdateDialogProps> = (props) => {
  const [updatePort, setUpdatePort] = useState<Port>(createEmptyPort());
  const [activeStep, setActiveStep] = useState(0);
  const [isNextDisable, setNextDisable] = useState(false);
  const [isConfirmDisable, setIsConfirmDisable] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [initialPortType, setInitialPortType] = useState<PortType | undefined>();
  const [editingDestinationPortList, setEditingDestinationPortList] = useState<string[]>([]);

  const handleClose = () => {
    setErrorMessage(undefined);
    props.closeHandler();
  };

  // 次へを押したとき
  const nextHandler = () => {
    setActiveStep((prevSteps) => prevSteps + 1)
  }

  // 戻るを押したとき
  const backHandler = () => {
    setActiveStep((prevSteps) => prevSteps - 1)
  }

  // ダイアログが閉じるたびにstate初期化
  useEffect(() => {
    setActiveStep((prevSteps) => (prevSteps = 0));
    setNextDisable(() => false);

    if (props.port) {
      // ポート情報の1階層目をシャローコピー
      let newPort: Port = structuredClone(props.port);

      // stateに値をセット
      setUpdatePort(newPort);
      setEditingDestinationPortList(newPort.destinationPortList)
      setInitialPortType(newPort.portType);
    }
  }, [props.isOpen]);

  // 更新処理呼び出し
  const handleUpdate = (event: any) => {
    if (props.port && updatePort) {
      let apigw: APIConnector = APIConnector.instance;
      let result: Promise<void | string> = apigw.updatePort(
        props.port,
        updatePort
      );
      result.then(() => {
        props.closeHandler();
        props.setEditPortList(true);
        setErrorMessage(undefined);
      }).catch((error) => {
        console.log(error);
        if (axios.isAxiosError(error) && typeof error.response !== "undefined" && error.response.status === 403) {
          setErrorMessage("権限がありません");
        } else {
          setErrorMessage("ポート情報を更新できませんでした");
        }
      });
    }
  };

  return (
    <Dialog
      open={props.isOpen}
      onClose={() => { handleClose() }}
      sx={{ width: "auto" }}
      aria-labelledby="responsive-dialog-title"
    >
      {/* ポート情報のダイアログコンテンツ*/}
      {activeStep === 0 && (
        <PortUpdateInformationContent
          isOpen={props.isOpen}
          updatePort={updatePort}
          setUpdatePort={setUpdatePort}
          setIsNextDisable={setNextDisable}
          allPortList={props.allPortList}
          initialPortType={initialPortType}
          editingDestinationPortList={editingDestinationPortList}
        />
      )}

      {/* ポート住所のダイアログコンテンツ */}
      {activeStep === 1 && (
        <PortAddressDialog
          port={updatePort}
          setPort={setUpdatePort}
          setIsConfirmDisable={setIsConfirmDisable}
          errorMessage={errorMessage}
        />
      )}

      {/* ダイアログボタン関連 */}
      <PortMobileStepper
        variant="dots"
        steps={2}
        position="static"
        activeStep={activeStep}
        nextButton={
          activeStep !== 1 ?
            (<Button color="primary" size="small" onClick={nextHandler} disabled={isNextDisable}>次へ</Button>) :
            (<Button color="secondary" size="small" onClick={handleUpdate} disabled={isConfirmDisable}>確定</Button>)
        }
        backButton={
          <Button color="primary" size="small" onClick={backHandler} disabled={activeStep === 0}>戻る</Button>
        }
      />
    </Dialog>
  )
}
export default PortUpdateDialog; 