import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, TextField, TextareaAutosize, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { FlightInfo, Report, UserInfo } from 'adoms-common-lib';
import dayjs, { Dayjs } from 'dayjs';
import ja from 'dayjs/locale/ja';
import React, { useEffect } from "react";
import { APIConnector } from '../../connector/APIConnector';
import { DisplayInfoGrid } from '../atoms/DisplayInfoGrid';
import { LoadingMark } from '../atoms/LoadingMark';

type FlightReportDialogProps = {
    isFlightReportDialogOpen: boolean
    setFlightReportDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
    selectedFlightInfo: FlightInfo
    report: Report | undefined
    setSavedFlightInfo: React.Dispatch<React.SetStateAction<boolean>>
}

/**
 * フライトレポートダイアログ
 * @param props 
 * @returns 
 */
export const FlightReportDialog: React.FC<FlightReportDialogProps> = (props) => {

    const [squawkDate, setSquawkDate] = React.useState<Dayjs | null>(props.report?.squawkDate ? dayjs(props.report?.squawkDate) : null);
    const [flightSquawk, setFlightSquawk] = React.useState<string | undefined>(props.report?.flightSquawk);
    const [actionDate, setActionDate] = React.useState<Dayjs | null>(props.report?.actionDate ? dayjs(props.report?.actionDate) : null);
    const [correctiveAction, setCorrectiveAction] = React.useState<string | undefined>(props.report?.correctiveAction);
    const [confirmer, setConfirmer] = React.useState<string | undefined>(props.report?.confirmer);
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
    const [fdUserInfoList, setFdUserInfoList] = React.useState<UserInfo[]>();
    const [isDisplayLoadingMark, setDisplayLoadingMark] = React.useState<boolean>(false);

    useEffect(() => {
        if (!fdUserInfoList) {
            getFduserInfoList();
        };
        setReportValue();
    }, [props.report]);

    const getFduserInfoList = async () => {
        let apiConnector: APIConnector = APIConnector.instance;
        await apiConnector.getFlightDirectorList().then(userInfoList => {
            console.log(userInfoList)
            setFdUserInfoList(userInfoList);
        }).catch((error) => {
            console.log(error);
            setErrorMessage("RP一覧が取得できませんでした。");
        });
    };

    const handleFlightStatusDialogClose = () => {
        setReportValue();
        props.setFlightReportDialogOpen(false);
    };

    const setReportValue = () => {
        setSquawkDate(props.report?.squawkDate ? dayjs(props.report?.squawkDate) : null);
        setFlightSquawk(props.report?.flightSquawk);
        setActionDate(props.report?.actionDate ? dayjs(props.report?.actionDate) : null);
        setCorrectiveAction(props.report?.correctiveAction);
        setConfirmer(props.report?.confirmer);
    };

    const disabledSaveButton = (): boolean => {
        let flightSquawkParam;
        let correctiveActionParam;

        if (flightSquawk === "") {
            flightSquawkParam = undefined;
        } else {
            flightSquawkParam = flightSquawk;
        };

        if (correctiveAction === "") {
            correctiveActionParam = undefined;
        } else {
            correctiveActionParam = correctiveAction;
        };

        if (
            (props.report?.squawkDate ? squawkDate?.isSame(dayjs(props.report?.squawkDate)) : squawkDate === null)
            && (flightSquawkParam === props.report?.flightSquawk)
            && (props.report?.actionDate ? actionDate?.isSame(dayjs(props.report?.actionDate)) : actionDate === null)
            && correctiveActionParam === props.report?.correctiveAction
            && confirmer === props.report?.confirmer) {
            return true;
        } else if (squawkDate || flightSquawkParam || actionDate || correctiveActionParam || confirmer) {
            if (!squawkDate || !flightSquawkParam) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        };
    };

    const handleSaveReport = async () => {
        let report: Report | undefined
        if (squawkDate && flightSquawk) {
            report = {
                squawkDate: squawkDate.format('YYYY-MM-DD'),
                flightSquawk: flightSquawk,
                actionDate: actionDate ? actionDate.format('YYYY-MM-DD') : undefined,
                correctiveAction: correctiveAction ?? undefined,
                confirmer: confirmer ?? undefined
            };
        } else {
            report = undefined;
        };

        if (props.selectedFlightInfo
            && props.selectedFlightInfo.std
            && props.selectedFlightInfo.sta
            && props.selectedFlightInfo.etd
            && props.selectedFlightInfo.eta) {
            setDisplayLoadingMark(true);
            setErrorMessage(undefined);

            let flightScheduleChangeParam = {
                id: props.selectedFlightInfo.id,
                std: props.selectedFlightInfo.std,
                sta: props.selectedFlightInfo.sta,
                etd: props.selectedFlightInfo.etd,
                eta: props.selectedFlightInfo.eta,
                remarks: props.selectedFlightInfo.remarks,
                unsafeEvent: props.selectedFlightInfo.unsafeEvent,
                report: report
            };

            let apiConnector: APIConnector = APIConnector.instance;
            await apiConnector.putFlightScheduleChange(flightScheduleChangeParam).then(async value => {
                props.setSavedFlightInfo(true);
                props.setFlightReportDialogOpen(false);
                setDisplayLoadingMark(false);
            }).catch(error => {
                console.log(error);
                setErrorMessage("フライト情報を更新できませんでした。");
                setDisplayLoadingMark(false);
            });
        };
    };

    return (
        <Dialog
            open={props.isFlightReportDialogOpen}
            onClose={handleFlightStatusDialogClose}
        >
            <DialogTitle id="dialog-title">
                フライトレポート
            </DialogTitle>
            <DialogContent sx={{ paddingBottom: 0 }}>
                <Grid container direction="column" spacing={1} alignItems="stretch">
                    {errorMessage ?
                        <Grid item>
                            <Alert severity="error">{errorMessage}</Alert>
                        </Grid> : undefined
                    }
                    <Grid item container direction="row" spacing={1}>
                        <DisplayInfoGrid
                            xl={4} lg={4} md={4} sm={4} xs={12}
                            title="発生日時"
                            value={
                                <FormControl fullWidth>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                        adapterLocale={ja}
                                        dateFormats={{ monthAndYear: 'YYYY年MM月' }} >
                                        <DatePicker
                                            inputFormat="YYYY/MM/DD"
                                            toolbarFormat='YYYY年MM月DD日'
                                            value={squawkDate}
                                            onChange={newValue => setSquawkDate(newValue)}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                            } />
                        <DisplayInfoGrid
                            xl={8} lg={8} md={8} sm={8} xs={12}
                            title="不具合事項"
                            value={
                                <TextField
                                    value={flightSquawk}
                                    onChange={event => setFlightSquawk(event.target.value)}
                                    multiline
                                    maxRows="10"
                                    variant="outlined"
                                    fullWidth
                                />
                            } />
                    </Grid>
                    <Grid item container direction="row" spacing={1}>
                        <DisplayInfoGrid
                            xl={4} lg={4} md={4} sm={4} xs={12}
                            title="処置年月日"
                            value={
                                <FormControl fullWidth>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                        adapterLocale={ja}
                                        dateFormats={{ monthAndYear: 'YYYY年MM月' }} >
                                        <DatePicker
                                            inputFormat="YYYY/MM/DD"
                                            toolbarFormat='YYYY年MM月DD日'
                                            value={actionDate}
                                            onChange={newValue => setActionDate(newValue)}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                            } />
                        <DisplayInfoGrid
                            xl={8} lg={8} md={8} sm={8} xs={12}
                            title="処置その他"
                            value={
                                <TextField
                                    value={correctiveAction}
                                    onChange={event => setCorrectiveAction(event.target.value)}
                                    multiline
                                    maxRows="10"
                                    variant="outlined"
                                    fullWidth
                                />
                            } />
                        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                            <Grid item textAlign={"left"}>
                                <Typography variant="caption" color="textSecondary" component="div">
                                    確認者
                                </Typography>
                            </Grid>
                            <Grid item textAlign={"left"} xs>
                                <FormControl variant="outlined" fullWidth>
                                    <Select
                                        variant="outlined"
                                        value={confirmer}
                                        onChange={(e) => setConfirmer(
                                            e.target.value === "" ? undefined : e.target.value)}>
                                        <MenuItem key={"none"} value="">
                                            設定なし</MenuItem>
                                        {fdUserInfoList?.map((fdUserInfo, i) => (
                                            <MenuItem
                                                key={fdUserInfo.sub}
                                                value={fdUserInfo.name}>
                                                {fdUserInfo.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <LoadingMark isDisplayLoadingMark={isDisplayLoadingMark} />
            </DialogContent>
            <DialogActions>
                <Button
                    color="secondary"
                    disabled={disabledSaveButton()}
                    onClick={handleSaveReport}>
                    登録
                </Button>
            </DialogActions>
        </Dialog>
    )
}