import React, { useEffect } from 'react';
import { Grid, Snackbar, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import NavigationIcon from '@mui/icons-material/Navigation';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { blue } from '@mui/material/colors';
import { EnvSensorInfo, Port, Display } from 'adoms-common-lib';
import useInterval from 'use-interval';
import * as axios from 'axios';
import { APIConnector } from '../../connector/APIConnector';

const useStyles = makeStyles()((theme: any) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },

    alertWind: {
        textAlign: "right",
        width: "90px",
        backgroundColor: "#ff1744",
        display: "inline-block",
        borderRadius: "5px",
        paddingLeft: "10px",
        paddingRight: "10px",
        marginLeft: "5px",
        marginRight: "5px",
    },

    strongWind: {
        textAlign: "right",
        width: "90px",
        backgroundColor: "#ff9800",
        display: "inline-block",
        borderRadius: "5px",
        paddingLeft: "10px",
        paddingRight: "10px",
        marginLeft: "5px",
        marginRight: "5px",
    },

    normalWind: {
        textAlign: "right",
        width: "90px",
        backgroundColor: "#00a152",
        display: "inline-block",
        borderRadius: "5px",
        paddingLeft: "10px",
        paddingRight: "10px",
        marginLeft: "5px",
        marginRight: "5px",
    }
}));


export interface EnvSensorInfoBarProps {
    depPortDeviceId?: string,
    depPortName: string,
    arrPortDeviceId?: string,
    arrPortName: string,
    depPort: Port,
    arrPort: Port,
}

/**
 * 環境センサー情報を表示するバー
 * 一定秒数でポーリングして情報を更新する。
 * @param props 
 * @returns 
 */
export default function EnvSensorInfoBar(props: EnvSensorInfoBarProps) {
    const { classes } = useStyles();
    const [expanded, setExpanded] = React.useState<string | false>(false);
    const [depSensor, setDepSensor] = React.useState<EnvSensorInfo | undefined>(undefined);
    const [arrSensor, setArrSensor] = React.useState<EnvSensorInfo | undefined>(undefined);


    //ポート情報を取得
    const fetchSensorData = async () => {
        let c: APIConnector = APIConnector.instance;

        if (props.depPort.envSensorDeviceID !== undefined && props.depPort.envSensorDeviceID !== "") {
            //到着地のセンサー情報
            try {
                setDepSensor(await c.getEnvSensorData(props.depPort.envSensorDeviceID));
            } catch (e) {
                if (axios.default.isAxiosError(e)) {
                    let error = e as axios.AxiosError;
                    if (error.response!.status !== 400) {
                        console.error(e);
                    }
                }
            }
        }

        if (props.arrPort.envSensorDeviceID !== undefined && props.arrPort.envSensorDeviceID !== "") {
            //出発地のセンサー情報
            try {
                setArrSensor(await c.getEnvSensorData(props.arrPort.envSensorDeviceID));
            } catch (e) {
                if (axios.default.isAxiosError(e)) {
                    let error = e as axios.AxiosError;
                    if (error.response!.status !== 400) {
                        console.error(e);
                    }
                }
            }
        }
    }

    useEffect(() => {
        fetchSensorData();
    }, []);


    //10秒に一度データ更新
    useInterval(() => {
        fetchSensorData();
    }, 5000);

    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };


    if (depSensor === undefined && arrSensor === undefined) {
        return (null);
    }



    const getWindColor = (windspeed: number | undefined): string => {
        if (windspeed === undefined) {
            return classes.alertWind;
        }
        if (windspeed < 5) {
            return classes.normalWind;
        } else if (windspeed >= 5 && windspeed < 8) {
            return classes.strongWind;
        } else {
            return classes.alertWind;
        }

    }

    const depWindColor = getWindColor(depSensor?.wind_vel);
    const depMaxWindColor = getWindColor(depSensor?.wind_maxinst_vel);
    const arrWindColor = getWindColor(arrSensor?.wind_vel);
    const arrMaxWindColor = getWindColor(arrSensor?.wind_maxinst_vel);

    return (
        <div className={classes.root}>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={true}
                autoHideDuration={6000}
                style={{ padding: "2px", margin: "2px", zIndex: 100 }}
            >
                <div>
                    {/** 出発地のセンサーデータが取れない場合は出発地のデータは非表示 */
                        (depSensor === undefined) ? null : (
                            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} style={{ backgroundColor: blue[500] }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <Typography style={{ color: "#FFF" }}>
                                        {props.depPortName}：{Display.getTimeStringFromMINEBEA(depSensor?.timestamp)} <NavigationIcon style={{ verticalAlign: "bottom", transform: "rotate(" + depSensor?.wind_dir + "deg)" }} />  &nbsp;&nbsp;{depSensor?.wind_dir}deg <div className={depWindColor}>{depSensor?.wind_vel}m/s</div>{depSensor?.temp}℃ {depSensor?.pres}hPa <br />
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails style={{ backgroundColor: blue[500] }}>
                                    <Typography style={{ color: "#FFF" }}>
                                        <Grid container spacing={1} alignItems="center" justifyContent="flex-start">
                                            <Grid item lg={3} sm={4}>
                                                最大瞬間風速
                                            </Grid>
                                            <Grid item lg={9} sm={8}>
                                                <NavigationIcon style={{ verticalAlign: "top", transform: "rotate(" + depSensor?.wind_maxinst_dir + "deg)" }} /> {depSensor?.wind_maxinst_dir}deg <div className={depMaxWindColor}>{depSensor?.wind_maxinst_vel}m/s  </div>
                                            </Grid>
                                            <Grid item lg={3} sm={4}>
                                                湿度
                                            </Grid>
                                            <Grid item lg={9} sm={8}>
                                                {depSensor?.hum}%
                                            </Grid>
                                            <Grid item lg={3} sm={4}>
                                                レインレベル
                                            </Grid>
                                            <Grid item lg={9} sm={8}>
                                                {depSensor?.rain}
                                            </Grid>
                                        </Grid>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        )}


                    {/** 到着地のセンサーデータが取れない場合は到着地のデータは非表示 */
                        (arrSensor === undefined) ? null : (
                            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} style={{ backgroundColor: blue[500] }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2bh-content"
                                    id="panel2bh-header"
                                >
                                    <Typography style={{ color: "#FFF" }}>
                                        {props.arrPortName}：{Display.getTimeStringFromMINEBEA(arrSensor?.timestamp)} <NavigationIcon style={{ verticalAlign: "bottom", transform: "rotate(" + arrSensor?.wind_dir + "deg)" }} />  &nbsp;&nbsp;{arrSensor?.wind_dir}deg <div className={arrWindColor}>{arrSensor?.wind_vel}m/s</div>{arrSensor?.temp}℃ {arrSensor?.pres}hPa <br />
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails style={{ backgroundColor: blue[500] }}>
                                    <Typography style={{ color: "#FFF" }}>
                                        <Grid container spacing={1} alignItems="center" justifyContent="flex-start">
                                            <Grid item lg={3} sm={4}>
                                                最大瞬間風速
                                            </Grid>
                                            <Grid item lg={9} sm={8}>
                                                <NavigationIcon style={{ verticalAlign: "top", transform: "rotate(" + arrSensor?.wind_maxinst_dir + "deg)" }} /> {arrSensor?.wind_maxinst_dir}deg <div className={arrMaxWindColor}>{arrSensor?.wind_maxinst_vel}m/s  </div>
                                            </Grid>
                                            <Grid item lg={3} sm={4}>
                                                湿度
                                            </Grid>
                                            <Grid item lg={9} sm={8}>
                                                {arrSensor?.hum}%
                                            </Grid>
                                            <Grid item lg={3} sm={4}>
                                                レインレベル
                                            </Grid>
                                            <Grid item lg={9} sm={8}>
                                                {arrSensor?.rain}
                                            </Grid>
                                        </Grid>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        )}
                </div>
            </Snackbar>
        </div>
    );
}