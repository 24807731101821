import React from 'react';
import { Button, ButtonGroup } from '@mui/material';
import { Display } from 'adoms-common-lib';

type SelectDayButtonProps = {
  wasLoadedData: boolean,
  date: string,
  setDate: React.Dispatch<React.SetStateAction<string>>,
  color?: string
}

/**
 * 配送依頼日選択ボタン表示用コンポーネント
 * @param props:参照用プロパティ
 */
export const SelectDayButton: React.FC<SelectDayButtonProps> = (props) => {
  let selectDayButton
  if (props.color) {
    selectDayButton = <ButtonGroup disabled={!props.wasLoadedData} variant="contained" aria-label="outlined secondary button group">
      <Button style={{ backgroundColor: props.color, color: "#FFFFFF" }} onClick={() => props.setDate(Display.getPreviousDayWithString(props.date))}>◀前日</Button>
      <Button style={{ backgroundColor: props.color, color: "#FFFFFF" }} onClick={() => props.setDate(Display.getNowDateWithString())}>本日</Button>
      <Button style={{ backgroundColor: props.color, color: "#FFFFFF" }} onClick={() => props.setDate(Display.getNextDateWithString(props.date))}>翌日▶</Button>
    </ButtonGroup>
  } else {
    selectDayButton = <ButtonGroup disabled={!props.wasLoadedData} variant="contained" color="primary" aria-label="outlined secondary button group">
      <Button onClick={() => props.setDate(Display.getPreviousDayWithString(props.date))}>◀前日</Button>
      <Button onClick={() => props.setDate(Display.getNowDateWithString())}>本日</Button>
      <Button onClick={() => props.setDate(Display.getNextDateWithString(props.date))}>翌日▶</Button>
    </ButtonGroup>
  }
  return selectDayButton;
}