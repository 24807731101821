import React, { useEffect } from 'react';
import { Alert, AlertTitle, Paper, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { GoodsInfo, OrderInfo } from "adoms-common-lib"
import { NumericFormat, PatternFormat } from 'react-number-format';


const useStyles = makeStyles()((theme: any) => ({
    table: {
        minWidth: 100,
    },
    title: {
        flexGrow: 1,
        textAlign: 'center',
        backgroundColor: theme.palette.primary.main,
        color: 'white',
    },
    goodsWeight: {
        width: 80
    }
}));

/**
 * 合計重量
 * @param goodsList 配送物リスト
 */
function getTotal(goodsList: Array<GoodsInfo>): any {
    let weight = 0;
    let count = 0;
    for (let goods of goodsList) {
        weight += goods.weight * goods.count;
        count += goods.count;
    }
    return { weight: weight, count: count };
}

interface NumberFormatCustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}


const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
    props: NumberFormatCustomProps,
    ref,
    )  {
    const { onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            inputMode='decimal'
            valueIsNumericString
            allowNegative={false}
            suffix="g"
        />
    );
});

type OrderGoodsTableProps = {
    order: OrderInfo
    setOrder: React.Dispatch<React.SetStateAction<OrderInfo>>
    setEdit: React.Dispatch<React.SetStateAction<boolean>>
}

/**
 * オーダーの商品一覧テーブルを表示する
 * @param props order:OrderInfo
 */
const OrderGoodTable: React.FC<OrderGoodsTableProps> = (props) => {
    const { classes } = useStyles();
    const [inputGoodWeight, setInputGoodWeight] = React.useState<number>(props.order.goods[0]?.weight ?? 0);


    useEffect(() => {
        setInputGoodWeight(props.order.goods[0]?.weight);
    }, [props.order]);

    const handleChangeWeight = (event: any) => {
        const inputGoodWeightParam = Number(event.target.value);
        setInputGoodWeight(inputGoodWeightParam);
        if (props.order.goods[0]?.weight !== inputGoodWeightParam) {
            let orderParam = JSON.parse(JSON.stringify(props.order)) as OrderInfo;
            orderParam.goods[0].weight = inputGoodWeightParam;
            props.setOrder(orderParam);
            props.setEdit(true);
        };
    };

    return (
        <div>
            <TableContainer component={Paper} elevation={0}>
                <Table className={classes.table} aria-label="simple table">
                    <TableBody>
                        <TableRow>
                            <TableCell component="th">重量&nbsp;(g)</TableCell>
                            <TableCell scope="row">
                                <TextField
                                    className={classes.goodsWeight}
                                    onChange={(e: any) => handleChangeWeight(e)}
                                    name="weight"
                                    id="formatted-numberformat-input"
                                    value={inputGoodWeight}
                                    InputProps={{
                                        inputComponent: NumberFormatCustom as any,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    size="small"
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            {(props.order.remarks !== undefined && props.order.remarks !== "") ?
                (
                    <div style={{ textAlign: "left" }} >
                        <Alert style={{ marginTop: "5px", marginBottom: "12px" }} severity="info">
                            <AlertTitle><Typography variant="subtitle1">備考</Typography></AlertTitle>
                            {props.order.remarks}
                        </Alert>
                    </div>
                ) : null
            }
        </div >
    );


}
export default OrderGoodTable;