import { Button, Card, CardActions, CardContent, CardHeader, Grid, TextField, Typography } from "@mui/material";
import { makeStyles } from 'tss-react/mui';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import ScheduleIcon from '@mui/icons-material/Schedule';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import { DateValidator, FlightInfo, FlightStatus } from "adoms-common-lib";
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import FlightStatusInfo from '../atoms/FlightStatusInfo';

const useStyles = makeStyles()((theme: any) => ({
    cardHeader: {
        backgroundColor: theme.palette.primary.main
    },
    cardPricing: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'baseline',
        marginBottom: theme.spacing(2),
    },
    footer: {
        borderTop: `1px solid ${theme.palette.divider}`,
        marginTop: theme.spacing(8),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(6),
            paddingBottom: theme.spacing(6),
        },
    }
}));

type OrderCardProps = {
    flightInfo: FlightInfo
    isOpenChangeScheduleDialog: boolean,
    setChangeFlightScheduleDialogOpen: React.Dispatch<React.SetStateAction<boolean>>,
    setTargetChangeFlightSchedule: React.Dispatch<React.SetStateAction<FlightInfo | undefined>>,
    setHandleScheduleChangeCancel: React.Dispatch<React.SetStateAction<Function>>,
    setErrorDialogOpen: React.Dispatch<React.SetStateAction<boolean>>,
    canChangeStaStdEtaEtd: boolean
}

export const FlightCardForFlightListView: React.FC<OrderCardProps> = (props) => {
    const { classes } = useStyles();
    const navigate = useNavigate();

    const [isDisplayValidate, setDisplayValidate] = React.useState<Map<string, boolean>>(new Map<string, boolean>());
    const [inputStd, setInputStd] = React.useState<string>(props.flightInfo.std);
    const [inputSta, setInputSta] = React.useState<string>(props.flightInfo.sta);
    const [inputEtd, setInputEtd] = React.useState<string>(props.flightInfo.etd);
    const [inputEta, setInputEta] = React.useState<string>(props.flightInfo.eta);

    useEffect(() => {
        setInputStd(props.flightInfo.std)
        setInputSta(props.flightInfo.sta)
        setInputEtd(props.flightInfo.etd)
        setInputEta(props.flightInfo.eta)
    }, [props.flightInfo]);

    /**
     * 次のページに遷移する際の処理
     * @param event 
     */
    const handleGotoDetail = (event: any, flightInfo: FlightInfo) => {
        navigate("/flightdetailview", {
            state: { id: flightInfo.id },
        })
    }

    /**
 * テキストフィールド内の現在の入力値を取得するためのハンドラ
 * @param event フライトスケジュールを入力するテキストフィールドからのonChangeイベント
 * @param kindOfSchedule 入力したスケジュールの種類
 */
    //完全一致とdから
    const changeTextfieldValue = (event: any, kindOfSchedule: string) => {
        if (/^std$/.test(kindOfSchedule)) {
            setInputStd(event.target.value)
        } else if (/^sta$/.test(kindOfSchedule)) {
            setInputSta(event.target.value)
        } else if (/^etd$/.test(kindOfSchedule)) {
            setInputEtd(event.target.value)
        } else if (/^eta$/.test(kindOfSchedule)) {
            setInputEta(event.target.value)
        }
    }

    /**
     * スケジュールを入力するテキストフィールドからフォーカスが外れた際、
     * スケジュール変更の必要の有無を判断する。
     * また、スケジュール変更が要求されたと判断した場合、
     * スケジュール変更ダイアログを表示するハンドラ
     * @param event 
     */
    const handleFocusBlurFromTextfield = (event: React.FocusEvent<HTMLInputElement>) => {
        event.currentTarget.blur();
        /*
         * Tabキーで次のTextfieldに移るまでに、onBlurイベントとonFocusイベントの発火に時間差があることから、
         * Timeoutを設定して対応している
         */
        setTimeout(() => {
            console.log(document.activeElement);
            console.log(document.activeElement?.tagName);
            console.log(document.activeElement?.className);
            console.log(!/flightInfoDateCell/.test(document.activeElement!.className));
            /*
             * flightInfoDateを入力するテキストフィールドを除き、
             * テキストフィールド以外にフォーカスされていて、
             * すべてのスケジュールが正しく入力されていればダイアログを表示する
             */
            if (
                (document.activeElement?.tagName !== 'INPUT' ||
                    /flightInfoDateCell/.test(document.activeElement!.className)) &&
                checkTextfieldValidate() &&
                checkChangeSchedule()
            ) {
                handleScheduleChange();
            }
        }, 50);
    };

    /**
     * スケジュールを入力するテキストフィールド上でEnterキーが押された場合、
     * スケジュール変更ダイアログを表示するハンドラ
     * @param event 
     */
    const handleKeyboardEventInTextField = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            (document.activeElement as HTMLInputElement).blur();
            if (checkTextfieldValidate() && checkChangeSchedule()) {
                handleScheduleChange();
            }
        }
    };

    /**
     * スケジュール変更の際、
     * 出発時間のみが入力された場合は
     * 出発時刻の差分と同じ時刻だけ差分を到着時間にも適用した上で、
     * フライト一覧ページでスケジュール変更ダイアログを表示するハンドラ 
     */
    const handleScheduleChange = () => {
        let flightInfoId = props.flightInfo.id;
        console.log('flightInfoId: ' + flightInfoId);

        let targetFlight = JSON.parse(JSON.stringify(props.flightInfo)) as FlightInfo;

        //出発時刻のみ入力したことの確認
        let currentDepartureDifferentTime = 0;
        if (props.flightInfo.std != inputStd) currentDepartureDifferentTime++;
        if (props.flightInfo.etd != inputEtd) currentDepartureDifferentTime++;
        let currentArriveDifferentTime = 0;
        if (props.flightInfo.sta != inputSta) currentArriveDifferentTime++;
        if (props.flightInfo.eta != inputEta) currentArriveDifferentTime++;

        //出発時刻のみ入力している場合は、出発時刻の差分と同じ時刻だけ差分を到着時間にも適用する
        if (currentDepartureDifferentTime !== 0 && currentArriveDifferentTime === 0) {
            let oldDirectChangeDate = new Date();
            let newDirectChangeDate = new Date();
            let newPartnerDate = new Date();
            //iPhoneでは日付にハイフンを使えないためスラッシュに置き換える
            let calculateFlightDate = props.flightInfo.flightDate.replace(/-/g, "/")

            /*
             * stdとetdを同時変更する場合に、
             * 変更時分が異なる可能性が想定されるため、
             * 共通化していない
             */
            if (props.flightInfo.std != inputStd) {
                oldDirectChangeDate = new Date(calculateFlightDate + ' ' + props.flightInfo.std);
                newDirectChangeDate = new Date(calculateFlightDate + ' ' + inputStd);
                newPartnerDate = new Date(calculateFlightDate + ' ' + props.flightInfo.sta);
                targetFlight.std = inputStd;

                let diff = (newDirectChangeDate.getTime() - oldDirectChangeDate.getTime()) / (60 * 1000);
                newPartnerDate.setMinutes(newPartnerDate.getMinutes() + diff);
                let newPartnerTimeString = String(String('0' + newPartnerDate.getHours()).slice(-2) + ':' + String('0' + newPartnerDate.getMinutes()).slice(-2));

                targetFlight.sta = newPartnerTimeString;
            }
            if (props.flightInfo.etd != inputEtd) {
                oldDirectChangeDate = new Date(calculateFlightDate + ' ' + props.flightInfo.etd);
                newDirectChangeDate = new Date(calculateFlightDate + ' ' + inputEtd);
                newPartnerDate = new Date(calculateFlightDate + ' ' + props.flightInfo.eta);

                targetFlight.etd = inputEtd;

                let diff = (newDirectChangeDate.getTime() - oldDirectChangeDate.getTime()) / (60 * 1000);
                newPartnerDate.setMinutes(newPartnerDate.getMinutes() + diff);
                let newPartnerTimeString = String(String('0' + newPartnerDate.getHours()).slice(-2) + ':' + String('0' + newPartnerDate.getMinutes()).slice(-2));

                targetFlight.eta = newPartnerTimeString;
            }
            //出発時刻以外も手動で入力している場合、手入力を前提とする
        } else {
            targetFlight.etd = inputEtd;
            targetFlight.eta = inputEta;
            targetFlight.std = inputStd;
            targetFlight.sta = inputSta;
        }
        /*
         ダイアログに受け渡すフライト情報、キャンセルされた時に戻す値を反映し、
         フライト一覧ページに配置されたスケジュール変更確認ダイアログを表示する
         */
        props.setTargetChangeFlightSchedule(targetFlight);
        props.setHandleScheduleChangeCancel(handleScheduleChangeCancel);
        props.setChangeFlightScheduleDialogOpen(true);

        /*
         Enterキーが押されてスケジュール変更しようとした場合、
         フォーカスが外れていない可能性が想定されるため、
         フォーカスを外す
        */
        (document.activeElement as HTMLInputElement).blur();
    };

    /**
     * スケジュール変更がキャンセルされた時、
     * 入力値を元に戻すためのハンドラ
     */
    const handleScheduleChangeCancel = () => {
        setInputStd(props.flightInfo.std);
        setInputSta(props.flightInfo.sta);
        setInputEtd(props.flightInfo.etd);
        setInputEta(props.flightInfo.eta);
    }

    /**
     * スケジュール変更ダイアログが表示される前に、
     * すべてのスケジュールが正しく入力されていることを
     * 確認するハンドラ
     * @returns すべてのスケジュールが正しく入力されているかどうか
     */
    const checkTextfieldValidate = (): boolean => {
        let makingDisplayValidate = new Map<string, boolean>();
        makingDisplayValidate.set('std', !DateValidator.checkXTDXTA(inputStd).result);
        makingDisplayValidate.set('sta', !DateValidator.checkXTDXTA(inputSta).result);
        makingDisplayValidate.set('etd', !DateValidator.checkXTDXTA(inputEtd).result);
        makingDisplayValidate.set('eta', !DateValidator.checkXTDXTA(inputEta).result);
        console.log(makingDisplayValidate);

        setDisplayValidate(makingDisplayValidate);

        console.log(Array.from(makingDisplayValidate.values()).every((value): boolean => { return value === false }));
        return Array.from(makingDisplayValidate.values()).every((value): boolean => { return value === false });
    }

    /**
     * スケジュール変更ダイアログが表示される前に、
     * スケジュール変更の有無を確認するハンドラ
     * @returns スケジュールになんらかの変更があるかどうか
     */
    const checkChangeSchedule = (): boolean => {
        console.log('check change schedule');
        if (
            inputStd != props.flightInfo.std ||
            inputSta != props.flightInfo.sta ||
            inputEtd != props.flightInfo.etd ||
            inputEta != props.flightInfo.eta
        ) {
            return true;
        } else {
            return false;
        }
    }

    const returnTimeGrid = () => {
        if (props.flightInfo.status === FlightStatus.Reset ||
            props.flightInfo.status === FlightStatus.Canceled ||
            props.flightInfo.status === FlightStatus.Irregular ||
            props.flightInfo.status === FlightStatus.Completed ||
            !props.canChangeStaStdEtaEtd) {
            // フライトが完了している場合
            return (
                <React.Fragment>
                    <Grid container direction="row">
                        <Grid item>
                            <ScheduleIcon />
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle1" color="textPrimary" component="div" style={{ marginLeft: "3px" }}>
                                STD－STA
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" style={{ marginBottom: "5px" }}>
                        <Typography variant="subtitle1" color="textPrimary" component="div" style={{ marginLeft: "27px", display: "inline-block" }}>
                            {props.flightInfo.std + "－" + props.flightInfo.sta}
                        </Typography>
                    </Grid>
                    <Grid container direction="row">
                        <Grid item>
                            <WatchLaterIcon />
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle1" color="textPrimary" component="div" style={{ marginLeft: "3px" }}>
                                ETD-ETA
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" style={{ marginBottom: "5px" }}>
                        <Typography variant="subtitle1" color="textPrimary" component="div" style={{ marginLeft: "27px", display: "inline-block" }}>
                            {props.flightInfo.etd + "－" + props.flightInfo.eta}
                        </Typography>
                    </Grid>
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <Grid container direction="row">
                        <Grid item>
                            <ScheduleIcon />
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle1" color="textPrimary" component="div" style={{ marginLeft: "3px" }}>
                                STD－STA
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" style={{ marginBottom: "5px" }}>
                        <TextField
                            variant="standard"
                            error={isDisplayValidate.get('std')}
                            helperText={isDisplayValidate.get('std') ? '時間を入力してください' : ''}
                            type="time"
                            value={inputStd}
                            onChange={event => { changeTextfieldValue(event, 'std') }}
                            inputProps={
                                {
                                    style: { fontSize: 16 },
                                    className: 'stdCell'
                                }
                            }
                            onKeyDown={handleKeyboardEventInTextField}
                            onBlur={handleFocusBlurFromTextfield}
                            style={{ marginLeft: "27px" }} />
                        <Typography variant="subtitle1" color="textPrimary" component="div" style={{ marginTop: "5px", display: "inline-block" }}>－</Typography>
                        <TextField
                            variant="standard"
                            error={isDisplayValidate.get('sta')}
                            helperText={isDisplayValidate.get('sta') ? '時間を入力してください' : ''}
                            type="time"
                            value={inputSta}
                            onChange={event => { changeTextfieldValue(event, 'sta') }}
                            inputProps={
                                {
                                    style: { fontSize: 16 },
                                    className: 'staCell'
                                }
                            }
                            onKeyDown={handleKeyboardEventInTextField}
                            onBlur={handleFocusBlurFromTextfield} />
                    </Grid>
                    <Grid container direction="row">
                        <Grid item>
                            <WatchLaterIcon />
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle1" color="textPrimary" component="div" style={{ marginLeft: "3px" }}>
                                ETD-ETA
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" style={{ marginBottom: "5px" }}>
                        <TextField
                            variant="standard"
                            error={isDisplayValidate.get('etd')}
                            helperText={isDisplayValidate.get('etd') ? '時間を入力してください' : ''}
                            type="time"
                            value={inputEtd}
                            onChange={event => { changeTextfieldValue(event, 'etd') }}
                            inputProps={
                                {
                                    style: { fontSize: 16 },
                                    className: 'etdCell'
                                }
                            }
                            onKeyDown={handleKeyboardEventInTextField}
                            onBlur={handleFocusBlurFromTextfield}
                            style={{ marginLeft: "27px" }} />
                        <Typography variant="subtitle1" color="textPrimary" component="div" style={{ marginTop: "5px", display: "inline-block" }}>－</Typography>
                        <TextField
                            variant="standard"
                            error={isDisplayValidate.get('eta')}
                            helperText={isDisplayValidate.get('eta') ? '時間を入力してください' : ''}
                            type="time"
                            value={inputEta}
                            onChange={event => { changeTextfieldValue(event, 'eta') }}
                            inputProps={
                                {
                                    style: { fontSize: 16 },
                                    className: 'etaCell'
                                }
                            }
                            onKeyDown={handleKeyboardEventInTextField}
                            onBlur={handleFocusBlurFromTextfield} />
                    </Grid>
                </React.Fragment>
            );
        }
    }

    return (
        <Grid item key={props.flightInfo.id} lg={12} md={12} sm={12} xs={12}>
            <Card>
                <CardHeader
                    title={<FlightStatusInfo status={props.flightInfo.status} />}
                    titleTypographyProps={{ align: 'center' }}
                    subheaderTypographyProps={{ align: 'center' }}
                    className={classes.cardHeader}
                />
                <CardContent>
                    <Typography variant="subtitle2" color="textSecondary" component="div" style={{ marginBottom: "5px" }}>
                        {props.flightInfo.businessPartnerId + " " + props.flightInfo.id}
                    </Typography>
                    {returnTimeGrid()}
                    <Grid container direction="row">
                        <Grid item>
                            <FlightTakeoffIcon />
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle1" color="textPrimary" component="div" style={{ marginLeft: "3px" }}>
                                FROM
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row">
                        <Grid item>
                            <Typography variant="subtitle1" color="textPrimary" component="div" style={{ marginLeft: "27px" }}>
                                {props.flightInfo.departure.name}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row">
                        <Grid item>
                            <FlightLandIcon />
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle1" color="textPrimary" component="div" style={{ marginLeft: "3px" }}>
                                TO
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row">
                        <Grid item>
                            <Typography variant="subtitle1" color="textPrimary" component="div" style={{ marginLeft: "27px" }}>
                                {props.flightInfo.arrival.name}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row">
                        <Grid item>
                            <RecordVoiceOverIcon />
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle1" color="textPrimary" component="div" style={{ marginLeft: "3px" }}>
                                フライトディレクター
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row">
                        <Grid item>
                            <Typography variant="subtitle1" color="textPrimary" component="div" style={{ marginLeft: "27px" }}>
                                {props.flightInfo.fd.name}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions>
                    <Button
                        style={{ marginLeft: 'auto' }}
                        color="primary"
                        onClick={(e: any) => { handleGotoDetail(e, props.flightInfo) }}>
                        詳細
                    </Button>
                </CardActions>
            </Card>
        </Grid>
    )
}