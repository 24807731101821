import { Box, Grid, Paper, Typography } from '@mui/material';
import React from 'react';

type WeatherSampleColorPaperProps = {
    sampleColorList: {
        color: string;
        value: number;
    }[],
    weatherIcon: JSX.Element,
    unit: string
}

export const WeatherSampleColorPaper: React.FC<WeatherSampleColorPaperProps> = (props) => {
    return (
        <Paper sx={{ padding: 0.5, backgroundColor: "rgba(246,246,246,0.7)", }}>
            <Grid container direction={"column"}>
                <Grid item textAlign={"center"}>
                    {props.weatherIcon}
                </Grid>
                <Grid container direction={"row"} spacing={0.5}>
                    <Grid item container direction={"column"} xs>
                        {props.sampleColorList.map((sampleColor, i) => (
                            <Grid item key={i}>
                                <Box width={21} height={21} sx={{ backgroundColor: sampleColor.color }} />
                            </Grid>
                        ))}
                    </Grid>
                    <Grid item container direction={"column"} xs>
                        {props.sampleColorList.map((sampleColor) => (
                            <Grid item>
                                <Typography variant='caption'
                                    fontWeight={"bold"}>{sampleColor.value}</Typography>
                            </Grid>
                        ))}
                        <Typography variant='caption'
                            fontWeight={"bold"}>{props.unit}</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    )
}