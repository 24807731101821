import React from "react";
import { Alert, Collapse, IconButton, TableCell, TableRow, Box, Link, Table, TableBody, TableHead } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';
import { Port, Spot } from 'adoms-common-lib';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LocationIcon from '@mui/icons-material/LocationOn';
import Zoom from '@mui/material/Zoom/Zoom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Fab from '@mui/material/Fab/Fab';

const useStyles = makeStyles()((theme: Theme) =>
({
    fab: {
        margin: theme.spacing(1)
    }
}));

type portDetailTableProps = {
    port: Port;
    displayPortList: Port[];
    handlePortUpdateDialogOpen: (port: Port) => void;
    handlePortDeleteDialogOpen: (port: Port) => void;
    canEditPortUser: boolean;
}

type destinationPortTableProps = {
    isLastIndex: boolean;
    portMap: Map<string, Port>;
    partnerId: string;
    destPortId: string;
    mapUrl: string;
}

/**
 * ポート詳細情報テーブル表示用コンポーネント
 * @param props:参照用プロパティ
 */
export const PortDetailTable: React.FC<portDetailTableProps> = (props) => {

    let portDetailTable;
    const { classes } = useStyles();
    const mapUrl = "https://www.google.com/maps/search/?api=1&query=";
    const [isCellOpen, setCellOpen] = React.useState(false);

    let portMap = new Map<string, Port>();
    for (const port of props.displayPortList) {
        portMap.set(port.businessPartnerId + port.id, port);
    }

    portDetailTable =
        <React.Fragment>
            <TableRow>
                <TableCell component="th" scope="row" align="left">
                    {props.port.businessPartnerId}
                </TableCell>
                <TableCell component="th" scope="row" align="left">
                    {props.port.name}
                </TableCell>
                <TableCell component="th" scope="row" align="left">
                    {"〒" + props.port.zipcode + " " + props.port.prefecture + props.port.city + props.port.address}
                    <Link
                        rel="noopener noreferrer"
                        href={mapUrl + props.port.latitude + "," + props.port.longitude}
                        target="_blank">
                        <IconButton size="large">
                            <LocationIcon color="primary" />
                        </IconButton>
                    </Link>
                </TableCell>
                <TableCell component="th" scope="row" align="left">
                    {props.port.latitude}
                </TableCell>
                <TableCell component="th" scope="row" align="left">
                    {props.port.longitude}
                </TableCell>
                <TableCell component="th" scope="row" align="left">
                    {props.port.envSensorDeviceID ? props.port.envSensorDeviceID : "未設定"}
                </TableCell>
                <TableCell align="right">
                    <IconButton aria-label="expand row" size="small" onClick={() => setCellOpen(!isCellOpen)}>
                        {isCellOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: "#f0f8ff" }} colSpan={7}>
                    <Collapse in={isCellOpen} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Table size="small" aria-label="purchases">
                                <TableBody>
                                    <TableRow>
                                        <TableCell component= "th" scope="row">
                                            <b>ポートID</b>
                                        </TableCell>
                                        <TableCell scope="row">
                                            {props.port.id}
                                        </TableCell>
                                        {props.canEditPortUser ?
                                            <TableCell align="right" style={{ border: "0px none", width: "60%" }}>
                                                <Zoom in={true} style={{ transitionDelay: '500ms' }} >
                                                    <Fab color="primary"
                                                        aria-label="Edit"
                                                        size="small"
                                                        className={classes.fab}
                                                        onClick={() => { props.handlePortUpdateDialogOpen(props.port) }}>
                                                        <EditIcon />
                                                    </Fab>
                                                </Zoom>
                                                <Zoom in={true} style={{ transitionDelay: '500ms' }} >
                                                    <Fab color="primary"
                                                        aria-label="Delete"
                                                        size="small"
                                                        className={classes.fab}
                                                        onClick={() => { props.handlePortDeleteDialogOpen(props.port) }}>
                                                        <DeleteIcon />
                                                    </Fab>
                                                </Zoom>
                                            </TableCell>
                                            :
                                            <TableCell align="right" style={{ border: "0px none", width: "60%" }} />
                                        }
                                    </TableRow>
                                    <TableRow style={{height: "75px"}}>
                                        <TableCell component= "th" scope="row">
                                            <b>離着陸区分</b>
                                        </TableCell>
                                        <TableCell scope="row">
                                            {props.port.portType === "sender" && "送り側"}
                                            {props.port.portType === "receiver" && "受け取り側"}
                                            {props.port.portType === "both" && "送り側 / 受け取り側"}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row" style={{ width: 130 }}><b>到着可能<br />ポート</b></TableCell>
                                        {props.port.destinationPortList.length === 0 ? (
                                            <TableCell align="left">
                                                <Alert severity="info">到着可能ポートがありません</Alert>
                                            </TableCell>
                                        ) : (
                                            <TableCell align="left">
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell style={{ width: 170 }} size="small"><b>ポート名</b></TableCell>
                                                            <TableCell style={{ width: 150 }} size="small"><b>ポートID</b></TableCell>
                                                            <TableCell style={{ width: 150 }} size="small"><b>地図</b></TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {props.port.destinationPortList.map((destPortId: string, index: number) => (
                                                            <DestinationPortTable
                                                                key={index}
                                                                isLastIndex={props.port.destinationPortList.length - 1 === index}
                                                                portMap={portMap}
                                                                partnerId={props.port.businessPartnerId}
                                                                destPortId={destPortId}
                                                                mapUrl={mapUrl} />
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableCell>
                                        )}
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row" style={{ width: 130, borderBottom: "0px none", paddingTop: 15 }}><b>備考</b></TableCell>
                                        <TableCell align="left" style={{ borderBottom: "0px none" }}>
                                            {props.port.remarks}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>

    return portDetailTable;
}

/**
 * 到着可能ポートテーブル表示用コンポーネント
 * @param props:参照用プロパティ
 */
export const DestinationPortTable: React.FC<destinationPortTableProps> = (props) => {
    let destinationPortTable;

    const portMap = props.portMap.get(props.partnerId + props.destPortId);

    destinationPortTable =
        <TableRow>
            <TableCell align="left" size="small" style={props.isLastIndex ? { borderBottom: "0px none" } : {}}>
                {portMap != null ? portMap.name : ""}
            </TableCell>
            <TableCell align="left" size="small" style={props.isLastIndex ? { borderBottom: "0px none" } : {}}>
                {props.destPortId}
            </TableCell>
            <TableCell align="left" size="small" style={props.isLastIndex ? { borderBottom: "0px none" } : {}}>
                <Link
                    rel="noopener noreferrer"
                    href={portMap != null ? props.mapUrl + portMap.latitude + "," + portMap.longitude : ""}
                    target="_blank">
                    <IconButton size="large">
                        <LocationIcon color="primary" fontSize="small" />
                    </IconButton>
                </Link>
            </TableCell>
        </TableRow>
    return destinationPortTable;
}
