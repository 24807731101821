import { MapView } from '@aws-amplify/ui-react';
import { load } from "@loaders.gl/core";
import { KMLLoader } from "@loaders.gl/kml";
import AirIcon from '@mui/icons-material/Air';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import FlagIcon from '@mui/icons-material/Flag';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TuneIcon from '@mui/icons-material/Tune';
import { Alert, Box, CircularProgress, Grid, IconButton, Paper, SvgIcon, Typography, lighten } from '@mui/material';
import { FlightInfo, GeoInformation, MapStyle, Port, WeatherGeoJson } from "adoms-common-lib";
import { Auth, Geo } from 'aws-amplify';
import axios from 'axios';
import dayjs from 'dayjs';
import type { FeatureCollection } from 'geojson';
import * as geolib from "geolib";
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import type { MapLayerMouseEvent, MapRef } from 'react-map-gl';
import { Layer, Marker, Source } from 'react-map-gl';
import navigation from "../../assets/icons/navigation.svg";
import { ReactComponent as DroneIcon } from "../../assets/icons/quadcopter.svg";
import { ReactComponent as WeatherPouringIcon } from "../../assets/icons/weatherPouring.svg";
import { AmplifyGeoConfig, AmplifyGeoOptions, GeoInformationMap, MinMaxLanLng, getLocationIconColorBrightness } from '../../common/DroneGeoInformationUtil';
import { changeMinutes10MinuteIncrements, isWeatherAPIAccessible, lineLayerStyle, rainFillLayerStyle, rainSampleColorList, windSpeedSampleColorList, windSymbolLayerStyle } from '../../common/weatherUtil';
import { APIConnector } from '../../connector/APIConnector';
import { DroneGeoInformationPopover } from '../molecule/DroneGeoInformationPopover';
import { DronePopup } from '../molecule/DronePopUp';
import { PortPopup } from '../molecule/PortPopup';
import { WeatherAltitudeSlider } from '../molecule/WeatherAltitudeSlider';
import { WeatherSampleColorPaper } from '../molecule/WeatherSampleColorPaper';
import { WeatherTimeSlider } from '../molecule/WeatherTimeSlider';

type DroneGeoInformationGridProps = {
    selectedFlightInfo: FlightInfo | undefined
    allPortList: Port[]
    portErrorMessage: string | undefined
    setNextUpdateWeatherInfoDayJs: React.Dispatch<React.SetStateAction<dayjs.Dayjs | undefined>>
    isUpdateWeatherInfo: boolean
    setUpdateWeatherInfo: React.Dispatch<React.SetStateAction<boolean>>
};

export const DroneGeoInformationGrid: React.FC<DroneGeoInformationGridProps> = (props) => {

    Auth.configure();
    const mapRef = useRef<MapRef>(null);
    const styleProps = useMemo(
        () => ({
            height: "100%",
            position: 'relative',
            width: '100%',
            borderRadius: "5px"
        }),
        []
    );
    const amplifyConfig = Geo.configure() as AmplifyGeoConfig;
    const geoConfig = useMemo(
        () =>
            amplifyConfig.geo?.amazon_location_service ??
            amplifyConfig.geo?.AmazonLocationService ??
            ({} as AmplifyGeoOptions),
        [amplifyConfig]
    );
    const weatherMenuPopoverAnchor = useRef(null);
    const [mapErrorMessage, setMapErrorMessage] = React.useState<string | undefined>();
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
    const [weatherErrorMessage, setWeatherErrorMessage] = React.useState<string | undefined>(undefined);
    const [geoConfigMapStyle, setGeoConfigMapStyle] = React.useState<string | undefined>(geoConfig.maps?.neutral);
    const [minMaxLanLng, setMinMaxLanLng] = React.useState<MinMaxLanLng | undefined>(undefined);
    const [geoInformationMap, setGeoInformationMap] = React.useState<GeoInformationMap | undefined>();
    const [geoInformationListPerFlightId, setGeoInformationListPerFlightId] = React.useState<GeoInformation[]>([]);
    const [mapStyle, setMapStyle] = React.useState<MapStyle>(MapStyle.neutral);
    const [dronePopupInfo, setDronePopupInfo] = React.useState<GeoInformation | undefined>();
    const [portPopupInfo, setPortPopupInfo] = React.useState<Port | undefined>();
    const [displayFlightId, setDisplayFlightId] = React.useState<string | undefined>();
    const [routeData, setRouteData] = React.useState<FeatureCollection | undefined>();
    const [weatherGeoJson, setWeatherGeoJson] = React.useState<WeatherGeoJson | undefined>();
    const [displayWeatherInfoDayJs, setDisplayWeatherInfoDayJs] = React.useState<dayjs.Dayjs>();
    const [hoverInfo, setHoverInfo] = React.useState<{
        lngLat: mapboxgl.LngLat,
        rain: string;
        ugrd: string;
        vgrd: string;
        windDirection: string;
        windSpeed: string;
    }>();
    const [isDisplayWeatherLoading, setDisplayWeatherLoading] = React.useState(false);
    const [isDisplayRainInfo, setDisplayRainInfo] = React.useState(true);
    const [isDisplayWindInfo, setDisplayWindInfo] = React.useState(true);
    const [openedWeatherMenuPopover, setOpenedWeatherMenuPopover] = React.useState(false);
    const [openedWeatherSlider, setOpenedWeatherSlider] = React.useState(false);
    const [displayAltitude, setDisplayAltitude] = React.useState(60);
    const [weatherLngLatBounds, setWeatherLngLatBounds] = React.useState<mapboxgl.LngLatBounds | undefined>();

    /**
     * 画面表示時に地図の中心となる点を算出する
     * また、指定されたドローンの位置情報と飛行中の1分間のドローンの位置情報を取得する
     */
    useEffect(() => {
        if (props.selectedFlightInfo) {
            displayMap();
            fetchGeoInformation();
        } else {
            setMinMaxLanLng(undefined);
            setDisplayFlightId(undefined);
        };
    }, [props.selectedFlightInfo]);

    useEffect(() => {
        const dataload = async () => {
            if (props.selectedFlightInfo) {
                // 表示させるルートを取得する
                const kmlFilePath = "./kml/" + props.selectedFlightInfo?.businessPartnerId
                    + "_" + props.selectedFlightInfo?.departure.id
                    + "_" + props.selectedFlightInfo?.arrival.id + ".kml";
                //KMLデータを読み込む
                const res = await load(kmlFilePath, KMLLoader);
                //KMLローダーで読み込んだデータはGeoJSON形式に変換されている
                console.log(res);
                //読み込んだデータを保存
                setRouteData(res);
            };
        };
        dataload();
    }, [props.selectedFlightInfo]);

    useEffect(() => {
        if (props.isUpdateWeatherInfo) {
            getWeatherGeoJson();
        };
        /**
         * mapRef.currentが取得できた後に、気象情報が取得できるため、
         * 第2引数にmapRef.currentを設定している
         */
    }, [props.isUpdateWeatherInfo, mapRef.current]);

    const getWeatherGeoJson = async () => {
        if (isWeatherAPIAccessible()) {
            if (mapRef.current?.getZoom() && mapRef.current?.getZoom() >= 11) {
                // ズームレベルが11以上の場合、気象情報を取得する
                let weatherLngLatBoundsParam = mapRef.current?.getBounds();
                setWeatherLngLatBounds(weatherLngLatBoundsParam);
                if (weatherLngLatBoundsParam) {
                    props.setUpdateWeatherInfo(false);
                    let displayWeatherInfoDayJsParam: dayjs.Dayjs;
                    if (displayWeatherInfoDayJs && displayWeatherInfoDayJs.isAfter(dayjs())) {
                        // 時刻が指定されている、かつ指定された時刻が現在より後の場合
                        displayWeatherInfoDayJsParam = displayWeatherInfoDayJs;
                    } else {
                        // 現在時刻を10分単位に繰り上げる
                        displayWeatherInfoDayJsParam = changeMinutes10MinuteIncrements(dayjs());
                    };

                    setDisplayWeatherInfoDayJs(displayWeatherInfoDayJsParam);
                    let apiConnector: APIConnector = APIConnector.instance;
                    setDisplayWeatherLoading(true);
                    await apiConnector.getWeatherGeoJson(
                        displayWeatherInfoDayJsParam.format("YYYY-MM-DD"),
                        displayWeatherInfoDayJsParam.format("HH:mm"),
                        weatherLngLatBoundsParam?.getNorth().toString(),
                        weatherLngLatBoundsParam?.getSouth().toString(),
                        weatherLngLatBoundsParam?.getWest().toString(),
                        weatherLngLatBoundsParam?.getEast().toString(),
                        displayAltitude.toString(),
                        undefined
                    ).then(weatherGeoJson => {
                        // 次に天候情報を更新する時刻を計算する
                        props.setNextUpdateWeatherInfoDayJs(displayWeatherInfoDayJsParam.add(10, "m"));
                        setWeatherErrorMessage(undefined);
                        console.log(weatherGeoJson);
                        setWeatherGeoJson(weatherGeoJson);
                        setHoverInfo(undefined);
                        setDisplayWeatherLoading(false);
                    }).catch(e => {
                        console.log(e);
                        setWeatherErrorMessage("気象情報が取得できませんでした");
                        setDisplayWeatherLoading(false);
                    });
                };
            } else {
                setWeatherGeoJson(undefined);
                setWeatherLngLatBounds(undefined);
            };
        } else {
            props.setUpdateWeatherInfo(false);
            props.setNextUpdateWeatherInfoDayJs(undefined);
            setWeatherErrorMessage("天候情報を取得できません。24分以降にアクセスしてください。");
        };
    };

    const isNewPolygonInsideOldPolygon = (): boolean => {
        const oldPolygon = weatherLngLatBounds;
        const newPolygon = mapRef.current?.getBounds();

        if (oldPolygon && newPolygon) {
            return oldPolygon.contains(newPolygon.getSouthEast())
                && oldPolygon.contains(newPolygon.getNorthEast())
                && oldPolygon.contains(newPolygon.getNorthWest())
                && oldPolygon.contains(newPolygon.getSouthWest());
        } else {
            return false;
        };
    };

    const displayMap = () => {
        if (props.selectedFlightInfo && displayFlightId !== props.selectedFlightInfo.id) {
            setDisplayFlightId(props.selectedFlightInfo.id);
            const minMaxLanLngParam = geolib.getBounds([
                {
                    longitude: Number(props.selectedFlightInfo.departure.longitude),
                    latitude: Number(props.selectedFlightInfo.departure.latitude)
                },
                {
                    longitude: Number(props.selectedFlightInfo.arrival.longitude),
                    latitude: Number(props.selectedFlightInfo.arrival.latitude)
                },
            ]);

            if (!minMaxLanLng) {
                if (!minMaxLanLngParam) {
                    // 出発地点と到着地点の中間地点の座標取得に失敗した場合
                    setErrorMessage("地図表示地点を取得できませんでした");
                } else {
                    setErrorMessage(undefined);
                    setMinMaxLanLng(minMaxLanLngParam);
                };
            } else {
                if (props.selectedFlightInfo.departure.id
                    === props.selectedFlightInfo.arrival.id) {
                    mapRef.current?.flyTo(
                        {
                            center:
                                [
                                    Number(props.selectedFlightInfo.departure.longitude),
                                    Number(props.selectedFlightInfo.departure.latitude)
                                ],
                            duration: 2000,
                            zoom: props.selectedFlightInfo.businessPartnerId === "AIRWINGS" ? 13 : 15
                        });
                } else {
                    mapRef.current?.fitBounds(
                        [
                            [minMaxLanLngParam.minLng, minMaxLanLngParam.minLat],
                            [minMaxLanLngParam.maxLng, minMaxLanLngParam.maxLat]
                        ],
                        { padding: 40, duration: 1000 }
                    );
                };
            };
        };
    };

    /**
     * ドローン位置情報を取得する
     */
    const fetchGeoInformation = async () => {

        try {
            if (displayFlightId !== props.selectedFlightInfo?.id) {
                setGeoInformationListPerFlightId([]);
            };
            setErrorMessage(undefined);
            let apiConnector: APIConnector = APIConnector.instance;

            // 飛行中の1分間のドローン位置情報を取得する
            const geoInformationMapParam = await apiConnector.getGeoInformation();
            console.log(geoInformationMapParam);
            setGeoInformationMap(geoInformationMapParam);

            // フライトIDを検索条件として位置情報を取得する
            if (props.selectedFlightInfo) {
                const geoInformationList = await apiConnector.getGeoInformationByFlightId(props.selectedFlightInfo.id);
                setGeoInformationListPerFlightId(geoInformationList);
            };
        } catch (error) {
            console.log(error);
            if (axios.isAxiosError(error)
                && typeof error.response !== "undefined"
                && error.response.status === 403) {
                setErrorMessage("権限がありません");
            } else {
                setErrorMessage("ドローン位置情報を取得できませんでした。");
            };
        };
    };

    /**
     * ドローンの位置情報を表示する
     */
    const displayDroneGeoInformation = () => {
        let displayDroneGeoInformationMarkerList = new Array();

        // 選択されたフライトの位置情報を表示する
        if (geoInformationListPerFlightId.length !== 0) {
            geoInformationListPerFlightId.forEach((geoInformation, index) => {
                // 青色のマーカーを表示する
                displayDroneGeoInformationMarkerList.push(
                    displayMarker(
                        index,
                        geoInformation,
                        "#3b1eef",
                        "#3b1eef",
                        false)
                );
            });
        };

        geoInformationMap?.forEach(({ last1MinuteGeoInformations, last8HoursGeoInformation }, droneId) => {

            //  飛行中、かつ直近1分間の間に取得できた位置情報を表示する
            last1MinuteGeoInformations?.forEach((geoInformation, index) => {
                if (geoInformation.flightId !== props.selectedFlightInfo?.id) {
                    // 選択されていないフライトの位置情報の場合、黒のマーカーを表示する
                    displayDroneGeoInformationMarkerList.push(
                        displayMarker(
                            index,
                            geoInformation,
                            mapStyle === MapStyle.satellite ? "#000000" : "#ffffff",
                            mapStyle === MapStyle.satellite ? "#ffffff" : "#000000",
                            true)
                    );
                };
            });

            // 飛行中、かつ直近1分間の位置情報が取得できない場合、直近8時間の間に取得できた最後の位置情報を表示する
            if (last8HoursGeoInformation) {
                if (last8HoursGeoInformation?.flightId !== props.selectedFlightInfo?.id) {
                    // 選択されていないフライトの位置情報の場合、灰色のマーカーを表示する
                    displayDroneGeoInformationMarkerList.push(
                        displayMarker(
                            0,
                            last8HoursGeoInformation,
                            "#797979",
                            "#797979",
                            true)
                    );
                };
            };
        });

        return displayDroneGeoInformationMarkerList;
    };

    const displayMarker = (
        index: number,
        geoInformation: GeoInformation,
        stroke: string,
        color: string,
        isSelectedFlightMarker: boolean) => {
        let marker
        if (index === 0) {
            marker = droneSvgIcon(stroke, color);
        } else {
            marker = fiberManualRecordIcon(index, color);
        };

        return (
            <Marker
                key={isSelectedFlightMarker
                    ? `${geoInformation.flightId}-${index}-selected` : `${geoInformation.flightId}-${index}`}
                longitude={geoInformation.longitude}
                latitude={geoInformation.latitude}
                onClick={e => {
                    e.originalEvent.stopPropagation();
                    setDronePopupInfo(geoInformation)
                }}
            >{marker}
            </Marker>
        );
    };

    const fiberManualRecordIcon = (index: number, color: string) => {
        const colorBrightness = getLocationIconColorBrightness(index);
        return (
            <FiberManualRecordIcon
                key={index}
                sx={{ color: lighten(color, colorBrightness), fontSize: 10 }} />
        );
    };

    const droneSvgIcon = (stroke: string, color?: string) => {
        return (
            <SvgIcon
                style={{
                    color: color,
                    stroke: stroke,
                    strokeWidth: "0.5px"
                }}>
                <DroneIcon />
            </SvgIcon>
        );
    };

    const onHover = useCallback((event: MapLayerMouseEvent) => {
        const geoJsonProperties = mapRef.current?.queryRenderedFeatures(event.point)[0].properties;

        if (geoJsonProperties) {
            const weatherHoverInfo = {
                lngLat: event.lngLat,
                rain: geoJsonProperties?.rain as string,
                ugrd: geoJsonProperties?.ugrd as string,
                vgrd: geoJsonProperties?.vgrd as string,
                windDirection: geoJsonProperties?.windDirection as string,
                windSpeed: geoJsonProperties?.windSpeed as string
            };
            setHoverInfo(weatherHoverInfo);
        } else {
            setHoverInfo(undefined);
        };
    }, []);

    return (props.selectedFlightInfo && minMaxLanLng && !mapErrorMessage ?
        <MapView
            ref={mapRef}
            initialViewState={
                props.selectedFlightInfo.departure.id
                    === props.selectedFlightInfo.arrival.id ?
                    {
                        latitude: Number(props.selectedFlightInfo.departure.latitude),
                        longitude: Number(props.selectedFlightInfo.departure.longitude),
                        zoom: props.selectedFlightInfo.businessPartnerId === "AIRWINGS" ? 13 : 15
                    }
                    : {
                        bounds:
                            [
                                [minMaxLanLng.minLng, minMaxLanLng.minLat],
                                [minMaxLanLng.maxLng, minMaxLanLng.maxLat]],
                        fitBoundsOptions: {
                            padding: 60
                        }
                    }}
            style={styleProps as React.CSSProperties}
            onError={error => {
                console.log(error);
                setMapErrorMessage("地図情報を取得できませんでした。");
            }}
            mapStyle={geoConfigMapStyle}
            onRender={() => {
                if (mapRef.current) {
                    const navigationImage = new Image();
                    navigationImage.onload = () => {
                        if (!mapRef.current?.hasImage('navigation')) {
                            mapRef.current?.addImage('navigation', navigationImage, { sdf: true });
                        }
                    }
                    navigationImage.src = navigation;
                }
            }}
            onMouseMove={onHover}
            onMoveEnd={() => {
                if (!isNewPolygonInsideOldPolygon()) {
                    getWeatherGeoJson()
                }
            }}
        >
            <Grid container direction={"column"}>
                <Grid container
                    direction="row"
                    alignItems='center'
                    zIndex={10}
                    position={"absolute"}
                    spacing={1}
                    padding={1}
                    xs="auto">
                    <Grid item>
                        <Paper sx={{
                            height: "30.75px",
                            width: "auto",
                            display: "flex",
                            backgroundColor: "rgba(246,246,246,0.8)"
                        }}>
                            <IconButton
                                ref={weatherMenuPopoverAnchor}
                                aria-owns={'mouse-over-popover'}
                                aria-haspopup="true"
                                size='small'
                                onMouseEnter={() => setOpenedWeatherMenuPopover(true)}
                                onMouseLeave={() => setOpenedWeatherMenuPopover(false)}>
                                <MoreVertIcon />
                            </IconButton>
                        </Paper>
                        <DroneGeoInformationPopover
                            opendPopover={openedWeatherMenuPopover}
                            setOpenedPopover={setOpenedWeatherMenuPopover}
                            mapStyle={mapStyle}
                            setMapStyle={setMapStyle}
                            setGeoConfigMapStyle={setGeoConfigMapStyle}
                            geoConfig={geoConfig}
                            setWeatherGeoJson={setWeatherGeoJson}
                            setUpdateWeatherInfo={props.setUpdateWeatherInfo}
                            isDisplayRainInfo={isDisplayRainInfo}
                            setDisplayRainInfo={setDisplayRainInfo}
                            isDisplayWindInfo={isDisplayWindInfo}
                            setDisplayWindInfo={setDisplayWindInfo}
                            popoverAnchor={weatherMenuPopoverAnchor}
                        />
                    </Grid>
                    {weatherGeoJson || isDisplayWeatherLoading ?
                        <Grid item>
                            <Paper sx={{
                                height: "30.75px",
                                width: "auto",
                                padding: "5px",
                                backgroundColor: "rgba(246,246,246,0.8)"
                            }}>
                                <Grid container direction="row" spacing={1} whiteSpace="nowrap">
                                    {isDisplayWeatherLoading ?
                                        <Grid item xs>
                                            <CircularProgress size="1rem" sx={{ margin: "2px 0px 0px 4px" }} />
                                        </Grid>
                                        : false
                                    }
                                    <Grid item xs="auto">
                                        <Typography variant='subtitle2'>
                                            {displayWeatherInfoDayJs ? displayWeatherInfoDayJs.format("HH:mm") + "時点" : undefined}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs="auto">
                                        <Typography variant='subtitle2'>
                                            {typeof displayAltitude !== "undefined" ? "海抜" + displayAltitude + "m" : undefined}
                                        </Typography>
                                    </Grid>
                                    <Grid item container direction="row" xs="auto" spacing={0.5} whiteSpace="nowrap">
                                        <Grid item xs>
                                            <SvgIcon fontSize='small'>
                                                <WeatherPouringIcon />
                                            </SvgIcon>
                                        </Grid>
                                        <Grid item xs>
                                            <Typography variant='subtitle2'>
                                                {typeof hoverInfo?.rain !== "undefined" ? hoverInfo?.rain + "mm" : "ー"}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item container direction="row" xs="auto" spacing={0.5} whiteSpace="nowrap">
                                        <Grid item xs>
                                            <SvgIcon fontSize='small'>
                                                <AirIcon />
                                            </SvgIcon>
                                        </Grid>
                                        <Grid item xs>
                                            <Typography variant='subtitle2'>
                                                {typeof hoverInfo?.windSpeed !== "undefined" ? hoverInfo?.windSpeed + "m/s" : "ー"}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        : undefined}
                </Grid>
                {errorMessage ?
                    <Grid item>
                        <Alert severity="error" sx={{ zIndex: 10, position: "relative", margin: 1 }}>
                            {errorMessage}
                        </Alert>
                    </Grid> : undefined}
                {props.portErrorMessage ?
                    <Grid item>
                        <Alert severity="error" sx={{ zIndex: 10, position: "relative", margin: 1 }}>
                            {props.portErrorMessage}
                        </Alert>
                    </Grid> : undefined}
                {weatherErrorMessage ?
                    <Grid item>
                        <Alert severity="error" sx={{ zIndex: 10, position: "relative", margin: 1 }}>
                            {weatherErrorMessage}
                        </Alert>
                    </Grid> : undefined}
            </Grid>
            {displayDroneGeoInformation()}
            {props.allPortList.map((port, index) => (
                <Marker
                    key={`marker-${index}`}
                    longitude={Number(port.longitude)}
                    latitude={Number(port.latitude)}
                    onClick={e => {
                        e.originalEvent.stopPropagation();
                        setPortPopupInfo(port);
                    }}
                >
                    {port.id === props.selectedFlightInfo?.arrival.id || port.id === props.selectedFlightInfo?.departure.id ?
                        <FlagIcon style={{
                            color: "#CC0000",
                            stroke: "#ffffff",
                            strokeWidth: "0.5px"
                        }} /> :
                        <FlagIcon style={{
                            color: mapStyle === MapStyle.neutral ? '#000000' : '#ffffff',
                            stroke: mapStyle === MapStyle.neutral ? "#ffffff" : "#000000",
                            strokeWidth: "0.5px"
                        }} />
                    }
                </Marker>
            ))}
            {portPopupInfo ?
                <PortPopup
                    port={portPopupInfo}
                    setPortPopupInfo={setPortPopupInfo} /> : undefined
            }
            {dronePopupInfo ?
                <DronePopup
                    dronePopupInfo={dronePopupInfo}
                    setDronePopupInfo={setDronePopupInfo} /> : undefined}

            {weatherGeoJson ?
                <React.Fragment>
                    {isDisplayRainInfo ?
                        <Source id="rain" type="geojson" data={weatherGeoJson?.geoJson}>
                            <Layer {...rainFillLayerStyle} />
                        </Source>
                        : undefined}
                    {isDisplayWindInfo ?
                        <Source id="wind" type="geojson" data={weatherGeoJson?.geoJson}>
                            <Layer {...windSymbolLayerStyle()} />
                        </Source>
                        : undefined}
                    <Grid container
                        alignItems='center'
                        position={"absolute"}
                        margin={1}
                        sx={{
                            height: `calc(100vh - 520px)`
                        }}
                        bottom={40} right={3} width="auto"
                        overflow={"auto"}>
                        <Grid item container direction="column" spacing={1}>
                            <Grid item>
                                <WeatherSampleColorPaper
                                    sampleColorList={windSpeedSampleColorList}
                                    weatherIcon={<AirIcon />}
                                    unit='m/s' />
                            </Grid>
                            <Grid item>
                                <WeatherSampleColorPaper
                                    sampleColorList={rainSampleColorList}
                                    weatherIcon={<SvgIcon fontSize='small'>
                                        <WeatherPouringIcon />
                                    </SvgIcon>}
                                    unit='mm' />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container position="absolute"
                        bottom={1}
                        margin={1}
                        display="flex"
                        xs="auto"
                    >
                        {openedWeatherSlider ?
                            <Grid item
                                onMouseEnter={() => setOpenedWeatherSlider(true)}
                                onMouseLeave={() => setOpenedWeatherSlider(false)}>
                                <Paper
                                    sx={{
                                        backgroundColor: "rgba(246,246,246,0.8)",
                                        padding: "12px 3px",
                                    }}>
                                    <WeatherAltitudeSlider
                                        setUpdateWeatherInfo={props.setUpdateWeatherInfo}
                                        displayAltitude={displayAltitude}
                                        setDisplayAltitude={setDisplayAltitude}
                                    />
                                </Paper>
                            </Grid>
                            : undefined}
                        <Grid item container>
                            <Grid item
                                onMouseEnter={() => setOpenedWeatherSlider(true)}
                                onMouseLeave={() => setOpenedWeatherSlider(false)}>
                                <Paper
                                    sx={{
                                        backgroundColor: "rgba(246,246,246,0.8)"
                                    }}
                                >
                                    <IconButton
                                        size='small'>
                                        <TuneIcon />
                                    </IconButton>
                                </Paper>
                            </Grid>
                            {openedWeatherSlider ?
                                <Grid item
                                    onMouseEnter={() => setOpenedWeatherSlider(true)}
                                    onMouseLeave={() => setOpenedWeatherSlider(false)}>
                                    <Paper
                                        sx={{
                                            backgroundColor: "rgba(246,246,246,0.8)",
                                            padding: "3px 12px",
                                            display: "flex"
                                        }}>
                                        <WeatherTimeSlider
                                            setUpdateWeatherInfo={props.setUpdateWeatherInfo}
                                            setDisplayWeatherInfoDayJs={setDisplayWeatherInfoDayJs}
                                            displayWeatherInfoDayJs={displayWeatherInfoDayJs} />
                                    </Paper>
                                </Grid>
                                : undefined}
                        </Grid>
                    </Grid>
                </React.Fragment>
                : undefined
            }
            {routeData ?
                <Source id="route" type="geojson" data={routeData}>
                    <Layer {...lineLayerStyle} />
                </Source>
                : undefined}
        </MapView >
        :
        <Box padding={3}>
            {mapErrorMessage ?
                <Alert severity="error" >{mapErrorMessage}</Alert>
                :
                <Alert severity="info" >フライトを選択するとマップが表示されます</Alert>
            }
        </Box>)
};