import { Alert, Backdrop, Breadcrumbs, Button, CircularProgress, Container, Grid, Paper, Tab, Tabs, TextField } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Display, FlightInfo, FlightStatus, FunctionPrivilege, UserInfo } from 'adoms-common-lib';
import axios from 'axios';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { havePrivilege } from '../../../common/PrivilegeUtil';
import { useUserInfoContext } from '../../../common/UserContext';
import { TabPanel } from '../../../components/atoms/TabPanel';
import { FlightCardForFlightListView } from '../../../components/molecule/FlightCardForFlightListView';
import { NotificationWarning } from '../../../components/molecule/NotificationWarning';
import { SelectDayButton } from '../../../components/molecule/SelectDayButton';
import { ChangeFlightScheduleDialog } from '../../../components/organisms/ChangeFlightScheduleDialog';
import { ErrorDialog } from '../../../components/organisms/ErrorDialog';
import { NewFlightCreateDialog } from '../../../components/organisms/NewFlightCreateDialog';
import OperationMenuBar from '../../../components/organisms/OperationMenuBar';
import { APIConnector } from '../../../connector/APIConnector';

const drawerWidth = 260;

const useStyles = makeStyles()((theme: any) => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.background.default
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },


  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },

  deliveryTable: {
    minWidth: 1024,
  },

  flightInfoInputTable: {
    minWidth: 512,
  },

  newButton: {
    position: "fixed",
    top: theme.spacing(9),
    right: theme.spacing(3),
  },

  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  },


  flightTextField: {
    width: 70
  },


  select: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  paper: {
    padding: '12px',
    textAlign: 'center',
    height: '100%'
  },
  griditem: {
    marginBottom: theme.spacing(2)
  },
}));

/**
 * フライト一覧
 */
export default function FlightListView() {
  const { classes, cx } = useStyles();
  const userInfoContext = useUserInfoContext();
  const navigate = useNavigate();

  const [isMenuOpen, setMenuOpen] = React.useState(false);
  const [operatingFlight, setOperatingFlight] = React.useState<FlightInfo[]>([] as FlightInfo[]);
  const [nonOperatingFlight, setNonOperatingFlight] = React.useState<FlightInfo[]>([] as FlightInfo[]);
  const [isNewFlightDialogOpen, setNewFlightDialogOpen] = React.useState(false);
  const [isChangeFlightScheduleDialogOpen, setChangeFlightScheduleDialogOpen] = React.useState(false);
  const [targetChangeFlightSchedule, setTargetChangeFlightSchedule] = React.useState<FlightInfo | undefined>();
  const [handleScheduleChangeCancel, setHandleScheduleChangeCancel] = React.useState<Function>(() => { });
  const [wasLoadedFlightData, setWasLoadedFlightData] = React.useState(false);
  const [canDisplayFlightData, setCanDisplayFlightData] = React.useState(false);
  const [isCorrectFlightDateISO8601DateFormat, setIsCorrectFlightDateISO8601DateFormat] = React.useState(true);
  const [flightDate, setFlightDate] = React.useState(Display.getNowDateWithString());
  const [isErrorDialogOpen, setErrorDialogOpen] = React.useState(false);
  const [userInfo, setUserInfo] = React.useState<UserInfo | undefined>(userInfoContext.userInfo);
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
  const [isDisplayLoadingMarkForUserInfo, setDisplayLoadingMarkForUserInfo] = React.useState(false);
  const [tabValue, setTabValue] = React.useState(0);

  //フライト情報を取得
  useEffect(() => {
    setWasLoadedFlightData(false);
    const fetchFlightData = async () => {
      let c: APIConnector = APIConnector.instance;
      await c.getFlightListByFlightDate(flightDate).then((flightList) => {
        let opeFLT = flightList.filter((v) => (
          v.status !== FlightStatus.Reset &&
          v.status !== FlightStatus.Canceled &&
          v.status !== FlightStatus.Irregular &&
          v.status !== FlightStatus.Completed)
        );
        setOperatingFlight(opeFLT);

        let nonOPEFLT = flightList.filter((v) => (
          v.status === FlightStatus.Reset ||
          v.status === FlightStatus.Canceled ||
          v.status === FlightStatus.Irregular ||
          v.status === FlightStatus.Completed)
        );
        setNonOperatingFlight(nonOPEFLT);

        setCanDisplayFlightData(true);
      }).catch((error) => {
        console.log(error);
        if (axios.isAxiosError(error)
          && typeof error.response !== "undefined"
          && error.response.status === 403) {
          handleToForbidden403Page();
        } else {
          setCanDisplayFlightData(false);
          setOperatingFlight([] as FlightInfo[]);
          setNonOperatingFlight([] as FlightInfo[]);
        };
      });
      setWasLoadedFlightData(true);
    };
    if (!isChangeFlightScheduleDialogOpen) {
      let isCorrectDateFormat = RegExp(/\d{4}-\d{2}-\d{2}/).test(flightDate);
      setIsCorrectFlightDateISO8601DateFormat(isCorrectDateFormat)
      /*
        React は再レンダー間で setState 関数の同一性が保たれ、変化しないため、
        useEffect内でsetした直後のisCorrectFlightDateISO8601DateFormatの値は参照できない
      */
      if (isCorrectDateFormat) {
        console.log('fetch flight data');
        fetchFlightData();
      }
    }
  }, [isChangeFlightScheduleDialogOpen, flightDate]);

  /**
  * userInfoを取得する
  */
  useEffect(() => {
    if (userInfoContext.isError) {
      setErrorMessage("ユーザー情報が取得できませんでした。一部の機能が使えないことがあります。");
      setDisplayLoadingMarkForUserInfo(false);
    } else {
      setErrorMessage(undefined);
      if (typeof userInfoContext.userInfo === "undefined") {
        setDisplayLoadingMarkForUserInfo(true);
      } else {
        setDisplayLoadingMarkForUserInfo(false);
      }
    }
    setUserInfo(userInfoContext.userInfo);
  }, [userInfoContext]);

  /**
   * メニューバーが開かれたときのハンドラ
   */
  const handleDrawerOpen = () => {
    setMenuOpen(true);
  };

  /**
   * メニューバーが閉じられた時のハンドラ
   */
  const handleDrawerClose = () => {
    setMenuOpen(false);
  };

  /**
   * ダイアログをクローズする
   */
  const handleNewFlightDialogClose = () => {
    setNewFlightDialogOpen(false);
  };

  /**
   * ダイアログを開く
   * @param scrollType 
   */
  const handleClickNewFlightDialogOpen = () => {
    setNewFlightDialogOpen(true);
  };

  /**
  * フライト日が変わった時のハンドラ
  * @param event 
  */
  const handleChangeFlightDate = (event: any) => {
    console.log('event.target.value:' + event.target.value);
    setFlightDate(event.target.value);
  };

  /**
   * フライトデータがない場合またはフェッチエラー時の表示制御部品
   * @returns エラーまたはフライトが存在しないときは状況に応じたHTML。それ以外のときは空のデータ。
   */
  const displayNoticeForNoFlightData = () => {
    let displayNoticeForNoFlightDataHTML;
    if (wasLoadedFlightData) {
      if (!canDisplayFlightData) {
        displayNoticeForNoFlightDataHTML =
          <Alert style={{ marginTop: "15px" }} severity="error">フライト情報を取得できませんでした</Alert>
      } else if (operatingFlight.length === 0) {
        displayNoticeForNoFlightDataHTML =
          <Alert style={{ marginTop: "15px" }} severity="info">現在オペレーション中のフライトはありません</Alert>
      };
    };
    return displayNoticeForNoFlightDataHTML;
  };

  /**
   * 403ページに遷移させる
   * @param navigate 
   */
  const handleToForbidden403Page = () => {
    navigate({
      pathname: "/forbidden403Page",
    });
  };

  /**
  * タブが変更された時のハンドラ
  */
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  /**
  * tabのidをセットする
  */
  const a11yProps = (index: any) => {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  };

  return (
    <div className={classes.root}>
      {/**メニューバーを表示する */}
      <OperationMenuBar
        onChangeDrawerOpen={handleDrawerOpen}
        onChangeDrawerClose={handleDrawerClose}
        open={isMenuOpen}
        title="フライト管理"
      />

      {/** コンテンツ部分 */}
      <main
        className={cx(classes.content, {
          /** メニューバーがオープン・クローズされたときのスタイルの変更*/
          [classes.contentShift]: isMenuOpen,
        })}
      >
        <div className={classes.drawerHeader} />
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="textPrimary" aria-current="page" variant="h5">
            フライト一覧
          </Typography>
        </Breadcrumbs>

        {havePrivilege(FunctionPrivilege.PrivilegeFunctionCreateFlight, userInfo) ?
          <Button variant="contained" color="primary"
            size="large"
            onClick={handleClickNewFlightDialogOpen}
            className={classes.newButton}
            startIcon={<AddCircleOutlineIcon />}>
            新規作成
          </Button> : undefined}
        <br />
        <br />
        <NotificationWarning />

        {
          errorMessage ? (
            <Grid item xs={12} sm={12} className={classes.griditem}>
              <br />
              <Paper className={classes.paper} elevation={1}>
                <Alert severity="error">{errorMessage}</Alert>
              </Paper>
            </Grid>
          ) : (null)
        }

        {
          /* 今後未来のフライトを参照する可能性を考慮し、
           実装を途中で終了したため、
           フライト日に日付選択または入力で未来を選択できる状況を抑止していない。
           */
        }
        <TextField
          variant="standard"
          id="date"
          label="フライト日"
          type="date"
          value={flightDate}
          className={classes.textField}
          onChange={handleChangeFlightDate}
          InputLabelProps={{
            shrink: true
          }}
          inputProps={
            {
              className: 'flightDateCell'
            }
          }
          error={!isCorrectFlightDateISO8601DateFormat}
          helperText={isCorrectFlightDateISO8601DateFormat ? '' : '正しい日付を入力してください'} />
        <br />
        <br />
        <SelectDayButton
          wasLoadedData={wasLoadedFlightData}
          date={flightDate}
          setDate={setFlightDate}
        />
        <br />
        <br />
        <br />

        <Grid container justifyContent="center" spacing={1} alignItems="stretch">
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Paper>
              <Paper square>
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  aria-label="basic tabs example"
                  textColor='primary'
                  indicatorColor='primary'
                  variant="fullWidth"
                >
                  <Tab label="フライト中" {...a11yProps(0)} />
                  <Tab label="フライト完了" {...a11yProps(1)} />
                </Tabs>
              </Paper>
              <TabPanel value={tabValue} index={0}>
                <Container maxWidth="md" component="main" style={{ marginRight: "auto" }}>
                  <Grid container spacing={2} alignItems="flex-end">
                    {operatingFlight.map(flightInfo => (
                      <FlightCardForFlightListView
                        key={flightInfo.id}
                        flightInfo={flightInfo}
                        isOpenChangeScheduleDialog={isChangeFlightScheduleDialogOpen}
                        setChangeFlightScheduleDialogOpen={setChangeFlightScheduleDialogOpen}
                        setTargetChangeFlightSchedule={setTargetChangeFlightSchedule}
                        setHandleScheduleChangeCancel={setHandleScheduleChangeCancel}
                        setErrorDialogOpen={setErrorDialogOpen}
                        canChangeStaStdEtaEtd={havePrivilege(FunctionPrivilege.PrivilegeFunctionChangeStaStdEtaEtd, userInfo)}
                      />
                    ))}

                  </Grid>
                </Container>
                {displayNoticeForNoFlightData()}
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <Container maxWidth="md" component="main">
                  <Grid container spacing={5} alignItems="flex-end">
                    {nonOperatingFlight.map(flightInfo => (
                      <FlightCardForFlightListView
                        key={flightInfo.id}
                        flightInfo={flightInfo}
                        isOpenChangeScheduleDialog={isChangeFlightScheduleDialogOpen}
                        setChangeFlightScheduleDialogOpen={setChangeFlightScheduleDialogOpen}
                        setTargetChangeFlightSchedule={setTargetChangeFlightSchedule}
                        setHandleScheduleChangeCancel={setHandleScheduleChangeCancel}
                        setErrorDialogOpen={setErrorDialogOpen}
                        canChangeStaStdEtaEtd={havePrivilege(FunctionPrivilege.PrivilegeFunctionChangeStaStdEtaEtd, userInfo)}
                      />
                    ))}
                  </Grid>
                </Container>
              </TabPanel>
            </Paper>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            {/**右半分に配送詳細やMMセンサーの情報等を載せたい */}
          </Grid>
        </Grid>

        {/** 新しいフライトを作成するダイアログ */
          isNewFlightDialogOpen ? (
            <NewFlightCreateDialog isOpen={isNewFlightDialogOpen} closeHandler={handleNewFlightDialogClose} />
          ) : null}

        {/** フライトスケジュール変更確認用ダイアログ */
          isChangeFlightScheduleDialogOpen && typeof targetChangeFlightSchedule !== 'undefined' ? (
            <ChangeFlightScheduleDialog
              isOpen={isChangeFlightScheduleDialogOpen}
              setIsOpen={setChangeFlightScheduleDialogOpen}
              handleScheduleChangeCancel={handleScheduleChangeCancel}
              flight={targetChangeFlightSchedule}
            />
          ) : null
        }

        <Backdrop
          className={classes.backdrop}
          open={!wasLoadedFlightData || isDisplayLoadingMarkForUserInfo}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <ErrorDialog
          isOpen={isErrorDialogOpen}
          setIsOpen={setErrorDialogOpen}
          errorMessage={"ユーザー情報を取得できませんでした。\n一部の機能が使用できないことがあります。"}
        />
      </main>
    </div >
  );
}