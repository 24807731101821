import { ListItemIcon, Menu, MenuItem, MenuProps, Theme } from '@mui/material';
import { FlightInfo, FlightStatus } from 'adoms-common-lib';
import React from 'react';
import { withStyles } from 'tss-react/mui';
import FlightStatusInfo from '../../components/atoms/FlightStatusInfo';

const StyledMenu = withStyles((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
), {
    paper: {
        border: '1px solid #d3d4d5',
    },
});

const StyledMenuItem = withStyles(MenuItem, (theme: Theme) => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}));

type StatusInfoListProps = {
    flightInfo: FlightInfo
    handleStatusChange: (event: React.MouseEvent<HTMLElement>, newStatus: FlightStatus) => void
    handleStatusClose: () => void
    anchorEl: HTMLElement | null
}

/**
 * ステータス情報リスト
 */
export const StatusInfoList: React.FC<StatusInfoListProps> = (props) => {
    return (
        <StyledMenu
            id="customized-menu"
            anchorEl={props.anchorEl}
            keepMounted
            open={Boolean(props.anchorEl)}
            onClose={props.handleStatusClose}
        >
            <StyledMenuItem selected={props.flightInfo.status === FlightStatus.Canceled} onClick={(e) => { props.handleStatusChange(e, FlightStatus.Canceled) }}>
                <ListItemIcon>
                    <FlightStatusInfo status={FlightStatus.Canceled} />
                </ListItemIcon>
            </StyledMenuItem>

            <StyledMenuItem selected={props.flightInfo.status === FlightStatus.Irregular} onClick={(e) => { props.handleStatusChange(e, FlightStatus.Irregular) }}>
                <ListItemIcon>
                    <FlightStatusInfo status={FlightStatus.Irregular} />
                </ListItemIcon>
            </StyledMenuItem>

            <StyledMenuItem selected={props.flightInfo.status === FlightStatus.Reset} onClick={(e) => { props.handleStatusChange(e, FlightStatus.Reset) }}>
                <ListItemIcon>
                    <FlightStatusInfo status={FlightStatus.Reset} />
                </ListItemIcon>
            </StyledMenuItem>

            <StyledMenuItem selected={props.flightInfo.status === FlightStatus.OBStandby} onClick={(e) => { props.handleStatusChange(e, FlightStatus.OBStandby) }}>
                <ListItemIcon>
                    <FlightStatusInfo status={FlightStatus.OBStandby} />
                </ListItemIcon>
            </StyledMenuItem>
        </StyledMenu>
    )
}