import { Box, Card, CardActionArea, CardContent, CardHeader, Collapse, Grid, SvgIcon, Typography } from "@mui/material";
import { FlightInfo } from "adoms-common-lib";
import React from 'react';
import FlightStatusInfo, { StatusSize } from "../atoms/FlightStatusInfo";
import FlightIcon from '@mui/icons-material/Flight';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import { ReactComponent as DroneIcon } from "../../assets/icons/quadcopter.svg";

type FlightCardProps = {
    flightInfo: FlightInfo
    selectedFlightId: string | undefined
    setSelectedFlightId: React.Dispatch<React.SetStateAction<string | undefined>>
}

/**
 * ドローン位置情報表示用フライトカード
 */
export const FlightCardForDroneGeoInformationView: React.FC<FlightCardProps> = (props) => {
    /**
     * フライトカードの詳細を開いたとき
     */
    const handleExpandClick = () => {
        if (props.selectedFlightId === props.flightInfo.id) {
            props.setSelectedFlightId(undefined);
        } else {
            props.setSelectedFlightId(props.flightInfo.id);
        }
    };
    return (
        <Card sx={{ width: "230px" }}>
            <CardActionArea onClick={handleExpandClick}>
                <CardContent>
                    <Grid container direction="column" spacing={1}>
                        <Grid item>
                            <FlightStatusInfo status={props.flightInfo.status} statusSize={StatusSize.Small} />
                        </Grid>
                        <Grid item>
                            <Typography
                                variant="caption"
                                color="textSecondary"
                                component="div">
                                {props.flightInfo.businessPartnerId + " " + props.flightInfo.id}
                            </Typography>
                        </Grid>
                        <Grid item container direction="row" alignItems="center">
                            <Grid item>
                                <Typography
                                    variant="subtitle2"
                                    color="textPrimary"
                                    component="div"
                                    sx={{ maxWidth: "80px" }}>
                                    {props.flightInfo.departure.name}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <FlightIcon
                                    fontSize='small'
                                    sx={{
                                        transform: "rotate(90deg)",
                                        margin: "5px 5px 0px 5px"
                                    }} />
                            </Grid>
                            <Grid item>
                                <Typography
                                    variant="subtitle2"
                                    color="textPrimary"
                                    component="div"
                                    sx={{ maxWidth: "80px" }}>
                                    {props.flightInfo.arrival.name}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
                <Collapse in={props.flightInfo.id === props.selectedFlightId} timeout="auto" unmountOnExit>
                    <CardContent style={{ padding: "0px 16px 16px 16px" }}>
                        <Grid container direction="column" >
                            <Grid container item direction="row" alignItems={"center"}>
                                <Grid item>
                                    <WatchLaterIcon fontSize='small' sx={{ color: "#797979", display: "flex" }} />
                                </Grid>
                                <Grid item>
                                    <Typography
                                        variant="caption"
                                        color="textPrimary"
                                        component="div"
                                        sx={{ marginLeft: "3px", color: "#797979" }}>
                                        ETD－ETA
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" alignItems={"center"} sx={{ marginBottom: "3px" }}>
                                <Grid item>
                                    <Typography variant="caption" color="textPrimary" component="div" sx={{ marginLeft: "23px" }}>
                                        <Box fontWeight={"bold"}>
                                            {props.flightInfo.etd + "－" + props.flightInfo.eta}
                                        </Box>
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" alignItems={"center"}>
                                <Grid item>
                                    <RecordVoiceOverIcon fontSize='small' sx={{ color: "#797979", display: "flex" }} />
                                </Grid>
                                <Grid item>
                                    <Typography
                                        variant="caption"
                                        color="textPrimary"
                                        component="div"
                                        sx={{ marginLeft: "3px", color: "#797979" }}>
                                        フライトディレクター
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" alignItems={"center"} sx={{ marginBottom: "3px" }}>
                                <Grid item>
                                    <Typography variant="caption" color="textPrimary" component="div" sx={{ marginLeft: "23px" }}>
                                        <Box fontWeight={"bold"}>
                                            {props.flightInfo.fd.name}
                                        </Box>
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" alignItems={"center"}>
                                <Grid item>
                                    <SvgIcon
                                        fontSize="small" sx={{ color: "#797979", display: "flex" }}>
                                        <DroneIcon />
                                    </SvgIcon>
                                </Grid>
                                <Grid item>
                                    <Typography
                                        variant="caption"
                                        color="textPrimary"
                                        component="div"
                                        sx={{ marginLeft: "3px", color: "#797979" }}>
                                        ドローン種別
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" alignItems={"center"}>
                                <Grid item>
                                    <Typography variant="caption" color="textPrimary" component="div" sx={{ marginLeft: "23px" }}>
                                        <Box fontWeight={"bold"}>
                                            {props.flightInfo.drone.label}
                                        </Box>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Collapse>
            </CardActionArea >
        </Card >
    );
};