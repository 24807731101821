import FlightIcon from '@mui/icons-material/Flight';
import { Card, CardActionArea, CardContent, Grid, Typography } from "@mui/material";
import { FlightInfo } from "adoms-common-lib";
import React from 'react';
import FlightStatusInfo, { StatusSize } from "../atoms/FlightStatusInfo";

type FlightCardProps = {
    flightInfo: FlightInfo
    selectedFlightInfo: FlightInfo | undefined
    setSelectedFlightInfo: React.Dispatch<React.SetStateAction<FlightInfo | undefined>>
    flightIdRef: React.MutableRefObject<string | null>
}

/**
 * フライトオペレーション用フライトカード
 */
export const FlightCardForFlightOperationView: React.FC<FlightCardProps> = (props) => {

    const clickFlightCard = () => {
        props.setSelectedFlightInfo(props.flightInfo);
        props.flightIdRef.current = props.flightInfo.id;
    };

    return (
        <Card sx={props.flightInfo.id === props.selectedFlightInfo?.id ? { backgroundColor: "#aad2f2" } : undefined}>
            <CardActionArea onClick={clickFlightCard}>
                <CardContent>
                    <Grid container direction="column" spacing={1}>
                        <Grid item>
                            <FlightStatusInfo status={props.flightInfo.status} statusSize={StatusSize.Medium} />
                        </Grid>
                        <Grid item>
                            <Typography
                                variant="subtitle2"
                                color="textSecondary"
                                component="div">
                                {props.flightInfo.businessPartnerId + " " + props.flightInfo.id}
                            </Typography>
                        </Grid>
                        <Grid item container
                            direction="row"
                            alignItems="center"
                            flexWrap="nowrap"
                            spacing={1}>
                            <Grid item xs={5}>
                                <Typography
                                    variant="subtitle1"
                                    color="textPrimary">
                                    {props.flightInfo.departure.name}
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    color="textPrimary"
                                    sx={{ fontWeight: 'bold' }}>
                                    {props.flightInfo.etd}
                                </Typography>
                            </Grid>
                            <Grid item textAlign={"center"} xs={2}>
                                <FlightIcon
                                    fontSize="large"
                                    sx={{
                                        transform: "rotate(90deg)"
                                    }} />
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="subtitle1"
                                    color="textPrimary">
                                    {props.flightInfo.arrival.name}
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    color="textPrimary"
                                    sx={{ fontWeight: 'bold' }}>
                                    {props.flightInfo.eta}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </CardActionArea >
        </Card >
    );
};