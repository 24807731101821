import React from 'react';
import { makeStyles } from 'tss-react/mui';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { FlightStatus, getNextFlightStatus } from 'adoms-common-lib';
import { Fab, Table, TableCell, TableContainer, TableHead, TableRow, Zoom } from '@mui/material';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useMediaQuery } from 'react-responsive';


const useStyles = makeStyles()((theme: any) =>
({
  root: {
    width: '100%',
  },

  button: {
    marginRight: theme.spacing(1),
  },

  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  }
}));


function getFlightStep(status: FlightStatus): number {
  let result = 0;
  if (status === FlightStatus.OBStandby) {
    result = 0;
  } else if (status === FlightStatus.OBBeforePreparation) {
    result = 1;
  } else if (status === FlightStatus.OBPreparation) {
    result = 2;
  } else if (status === FlightStatus.OBBeforeTakeoff) {
    result = 3;
  } else if (status === FlightStatus.OBTakeoff) {
    result = 4;
  } else if (status === FlightStatus.OBInFlightClimb) {
    result = 5;
  } else if (status === FlightStatus.OBInFlightCruise) {
    result = 6;
  } else if (status === FlightStatus.OBInFlightDescent) {
    result = 7;
  } else if (status === FlightStatus.OBAfterLanding) {
    result = 8;
    //後方互換のため復路Standby～Takeoffは強制置換
  } else if (
    status === FlightStatus.RTStandby ||
    status === FlightStatus.RTBeforePreparation ||
    status === FlightStatus.RTPreparation ||
    status === FlightStatus.RTBeforeTakeoff ||
    status === FlightStatus.RTTakeoff
  ) {
    result = 9;
  } else if (status === FlightStatus.RTInFlightClimb) {
    result = 10;
  } else if (status === FlightStatus.RTInFlightCruise) {
    result = 11;
  } else if (status === FlightStatus.RTInFlightDescent) {
    result = 12;
  } else if (status === FlightStatus.RTAfterLanding) {
    result = 13;
  } else if (status === FlightStatus.Completed) {
    result = 14;
  }
  return result;
}


type FlightStatusStepBarProps = {
  status: FlightStatus,
  onChangeStatus?: (event: any, status: FlightStatus) => void,
  isReadOnly: boolean,
}


/**
 * 配送依頼の状態をステップバーで表示する
 * @param props steps, status
 */
const FlightStatusStepBar: React.FC<FlightStatusStepBarProps> = (props) => {
  const { classes } = useStyles();
  const status = props.status as FlightStatus;
  const step = getFlightStep(props.status as FlightStatus);
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })


  //往路のステップ
  const obsteps = [
    "StandBy",
    "Before Preparation",
    "Preparation",
    "Before Takeoff",
    "Takeoff",
    "InFlight Climb",
    "InFlight Cruise",
    "InFlight Descent",
    "After Landing"] as string[];


  //復路のステップ
  const rtsteps = [
    "Takeoff",
    "InFlight Climb",
    "InFlight Cruise",
    "InFlight Descent",
    "After Landing",
    "Completed",] as string[];



  /**
   * フライトステータスを変更するときのハンドラ
   * @param event 
   * @param newStatus 
   */
  const handleBackClick = (event: React.MouseEvent<HTMLElement>) => {
    if (props.onChangeStatus === undefined) {
      return;
    }

    let newStatus: FlightStatus
    //ステッパーに対応していないステータスの場合は動かさない
    if (
      status === FlightStatus.OBStandby ||
      status === FlightStatus.Reset ||
      status === FlightStatus.Canceled ||
      status === FlightStatus.Empty ||
      status === FlightStatus.Irregular) {
      return;
    } else if (
      status === FlightStatus.RTStandby ||
      status === FlightStatus.RTBeforePreparation ||
      status === FlightStatus.RTPreparation ||
      status === FlightStatus.RTBeforeTakeoff
    ) {
      newStatus = getNextFlightStatus(FlightStatus.RTTakeoff, -1)
    } else {
      newStatus = getNextFlightStatus(status, -1);
    }
    props.onChangeStatus(event, newStatus);
  }


  /**
 * フライトステータスを変更するときのハンドラ
 * @param event 
 * @param newStatus 
 */
  const handleNextClick = (event: React.MouseEvent<HTMLElement>) => {
    if (props.onChangeStatus === undefined) {
      return;
    }

    let newStatus: FlightStatus
    //ステッパーに対応していないステータスの場合は動かさない
    if (status === FlightStatus.Reset ||
      status === FlightStatus.Canceled ||
      status === FlightStatus.Empty ||
      status === FlightStatus.Irregular ||
      status === FlightStatus.Completed) {
      return;
    } else if (
      status === FlightStatus.RTStandby ||
      status === FlightStatus.RTBeforePreparation ||
      status === FlightStatus.RTPreparation ||
      status === FlightStatus.RTBeforeTakeoff
    ) {
      newStatus = getNextFlightStatus(FlightStatus.RTTakeoff, 1)
    } else {
      newStatus = getNextFlightStatus(status, 1);
    }
    props.onChangeStatus(event, newStatus);
  }

  return (
    <div className={classes.root}>
      <TableContainer>
        <Table >
          <TableHead>
            <TableRow>
              {props.isReadOnly ? null : (
                <TableCell align="left" style={{ width: 30 }}>
                  <Zoom in={true} style={{ transitionDelay: '500ms' }}>
                    <Fab color="primary" aria-label="Edit" size="small" onClick={handleBackClick}>
                      <ArrowBackOutlinedIcon />
                    </Fab>
                  </Zoom>
                </TableCell>
              )}
              <TableCell align="center">
                {step < 9 ? (
                  <Stepper
                    style={{ padding: 24 }}
                    activeStep={step}
                    alternativeLabel={false}
                    orientation="vertical"
                  >
                    {
                      obsteps.map((label: string, index: number) => {
                        const stepProps: { completed?: boolean } = {};
                        const labelProps: { optional?: React.ReactNode } = {};
                        return (
                          <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                          </Step>
                        );
                      })
                    }
                  </Stepper>
                ) : (
                  <Stepper
                    style={{ padding: 24 }}
                    activeStep={step - 9}
                    alternativeLabel={false}
                    orientation="vertical"
                  >
                    {
                      rtsteps.map((label: string, index: number) => {
                        const stepProps: { completed?: boolean } = {};
                        const labelProps: { optional?: React.ReactNode } = {};
                        return (
                          <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}
                              {/* <Typography style={{ writingMode: "vertical-rl", textAlign: "center" }}>{label}</Typography> */}
                            </StepLabel>
                          </Step>
                        );
                      })
                    }
                  </Stepper>
                )
                }

              </TableCell>
              {props.isReadOnly ? null : (
                <TableCell align="left" style={{ width: 30 }}>
                  <Zoom in={true} style={{ transitionDelay: '500ms' }}>
                    <Fab color="primary" aria-label="Edit" size="small" onClick={handleNextClick}>
                      <ArrowForwardOutlinedIcon />
                    </Fab>
                  </Zoom>
                </TableCell>)}
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>




    </div>
  );
}

export default FlightStatusStepBar;