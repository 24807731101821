import { Alert, Backdrop, Breadcrumbs, Button, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { FunctionPrivilege, Port } from 'adoms-common-lib';
import axios from 'axios';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { havePrivilege } from '../../../common/PrivilegeUtil';
import { useUserInfoContext } from '../../../common/UserContext';
import { PortDetailTable } from '../../../components/molecule/PortDetailTable';
import OperationMenuBar from '../../../components/organisms/OperationMenuBar';
import { PortDeleteDialog } from '../../../components/organisms/PortDeleteDialog';
import { APIConnector } from '../../../connector/APIConnector';
import PortCreateDialog from '../../../components/organisms/PortCreateDialog';
import PortUpdateDialog from '../../../components/organisms/PortUpdateDialog';

const drawerWidth = 260;

const useStyles = makeStyles()((theme: any) => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.background.default
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  formControl: {
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  deliveryTable: {
    minWidth: 1024,
  },
  newButton: {
    position: 'fixed',
    top: theme.spacing(9),
    right: theme.spacing(3),
  },
  paper: {
    padding: '12px',
    textAlign: 'center',
    height: '100%'
  },
  griditem: {
    marginBottom: theme.spacing(2)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

/**
 * ポート一覧
 */
export default function PortListView() {
  const { classes, cx } = useStyles();
  const navigate = useNavigate();
  const userInfoContext = useUserInfoContext();

  const [isOpen, setOpen] = React.useState(false);
  const [allPortList, setAllPortList] = React.useState<Port[]>([]);
  const [displayPortList, setDisplayPortList] = React.useState<Port[]>([]);
  const [partnerIdList, setPartnerIdList] = React.useState<string[]>([]);
  const [isPortCreateDialogOpen, setPortCreateDialogOpen] = React.useState(false);
  const [isPortUpdateDialogOpen, setPortUpdateDialogOpen] = React.useState(false);
  const [updatePort, setUpdatePort] = React.useState<Port>();
  const [isPortDeleteDialogOpen, setPortDeleteDialogOpen] = React.useState(false);
  const [deletePort, setDeletePort] = React.useState<Port>();
  const [isEditPortList, setEditPortList] = React.useState(false);
  const [partnerId, setPartnerId] = React.useState<string>();
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
  const [isDisplayLoadingMark, setDisplayLoadingMark] = React.useState(false);
  const [isDisplayLoadingMarkForUserInfo, setDisplayLoadingMarkForUserInfo] = React.useState(false);
  const [userErrorMessage, setUserErrorMessage] = React.useState<string | undefined>(undefined);
  const [canEditPortUser, setEditPortUser] = React.useState(false);

  /**
   * ポート情報を取得
   */
  useEffect(() => {
    const fetchData = async () => {
      setEditPortList(false);
      setErrorMessage(undefined);
      setDisplayLoadingMark(true);
      let c: APIConnector = APIConnector.instance;
      await c.getPortListForOperation().then(async (portList: Port[]) => {
        setAllPortList(portList);
        if (partnerId && partnerId !== "指定なし") {
          let displayPortList: Port[] = new Array();
          portList.forEach((port) => {
            if (port.businessPartnerId === partnerId) {
              displayPortList.push(port);
            }
          });
          setDisplayPortList(displayPortList);
        } else {
          setDisplayPortList(portList);
        };
        setDisplayLoadingMark(false);
      }).catch((error) => {
        console.log(error);
        setDisplayLoadingMark(false);
        if (axios.isAxiosError(error)
          && typeof error.response !== "undefined"
          && error.response.status === 403) {
          handleToForbidden403Page();
        } else {
          setErrorMessage("ポート情報を取得できませんでした。");
        };
      });
    };
    fetchData();
  }, [isEditPortList, partnerId]);

  /**
  * userInfoを取得する
  */
  useEffect(() => {
    if (userInfoContext.isError) {
      setUserErrorMessage("ユーザー情報が取得できませんでした。一部の機能が使えないことがあります。");
      setDisplayLoadingMarkForUserInfo(false);
    } else {
      setUserErrorMessage(undefined);
      if (typeof userInfoContext.userInfo === "undefined") {
        setDisplayLoadingMarkForUserInfo(true);
      } else {
        setDisplayLoadingMarkForUserInfo(false);
      }
    }
    setEditPortUser(havePrivilege(FunctionPrivilege.PrivilegeFunctionEditPort, userInfoContext.userInfo));
  }, [userInfoContext]);

  /**
   * メニューバーが開かれたときのハンドラ
   */
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  /**
   * メニューバーが閉じられた時のハンドラ
   */
  const handleDrawerClose = () => {
    setOpen(false);
  };

  /**
  * ポートを新規作成するダイアログをクローズする
  */
  const handlePortCreateDialogClose = () => {
    setPortCreateDialogOpen(false);
  };

  /**
   * ポートを新規作成するダイアログを開く
   */
  const handlePortCreateDialogOpen = () => {
    setPortCreateDialogOpen(true);
  };

  /**
  * ポートを更新するダイアログをクローズする
  */
  const handlePortUpdateDialogClose = () => {
    setPortUpdateDialogOpen(false);
  };

  /**
   * ポートを更新するダイアログを開く
   */
  const handlePortUpdateDialogOpen = (port: Port) => {
    setUpdatePort(port);
    setPortUpdateDialogOpen(true);
  };

  /**
  * ポートを削除するダイアログをクローズする
  */
  const handlePortDeteleDialogClose = () => {
    setPortDeleteDialogOpen(false);
  };

  /**
   * ポートを削除するダイアログを開く
   */
  const handlePortDeleteDialogOpen = (port: Port) => {
    setPortDeleteDialogOpen(true);
    setDeletePort(port);
  };


  /**
   * パートナーID選択が開かれた時のハンドラ
   */
  const handleSelectOpen = () => {
    let duplicatePartnerIdList = new Array();
    duplicatePartnerIdList.push("指定なし");
    allPortList.forEach(port => (
      duplicatePartnerIdList.push(port.businessPartnerId)
    ));
    // duplicatePartnerIdListの重複を削除し、partnerIdListにセットする
    setPartnerIdList(Array.from(new Set(duplicatePartnerIdList)));
  };

  /**
   * 表示するパートナーIDが変更された時のハンドラ
   */
  const handleChangePartnerId = (event: any) => {
    setPartnerId(event.target.value);
  };

  /**
   * 403ページに遷移させる
   * @param navigate 
   */
  const handleToForbidden403Page = () => {
    navigate(
      "/forbidden403Page"
    );
  };

  /**
   * TableBodyを表示する
   * @returns TableBody
   */
  const displayTableBody = () => {

    if (allPortList.length !== 0) {
      return (
        <TableBody>
          {displayPortList.map((port, i) => (
            <PortDetailTable
              key={i}
              port={port}
              displayPortList={displayPortList}
              handlePortUpdateDialogOpen={handlePortUpdateDialogOpen}
              handlePortDeleteDialogOpen={handlePortDeleteDialogOpen}
              canEditPortUser={canEditPortUser} />
          ))}
        </TableBody>
      )
    } else if (typeof errorMessage !== "undefined") {
      return (
        <TableBody>
          <TableRow>
            <TableCell colSpan={8}>
              <Alert severity="error">{errorMessage}</Alert>
            </TableCell>
          </TableRow>
        </TableBody>
      )
    } else if (!isDisplayLoadingMark) {
      return (
        <TableBody>
          <TableRow>
            <TableCell colSpan={8}>
              <Alert severity="info">ポート情報はありません</Alert>
            </TableCell>
          </TableRow>
        </TableBody>
      )
    };
  };

  return (
    <div className={classes.root}>
      <CssBaseline />

      {/**メニューバーを表示する */}
      <OperationMenuBar
        onChangeDrawerOpen={handleDrawerOpen}
        onChangeDrawerClose={handleDrawerClose}
        open={isOpen}
        title="ポート管理"
      />

      {/** コンテンツ部分 */}
      <main
        className={cx(classes.content, {
          /** メニューバーがオープン・クローズされたときのスタイルの変更*/
          [classes.contentShift]: isOpen,
        })}
      >
        <div className={classes.drawerHeader} />

        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="textPrimary" aria-current="page" variant="h5">
            ポート一覧
          </Typography>
        </Breadcrumbs>

        {canEditPortUser ?
          <Button variant="contained" color="primary"
            size="large"
            onClick={handlePortCreateDialogOpen}
            className={classes.newButton}
            startIcon={<AddCircleOutlineIcon />}
            style={{ zIndex: 100 }}>
            新規作成
          </Button>
          : undefined
        }

        {
          userErrorMessage ? (
            <Grid item xs={12} sm={12} className={classes.griditem}>
              <br />
              <Paper className={classes.paper} elevation={1}>
                <Alert severity="error">{userErrorMessage}</Alert>
              </Paper>
            </Grid>
          ) : (null)
        }

        <br />
        <FormControl className={classes.formControl} variant="filled" size="small">
          <InputLabel id="partnerIdLabel">パートナーID</InputLabel>
          <Select
            variant="filled"
            label="パートナーID"
            value={partnerId}
            onOpen={handleSelectOpen}
            onChange={handleChangePartnerId}>
            {partnerIdList.map((partnerId: string, i: number) => (
              <MenuItem value={partnerId}>
                {partnerId}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <br />
        <br />
        <TableContainer component={Paper}>
          <Table className={classes.deliveryTable} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{ width: 150 }}><b>パートナーID</b></TableCell>
                <TableCell style={{ width: 200 }}><b>ポート名</b></TableCell>
                <TableCell align="left" style={{ minWidth: 600 }}><b>住所</b></TableCell>
                <TableCell style={{ width: 150 }}><b>緯度</b></TableCell>
                <TableCell style={{ width: 150 }}><b>経度</b></TableCell>
                <TableCell style={{ width: 200 }}><b>センサーID</b></TableCell>
              </TableRow>
            </TableHead>
            {displayTableBody()}
          </Table>
        </TableContainer>
        <br />

        {/**ポート情報を新規作成するダイアログ */}
        <PortCreateDialog
          isOpen={isPortCreateDialogOpen}
          closeHandler={handlePortCreateDialogClose}
          allPortList={allPortList}
          setEditPortList={setEditPortList} />

        {/**ポート情報を更新するダイアログ */}
        <PortUpdateDialog
          isOpen={isPortUpdateDialogOpen}
          closeHandler={handlePortUpdateDialogClose}
          port={updatePort}
          allPortList={allPortList}
          setEditPortList={setEditPortList} />

        {/**ポート情報を削除するダイアログ */}
        <PortDeleteDialog
          isOpen={isPortDeleteDialogOpen}
          closeHandler={handlePortDeteleDialogClose}
          port={deletePort}
          setEditPortList={setEditPortList} />

        <Backdrop
          className={classes.backdrop}
          open={isDisplayLoadingMark || isDisplayLoadingMarkForUserInfo}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </main>
    </div>
  );
}