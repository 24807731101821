import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import InfoIcon from '@mui/icons-material/Info';
import SaveIcon from '@mui/icons-material/Save';
import { Alert, Backdrop, Breadcrumbs, Button, CircularProgress, Grid, Hidden, Link, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableRow, Tabs } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Fab from '@mui/material/Fab/Fab';
import Typography from '@mui/material/Typography';
import Zoom from '@mui/material/Zoom/Zoom';
import { FlightInfo, FlightStatus, FunctionPrivilege, OrderInfo, Port, Report, UserInfo, createDefault, createEmptyPort, flightStatusForCheckList, getTotalWeight, isReturnTrip } from 'adoms-common-lib';
import axios from 'axios';
import dayjs, { Dayjs } from 'dayjs';
import queryString from "query-string";
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { havePrivilege } from '../../../common/PrivilegeUtil';
import { useUserInfoContext } from '../../../common/UserContext';
import EnvSensorInfoBar from '../../../components/molecule/EnvSensorInfoBar';
import { FlightCardForFlightDetailView } from '../../../components/molecule/FlightCardForFlightDetailView';
import FlightStatusStepBar from '../../../components/molecule/FlightStatusStepBar';
import OrderCard from '../../../components/molecule/OrderCard';
import { ReportCardForFlightDetailView } from '../../../components/molecule/ReportCardForFlightDetailView';
import { ErrorDialog } from '../../../components/organisms/ErrorDialog';
import { FlightCheckListDialog } from '../../../components/organisms/FlightCheckListDialog';
import FlightOrderCombinationDialog from '../../../components/organisms/FlightOrderCombinationDialog';
import { ListForCheckListAndProcedure } from '../../../components/organisms/ListForCheckListAndProcedure';
import OperationMenuBar from '../../../components/organisms/OperationMenuBar';
import { APIConnector } from '../../../connector/APIConnector';

const drawerWidth = 260;

export type FlightCheckListItem = {
  CheckList: string,
  Value: string,
  Input: boolean,
  Critical: boolean
}

const useStyles = makeStyles()((theme: any) => ({

  root: {
    display: 'flex',
    backgroundColor: theme.palette.background.default
  },

  title: {
    flexGrow: 1,
    textAlign: 'center',
    color: 'white',
    backgroundColor: theme.palette.primary.main,
  },

  table: {
    minWidth: 100,
  },

  /**
   * メニューヘッダー
   */
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    // necessary for content to be below app bar
    justifyContent: 'flex-end'
  },

  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },

  contentDetail: {
    padding: theme.spacing(3)
  },

  /**
   * 
   */
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },


  paper: {
    padding: '12px',
    textAlign: 'center',
    height: '100%'
  },
  paperForAlert: {
    padding: '12px',
    textAlign: 'center',
    height: '100%',
    marginTop: theme.spacing(2)
  },
  savefab: {
    position: 'fixed',
    marginTop: theme.spacing(-1),
    right: theme.spacing(3),
    animation: `$flash infinite 0.5s`,
    "&:disabled": {
      animation: ``,
    }
  },
  '@keyframes flash': {
    '0%': {
      'opacity': '1'
    },
    '100%': {
      'opacity': '1'
    },
    '50%': {
      'background': '#ffcc80'
    }
  },

  textField: {
    width: '100%'
  },

  cancelfab: {
    position: 'fixed',
    marginTop: theme.spacing(-1),
    right: theme.spacing(24),
  },

  menu: {
    backgroundColor: 'white',
    color: theme.palette.primary.main
  },

  alert: {
    "& .MuiAlert-icon": {
      fontSize: '1.25em'
    },
    fontSize: '1.25em'
  },

  displayCheckListPaper: {
    padding: '12px',
    textAlign: 'center'
  },

  checkListTabs: {
    '& button': {
      minWidth: 25
    }
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  errorMessage: {
    marginTop: theme.spacing(2),
    padding: '12px',
    textAlign: 'center',
    height: '100%',
  }
}));

/**
 * ステータスが到着以降であるかを確認する
 * @param status 
 */
function isPreparationOrLater(status: FlightStatus): boolean {
  if (status === FlightStatus.Empty ||
    status === FlightStatus.OBStandby ||
    status === FlightStatus.OBBeforePreparation ||
    status === FlightStatus.OBPreparation ||
    status === FlightStatus.OBBeforeTakeoff) {
    return true;
  } else {
    return false;
  }
}


/**
 * 詳細
 */
export default function FlightDetailView(props: any) {
  const { classes, cx } = useStyles();
  const navigate = useNavigate();
  const userInfoContext = useUserInfoContext();
  const location = useLocation();
  const qs = queryString.parse(location.search);
  const [flightID, setFlightID] = React.useState<{ id: string }>(location.state == null ? { id: qs.id as string } : location.state as { id: string })
  const [isOpen, setOpen] = React.useState(false);
  const [flightInfo, setFlightInfo] = React.useState(createDefault());
  /*
  ファイルから取得されたチェックリストが格納されるMapは、
  キーにFlightStatusも記録されるため、
  型定義を行っていない。
  */
  const [fdCheckListMap, setFdCheckListMap] = React.useState<Map<string, FlightCheckListItem[]>>(new Map<string, FlightCheckListItem[]>())
  const [landingSupporterCheckListMap, setLandingSupporterCheckListMap] =
    React.useState<Map<string, FlightCheckListItem[]>>(new Map<string, FlightCheckListItem[]>())
  const [takeoffSupporterCheckListMap, setTakeoffSupporterCheckListMap] =
    React.useState<Map<string, FlightCheckListItem[]>>(new Map<string, FlightCheckListItem[]>())
  const [nonNormalProcedure, setNonNormalProcedure] = React.useState<FlightCheckListItem[]>([])
  const [displayCheckListOrProcedureKind, setDisplayCheckListOrProcedureKind] = React.useState<'fd' | 'landing' | 'takeoff' | 'nonNormal'>('fd');
  const [displayCheckListMap, setDisplayCheckListMap] = React.useState<Map<string, FlightCheckListItem[]>>(new Map<string, FlightCheckListItem[]>())
  const [inputRemarks, setInputRemarks] = React.useState<string>('');
  const [unsafeEvent, setUnsafeEvent] = React.useState<string>('');
  const [squawkDate, setSquawkDate] = React.useState<Dayjs | null>(null);
  const [flightSquawk, setFlightSquawk] = React.useState<string>("");
  const [actionDate, setActionDate] = React.useState<Dayjs | null>(null);
  const [correctiveAction, setCorrectiveAction] = React.useState<string>("");
  const [confirmer, setConfirmer] = React.useState<string>("");

  const [flightForReset, setFlightForReset] = React.useState(createDefault());
  const [initStatus, setInitStatus] = React.useState<FlightStatus>(FlightStatus.Empty);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  //ステータスが変更された場合true
  const [isEdit, setEdit] = React.useState(false);
  //保存ボタンをdiableにするかどうかのフラグ
  const [isDisableFlag, setDisableFlag] = React.useState(false);
  //フライトと配送を組み合わせるダイアログがオープンしているかどうかのフラグ
  const [isFOCombinationDialogOpen, setFOCombinationDialogOpen] = React.useState(false);
  const [isCheckListDialogOpen, setCheckListDialogOpen] = React.useState(false);
  //追加されたオーダー
  const [addedOrder, setAddedOrder] = React.useState<OrderInfo[]>([]);
  //保存されているフライト・注文の連携情報のキャッシュ
  const [cacheAddedOrder, setCacheAddedOrder] = React.useState<OrderInfo[]>([]);

  //到着地と出発地
  const [depPort, setDepPort] = React.useState<Port>(createEmptyPort());
  const [arrPort, setArrPort] = React.useState<Port>(createEmptyPort());
  // ステータスや配送情報を編集された場合true
  const [needUpdateTimeline, setNeedUpdateTimeline] = React.useState(false);
  // フライト情報履歴を表示するかどうかのフラグ
  const [displayFlightHistoryFlag, setDisplayFlightHistoryFlag] = React.useState(false);
  const [visibleHisoty, setVisibleHisoty] = React.useState(false);
  const [selectMainTabMenu, setSelectMainTabMenu] = React.useState<'Operation' | 'FlightInfo'>('Operation');
  const [isDisplayLoadingMark, setDisplayLoadingMark] = React.useState(false);
  const [userInfo, setUserInfo] = React.useState<UserInfo | undefined>(userInfoContext.userInfo);
  const [isDisplayLoadingMarkForUserInfo, setDisplayLoadingMarkForUserInfo] = React.useState(false);
  const [isErrorDialogOpen, setErrorDialogOpen] = React.useState(false);

  // 同じタイミングでエラーが発生する可能性があるため、上書きされないようにエラーメッセージ表示場所を分ける
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
  const [checkListErrorMessage, setCheckListErrorMessage] = React.useState<string | undefined>(undefined);
  const [userErrorMessage, setUserErrorMessage] = React.useState<string | undefined>(undefined);
  const [flightReportErrorMessage, setFlightReportErrorMessage] = React.useState<string | undefined>(undefined);

  //フライト情報を取得
  useEffect(() => {
    const fetchData = async () => {
      try {
        setDisplayLoadingMark(true);
        setErrorMessage(undefined);
        let c: APIConnector = APIConnector.instance;
        let currentFlight = await c.getFlight(flightID.id);

        /*
          取得したフライトデータからドローン機種を読み取る必要があるため、
          同時に読み取りを行う。
          また、結果の如何による処理はcsvReaderの中で行わせ、この後の処理に影響はないため、
          awaitしない。
        */
        csvReader(currentFlight);

        let orderIdTiedToFlightId = await c.getOrderIdTiedToFlightId(currentFlight);

        let p = orderIdTiedToFlightId.map((v, i) => {
          return c.getOrderForOperation(v.orderId);
        });
        let orders = await Promise.all(p);
        setAddedOrder(orders);
        setCacheAddedOrder(JSON.parse(JSON.stringify(orders)));
        setFlightInfo(currentFlight);
        setInputRemarks(currentFlight.remarks);
        setDepPort(currentFlight.departure);
        setArrPort(currentFlight.arrival);
        setInitStatus(currentFlight.status);
        setUnsafeEvent(currentFlight.unsafeEvent ?? "");
        setSquawkDate(currentFlight.report?.squawkDate ? dayjs(currentFlight.report?.squawkDate) : null);
        setFlightSquawk(currentFlight.report?.flightSquawk ?? "");
        setActionDate(currentFlight.report?.actionDate ? dayjs(currentFlight.report?.actionDate) : null);
        setCorrectiveAction(currentFlight.report?.correctiveAction ?? "");
        setConfirmer(currentFlight.report?.confirmer ?? "");
        const copy = JSON.parse(JSON.stringify(currentFlight)) as FlightInfo;
        setFlightForReset(copy);
        setDisplayLoadingMark(false);
      } catch (e) {
        console.log("error:" + e);

        if (axios.isAxiosError(e)
          && typeof e.response !== "undefined"
          && e.response.status === 403) {
          setDisplayLoadingMark(false);
          handleToForbidden403Page();
        } else {
          setErrorMessage("フライト詳細情報が取得できませんでした");
          setDisplayLoadingMark(false);
        }
      }
    }

    /**
     * publicフォルダ内のCSVファイルを読み込むハンドラ
     * @param currentFlight 
     */
    const csvReader = async (currentFlight: FlightInfo) => {
      //並列処理実施のためPromise待機用の配列を準備
      let getCheckListPromiseList: Promise<void>[] = [];
      let decodeCheckListCSVtoArray = async (response: Response): Promise<FlightCheckListItem[]> => {
        let contentType = response.headers.get('Content-Type') || response.headers.get('content-Type');
        console.log(`contentType: ${contentType}`);
        if (response.status === 200 &&
          (
            contentType == null ||
            /application\/vnd.ms-excel*/.test(contentType) ||
            /text\/csv*/.test(contentType)
          )
        ) {
          //取得したファイルの中身をテキストとしてデコードする
          // let csvFileText = await response.text();
          const arrayBuffer = await response.arrayBuffer();
          const csvFileText = new TextDecoder('utf-8').decode(arrayBuffer);
          //後ろの行改行のみで分割する。/nが本文にも含まれるため/nだけで分割しない
          let csvFlightTextArray = csvFileText.split('\r\n');
          //ヘッダー部と最後の空行を取り除く
          csvFlightTextArray = csvFlightTextArray.slice(1, csvFlightTextArray.length - 1);
          let checkListArray: FlightCheckListItem[] = [];
          //本文をチェックリストのオブジェクトに対応するよう割り当てる
          //Mapのcallback内でエラーが発生した時、関数直下までエラーが戻らないのが課題
          csvFlightTextArray.map((row) => {
            let arraySplitLine = row.split(',');
            let flightCheckListItem: FlightCheckListItem = {
              CheckList: arraySplitLine[0].replace(/"/g, ""),
              Value: arraySplitLine[1].replace(/"/g, ""),
              Input: arraySplitLine[2].toLowerCase() === 'true',
              Critical: arraySplitLine[3].toLowerCase() === 'true'
            }
            checkListArray.push(flightCheckListItem);
          });

          return checkListArray;
        } else {
          throw new Error('404');
        }
      }

      /**
       * 下記fetch用のerrorHandler
       * 手動で振り分けた404エラーかどうかを判断し、
       * そうではない場合は関数の終端のPromise.allへエラーを投げる
       * @param err 
       */
      const errorHandler = (error: Error) => {
        if (error.message !== '404') {
          throw error;
        }
      }
      //配列内の要素を回すためfor~ofを使う
      for (let status of flightStatusForCheckList) {
        /*
         * fetch APIを使用してpublicフォルダ内のローカルファイルを取得、
         * thenブロックで後処理定義
         */
        //名前：PF-Next
        let getFdCheckListPromise = fetch(
          `checkList/FD/${currentFlight.drone.kind}/${currentFlight.drone.kind}_FD_${status}.csv`,
          {
            headers: {
              'Accept': 'text/csv'
            }
          }
        ).then(async response => {
          let fdCheckListArray = await decodeCheckListCSVtoArray(response);
          if (fdCheckListArray.length !== 0) {
            setFdCheckListMap(new Map(fdCheckListMap.set(status, fdCheckListArray)));
          }
        }).catch(reason => {
          errorHandler(reason);
        });

        let getLandingSuppoterCheckListPromise = fetch(
          `checkList/LandingSuppoter/${currentFlight.drone.kind}/${currentFlight.drone.kind}_LandingSuppoter_${status}.csv`,
          {
            headers: {
              'Accept': 'text/csv'
            }
          }
        ).then(async response => {
          let landingSuppoterCheckListArray = await decodeCheckListCSVtoArray(response);
          if (landingSuppoterCheckListArray.length !== 0) {
            setLandingSupporterCheckListMap(
              new Map(landingSupporterCheckListMap.set(status, landingSuppoterCheckListArray))
            );
          }
        }).catch(reason => {
          errorHandler(reason);
        });

        let getTakeoffSuppoterCheckListPromise = fetch(
          `checkList/TakeoffSuppoter/${currentFlight.drone.kind}/${currentFlight.drone.kind}_TakeoffSuppoter_${status}.csv`,
          {
            headers: {
              'Accept': 'text/csv'
            }
          }).then(async response => {
            let takeoffSuppoterCheckListArray = await decodeCheckListCSVtoArray(response);
            if (takeoffSuppoterCheckListArray.length !== 0) {
              setTakeoffSupporterCheckListMap(
                new Map(takeoffSupporterCheckListMap.set(status, takeoffSuppoterCheckListArray))
              );
            }
          }).catch(reason => {
            errorHandler(reason);
          });
        //発行したPromiseを配列にセット
        getCheckListPromiseList.push(getFdCheckListPromise);
        getCheckListPromiseList.push(getLandingSuppoterCheckListPromise);
        getCheckListPromiseList.push(getTakeoffSuppoterCheckListPromise);
      };

      let getNonNormalProcedurePromise = fetch(
        `checkList/NonNormalProcedure/${currentFlight.drone.kind}/${currentFlight.drone.kind}_NonNormalProcedure.csv`,
        {
          headers: {
            'Accept': 'text/csv'
          }
        }
      ).then(async response => {
        let nonNormalProcedureArray = await decodeCheckListCSVtoArray(response);
        if (nonNormalProcedureArray.length !== 0) {
          setNonNormalProcedure(nonNormalProcedureArray);
        }
      }).catch(reason => {
        errorHandler(reason);
      });

      getCheckListPromiseList.push(getNonNormalProcedurePromise);

      /*
        全部終わるまで待ってから結果を確認する。
        単純な404以外のエラーが発生した時、
        catchブロックへ入る。
      */
      await Promise.all(getCheckListPromiseList).then(() => {
        console.log('all success');
        setCheckListErrorMessage(undefined);
      }).catch(reason => {
        let error = reason as Error;
        console.log(reason)
        if (error.message !== '404') {
          setCheckListErrorMessage('チェックリストを正しく取得できませんでした。管理者へお問い合わせください。');
        }
      });
    };

    const firstLoadingDataHandler = async () => {
      setDisplayLoadingMark(true);

      await fetchData();

      setDisplayLoadingMark(false);
    }
    firstLoadingDataHandler();
  }, []);

  /**
   * userInfoを取得する
   */
  useEffect(() => {
    if (userInfoContext.isError) {
      setUserErrorMessage("ユーザー情報が取得できませんでした。一部の機能が使えないことがあります。");
      setDisplayLoadingMarkForUserInfo(false);
    } else {
      setUserErrorMessage(undefined);
      if (typeof userInfoContext.userInfo === "undefined") {
        setDisplayLoadingMarkForUserInfo(true);
      } else {
        setDisplayLoadingMarkForUserInfo(false);
      }
    }
    setUserInfo(userInfoContext.userInfo);
  }, [userInfoContext]);

  /**
   * メニューバーが開かれたときのハンドラ
   */
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  /**
   * メニューバーが閉じられた時のハンドラ
   */
  const handleDrawerClose = () => {
    setOpen(false);
  };

  /**
  * メニューバーが開かれたときのハンドラ
  */
  const handleHistoryDrawerOpen = () => {
    setVisibleHisoty(true);
  };

  /**
   * メニューバーが閉じられた時のハンドラ
   */
  const handleHistoryDrawerClose = () => {
    setVisibleHisoty(false);
  };

  const handleDisplayAlert = () => {
    let infoAlert;

    if (
      flightForReset.status == FlightStatus.OBTakeoff ||
      flightForReset.status == FlightStatus.OBInFlightClimb ||
      flightForReset.status == FlightStatus.OBInFlightCruise ||
      flightForReset.status == FlightStatus.OBInFlightDescent ||
      flightForReset.status == FlightStatus.RTTakeoff ||
      flightForReset.status == FlightStatus.RTInFlightClimb ||
      flightForReset.status == FlightStatus.RTInFlightCruise ||
      flightForReset.status == FlightStatus.RTInFlightDescent
    ) {
      infoAlert = <Alert className={classes.alert} severity="info">{"バッテリー容量と電圧、高度、速度、姿勢を確認！"}</Alert>
    }

    return typeof infoAlert !== "undefined" ?
      <Paper className={classes.paperForAlert} elevation={1}>
        {infoAlert}
      </Paper> :
      null
  }

  const handleStatusClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleStatusClose = () => {
    setAnchorEl(null);
  };

  const displayAllFlightCheckList = () => {
    let returnFlightCheckList: JSX.Element[] = [];
    if (displayCheckListOrProcedureKind !== 'nonNormal') {
      flightStatusForCheckList.forEach(status => {
        let checkList: FlightCheckListItem[] | undefined = undefined;
        if (displayCheckListOrProcedureKind === 'fd') {
          checkList = fdCheckListMap.get(status);
        } else if (displayCheckListOrProcedureKind === 'takeoff') {
          checkList = takeoffSupporterCheckListMap.get(status);
        } else if (displayCheckListOrProcedureKind === 'landing') {
          checkList = landingSupporterCheckListMap.get(status);
        }
        if (typeof checkList !== 'undefined' && checkList.length !== 0) {
          returnFlightCheckList.push(
            <ListForCheckListAndProcedure
              key={`${status}FlightCheckList`}
              flightStatus={status}
              displayFlightCheckList={checkList}
            />
          );
        }
      })
    } else {
      if (nonNormalProcedure.length !== 0) {
        returnFlightCheckList.push(
          <ListForCheckListAndProcedure
            key={`nonNormalProcedureCheckList`}
            flightStatus={"NonNormal Procedure"}
            displayFlightCheckList={nonNormalProcedure}
          />
        );
      }
    }
    if (returnFlightCheckList.length !== 0) {
      return returnFlightCheckList;
    } else {
      let undefinedCheckList = <div style={{ alignItems: 'center', color: 'rgba(0, 0, 0, 0.5)' }}>
        <ErrorTwoToneIcon fontSize="large" color="disabled" /><br />
        Undefined checklist
      </div>
      return undefinedCheckList;
    };
  };

  const changeDisplayCheckListKind = (kind: 'fd' | 'landing' | 'takeoff' | 'nonNormal') => {
    setDisplayCheckListOrProcedureKind(kind);
  }

  /**
   * FOダイアログを開く
   */
  const handleFOOpenDialog = (event: any) => {
    setFOCombinationDialogOpen(true);
  }

  const handleFOCloseDialog = (event: any) => {
    setFOCombinationDialogOpen(false);
  }

  /**
   * フライトステータスを変更するときのハンドラ
   * @param event 
   * @param newStatus 
   */
  const handleStatusChange = (event: React.MouseEvent<HTMLElement>, newStatus: FlightStatus) => {
    console.log(newStatus + ":" + event);
    setAnchorEl(null);
    if (flightInfo.status !== newStatus) {
      let newFlight = { ...flightInfo };
      newFlight.status = newStatus;
      setFlightInfo(newFlight);
      setInitStatus(newStatus);
      setEdit(true);
    }
  };

  const sendDisplayCheckList = (): boolean => {
    //フライトステータスのEnumを文字列化したものの一覧を取得する
    let flightStatusValues = Object.values(FlightStatus);
    /*
     フライトステータスがフライトの異常終了を示すものに設定される場合、
     復路ステータス→往路ステータスへの遷移の場合、
     往路・復路ステータスで、設定しているより前のステータスに遷移する場合は
     チェックリストのダイアログは表示しない。

     尚、遷移元のステータスがフライトの異常終了を示すものである場合は、
     設定しているより前のステータスに遷移と判定されるため、
     チェックリストは表示されない。
    */
    if (
      flightInfo.status === FlightStatus.Reset ||
      flightInfo.status === FlightStatus.Canceled ||
      flightInfo.status === FlightStatus.Empty ||
      flightInfo.status === FlightStatus.Irregular ||
      (/^RT/.test(flightForReset.status) &&
        /^OB/.test(flightInfo.status)) ||
      flightStatusValues.indexOf(flightForReset.status) - flightStatusValues.indexOf(flightInfo.status) >= 0
    ) {
      setDisplayCheckListMap(new Map<string, FlightCheckListItem[]>());
      return false;
    } else {
      /*
      フライトステータスがStandbyからbeforetakeoffの間であれば、
      遷移前ステータスと遷移先ステータスの間のチェックリスト + 
      遷移先のステータスのチェックリストを表示する。

      それ以外であれば、遷移前のステータスのチェックリスト + 
      遷移前ステータスと遷移先ステータスの間のチェックリストを表示する。
      */

      //元々設定されていたフライトステータスの配列内での位置を取得する
      let firstIndex = flightStatusValues.indexOf(flightForReset.status);
      let displayFlightStatusMap = new Map<string, FlightCheckListItem[]>();

      //元々設定されていたフライトステータスから遷移先のフライトステータスまでに表示すべきチェックリストの一覧を作成する
      for (let i = firstIndex; i < flightStatusValues.indexOf(FlightStatus.Completed); i++) {
        //往路・復路の区別を外したキー名
        let key: string | undefined = undefined;
        //飛行中のステータスは「In」を取り除かずに表示するため、往路・復路を示す値のみ省く
        if (/InFlightClimb|InFlightCruise|InFlightDescent/g.test(flightStatusValues[i])) {
          key = flightStatusValues[i].replace(/^OB-|RT-/g, "");
          /*
          それ以外であれば、往路・復路・接頭辞「In」を取り除くが、
          現状チェックリストを表示を想定していない通常のステータスにはキー名を設定しない
          */
        } else {
          //復路準備ステータスは現状使用していないが、一覧には存在するため考慮
          if (!/Standby|RT-BeforePreparation|RT-InPreparation|RT-InBeforeTakeoff/g.test(flightStatusValues[i])) {
            key = flightStatusValues[i].replace(/^OB-|RT-|In/g, "");
          }
        }

        //参照しているフライトステータスが遷移先のフライトステータスである場合
        if (flightStatusValues[i] === flightInfo.status) {
          //離陸準備中のステータスである場合で、キー名が存在している場合はチェックリストの表示対象とする
          if (
            /BeforePreparation|Preparation|BeforeTakeoff/g.test(flightStatusValues[i]) &&
            key != null
          ) {
            console.log(`key: ${key}`);
            let checkList = fdCheckListMap.get(key);
            if (typeof checkList !== 'undefined') {
              displayFlightStatusMap.set(`${key}(${flightStatusValues[i]})`, checkList);
            }
          }
          //それ以外であれば、遷移先のステータスのチェックリストは表示対象とせず、ループを抜ける
          break;
        } else {
          //チェックリスト作成対象外のステータスの場合キーが存在しないためチェックリストの作成は行わない
          if (key != null) {
            //2つ以上先へのステータス遷移を考慮し、最初のチェックの場合は、離陸準備中のステータスであるかチェックする
            if (
              i !== firstIndex ||
              (
                i === firstIndex &&
                !/Standby|BeforePreparation|Preparation|BeforeTakeoff/g.test(flightStatusValues[i])
              )
            ) {
              /*
              最初のチェックではない、または、最初のチェックであっても離陸準備中のステータスではない場合は、
              表示するチェックリストを一覧に加える
              */
              console.log(`key: ${key}`);
              let checkList = fdCheckListMap.get(key);
              if (typeof checkList !== 'undefined') {
                displayFlightStatusMap.set(`${key}(${flightStatusValues[i]})`, checkList);
              }
            }
          }
        }
      }
      console.log('displaFlightStatusMap:');
      console.log(displayFlightStatusMap);
      setDisplayCheckListMap(displayFlightStatusMap);
      return displayFlightStatusMap.size !== 0;
    }
  };

  const handleStoreClick = () => {
    if (sendDisplayCheckList()) {
      setCheckListDialogOpen(true);
    } else {
      handleSave();
    }
  }

  const handleSave = async () => {
    setFlightReportErrorMessage(undefined);
    const fetchData = async () => {
      setDisplayLoadingMark(true);
      let addOrderId = [] as string[];
      addedOrder.forEach((v, i) => {
        addOrderId.push(v.orderID);
      })
      /**
       * dynamoDBにorderを保存
       */
      let apigw: APIConnector = APIConnector.instance;
      //現在のフライトを再度取得し最新のものを備考と一緒に送信する
      await apigw.getFlight(flightInfo.id).then(async flightDataForCheckSchedule => {

        //フライト情報は最終的にputFlightStatusChangeで得たものを適用するため、こちらを先に実行する
        let flightScheduleParam: {
          id: string,
          std: string,
          sta: string,
          etd: string,
          eta: string,
          remarks: string,
          unsafeEvent?: string,
          report?: Report
        } = {
          id: flightInfo.id,
          std: flightDataForCheckSchedule.std,
          sta: flightDataForCheckSchedule.sta,
          etd: flightDataForCheckSchedule.etd,
          eta: flightDataForCheckSchedule.eta,
          remarks: inputRemarks,
          unsafeEvent: unsafeEvent ? unsafeEvent : undefined
        };

        if (squawkDate || flightSquawk || actionDate
          || correctiveAction || confirmer) {
          if (!squawkDate || !flightSquawk) {
            setFlightReportErrorMessage("フライトレポートを記載する場合は発生日時と不具合事項を必ず入力してください。");
            setDisplayLoadingMark(false);
            setDisableFlag(false);
            return;
          };
          flightScheduleParam.report = {
            squawkDate: squawkDate?.format('YYYY-MM-DD'),
            flightSquawk: flightSquawk ? flightSquawk : undefined,
            actionDate: actionDate?.format('YYYY-MM-DD'),
            correctiveAction: correctiveAction ? correctiveAction : undefined,
            confirmer: confirmer ? confirmer : undefined
          } as Report
        };

        //簡潔に記載する場合はPromiseを配列に詰め込んでfor文で回してそれぞれawaitをかける
        await apigw.putFlightScheduleChange(flightScheduleParam).then(async value => {
          await apigw.putFlightStatusChange(flightInfo, addOrderId).then((value: FlightInfo) => {
            /**
             * 戻りの配送依頼情報を初期値にセットする
             */
            let saveFlight = value as FlightInfo;
            setFlightInfo(saveFlight);
            setCacheAddedOrder(JSON.parse(JSON.stringify(addedOrder)));
            setDisableFlag(false);
            setErrorMessage(undefined);
            setEdit(false);
            setInitStatus(saveFlight.status);
            setFlightForReset(JSON.parse(JSON.stringify(saveFlight)) as FlightInfo);
            setNeedUpdateTimeline(true);
          }).catch((error) => {
            handleError(error);
          });
        }).catch(error => {
          handleError(error);
        });
        setDisplayLoadingMark(false);
      }).catch(error => {
        handleError(error);
      });
    }

    const handleError = (error: string) => {
      console.log("エラー:" + error);
      setDisplayLoadingMark(false);
      setDisableFlag(false);
      if (axios.isAxiosError(error)
        && typeof error.response !== "undefined"
        && error.response.status === 403) {
        setErrorDialogOpen(true);
        handleCancelClick();
      } else {
        setErrorMessage('保存できませんでした。ページを再読み込みして操作をやりなおしてください。');
      };
    };

    fetchData();
  }

  /**
   * 元に戻すときのハンドラ
   */
  const handleCancelClick = async () => {
    let copy = JSON.parse(JSON.stringify(flightForReset)) as FlightInfo;
    setFlightInfo(copy);
    setInitStatus(copy.status);
    setAddedOrder(JSON.parse(JSON.stringify(cacheAddedOrder)));
    setInputRemarks(copy.remarks ?? "");
    setUnsafeEvent(copy.unsafeEvent ?? "");
    setSquawkDate(copy.report?.squawkDate ? dayjs(copy.report?.squawkDate) : null);
    setFlightSquawk(copy.report?.flightSquawk ?? "");
    setActionDate(copy.report?.actionDate ? dayjs(copy.report?.actionDate) : null);
    setCorrectiveAction(copy.report?.correctiveAction ?? "");
    setConfirmer(copy.report?.confirmer ?? "");
    setEdit(false);
  }

  const handleAddOrder = (order: OrderInfo | undefined) => {
    if (typeof order !== "undefined") {
      setAddedOrder([...addedOrder, order]);
      setEdit(true);
    }
  }

  const handleDeleteOrder = (order: OrderInfo | undefined) => {
    if (typeof order !== "undefined") {
      const filtered = addedOrder.filter((v) => v.orderID !== order.orderID)
      setAddedOrder(filtered);
      setEdit(true);
    }
  }


  const totalPayload = (): string => {
    let payload = 0;
    addedOrder.map((v, i): void => {
      payload += getTotalWeight(v.goods)
    });
    return payload.toLocaleString() + "g";
  }

  /**
   * 備考内の現在の入力値を取得するためのハンドラ
   * @param event 備考を入力するテキストフィールドからのonChangeイベント
   */
  const changeRemarksTextfieldValue = (event: any) => {
    setInputRemarks(event.target.value);
    //変更があれば編集フラグをつける。他にも判断材料があるため元に戻しても消さない。
    setEdit(true);
  }

  /**
   * 飛行の安全に影響のあった事項内の現在の入力値を取得するためのハンドラ
   * @param event 飛行の安全に影響のあった事項を入力するテキストフィールドからのonChangeイベント
   */
  const changeUnsafeEventTextfieldValue = (event: any) => {
    setUnsafeEvent(event.target.value);
    //変更があれば編集フラグをつける。他にも判断材料があるため元に戻しても消さない。
    setEdit(true);
  }

  /**
   * フライトレポート発生年月日が変更された時
   * @param event 
   */
  const changeSquawkDateValue = (newValue: Dayjs | null) => {
    setSquawkDate(newValue);
    //変更があれば編集フラグをつける。他にも判断材料があるため元に戻しても消さない。
    setEdit(true);
  };

  /**
   * フライトレポート不具合事項が変更された時
   * @param event
   */
  const changeFlightSquawkTextfieldValue = (event: any) => {
    setFlightSquawk(event.target.value);
    //変更があれば編集フラグをつける。他にも判断材料があるため元に戻しても消さない。
    setEdit(true);
  }

  /**
   * フライトレポート処置年月日が変更された時
   * @param event 
   */
  const changeActionDateValue = (newValue: Dayjs | null) => {
    setActionDate(newValue);
    //変更があれば編集フラグをつける。他にも判断材料があるため元に戻しても消さない。
    setEdit(true);
  };

  /**
   * フライトレポート処置その他が変更された時
   * @param event
   */
  const changeCorrectiveActionTextfieldValue = (event: any) => {
    setCorrectiveAction(event.target.value);
    //変更があれば編集フラグをつける。他にも判断材料があるため元に戻しても消さない。
    setEdit(true);
  }

  /**
   * フライトレポート確認者が変更された時
   * @param event
   */
  const changeConfirmerTextfieldValue = (event: any) => {
    setConfirmer(event.target.value);
    //変更があれば編集フラグをつける。他にも判断材料があるため元に戻しても消さない。
    setEdit(true);
  }

  /**
   * 403ページに遷移させる
   * @param navigate 
   */
  const handleToForbidden403Page = () => {
    navigate(
      "/forbidden403Page",
    )
  }

  return (
    <div className={classes.root}>
      <CssBaseline />

      {/**メニューバーを表示する */}
      <OperationMenuBar
        onChangeDrawerOpen={handleDrawerOpen}
        onChangeDrawerClose={handleDrawerClose}
        open={isOpen}
        onChangeHistoryDrawerOpen={handleHistoryDrawerOpen}
        onChangeHistoryDrawerClose={handleHistoryDrawerClose}
        visibleHisoty={visibleHisoty}
        title="フライト管理"
        flightId={flightID.id}
        needUpdateTimeline={needUpdateTimeline}
        setNeedUpdateTimeline={setNeedUpdateTimeline}
        displayFlightHistoryFlag={displayFlightHistoryFlag}
        setDisplayFlightHistoryFlag={setDisplayFlightHistoryFlag}
      />

      {/** コンテンツ部分 */}
      <main
        className={cx(classes.content, {
          /** メニューバーがオープン・クローズされたときのスタイルの変更*/
          [classes.contentShift]: isOpen,
        })}
      >
        <div className={classes.drawerHeader} id="flightDetailDrawerHeader" />
        <div>
          <Paper className={classes.menu}>
            <Tabs
              indicatorColor="primary"
              textColor="inherit"
              value={selectMainTabMenu}
              onChange={(event, newValue) => {
                setSelectMainTabMenu(newValue);
                console.log("newValue:" + newValue);
              }}
              variant="fullWidth"
            >
              <Tab icon={<FlightTakeoffIcon />} label="Operation" value="Operation" />
              <Tab icon={<InfoIcon />} label="Flight Info" value="FlightInfo" />
            </Tabs>
          </Paper>
        </div>
        <div className={classes.contentDetail}>
          {
            /**
             * データが更新されている場合、保存ボタンを表示する
             */
            isEdit ?
              (
                <div>
                  <Zoom in={isEdit} style={{ transitionDelay: isEdit ? '500ms' : '0ms' }}>
                    <Button variant="contained" color="primary"
                      size="large"
                      className={classes.cancelfab}
                      startIcon={<ClearOutlinedIcon />}
                      onClick={handleCancelClick}
                      disabled={isDisableFlag}
                      style={{ zIndex: 1000 }}
                    >
                      元に戻す
                    </Button>
                  </Zoom>
                  <Zoom in={isEdit} style={{ transitionDelay: isEdit ? '500ms' : '0ms' }}>
                    <Button variant="contained" color="secondary"
                      size="large"
                      className={classes.savefab}
                      startIcon={<SaveIcon />}
                      onClick={handleStoreClick}
                      disabled={isDisableFlag}
                      style={{ zIndex: 1000 }}
                    >
                      変更を保存
                    </Button>
                  </Zoom>
                </div>
              ) : null
          }

          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href="/flightlistview" variant="h5">
              フライト一覧
            </Link>
            <Typography color="textPrimary" aria-current="page" variant="h5">
              フライト詳細
            </Typography>
          </Breadcrumbs>

          <Grid item xs={12} sm={12} >
            {
              errorMessage ? (
                <Paper className={classes.errorMessage} elevation={1}>
                  <Alert severity="error">{errorMessage}</Alert>
                </Paper>
              ) : (null)
            }
            {
              checkListErrorMessage ? (
                <Paper className={classes.errorMessage} elevation={1}>
                  <Alert severity="error">{checkListErrorMessage}</Alert>
                </Paper>
              ) : (null)
            }
            {
              userErrorMessage ? (
                <Paper className={classes.errorMessage} elevation={1}>
                  <Alert severity="error">{userErrorMessage}</Alert>
                </Paper>
              ) : (null)
            }
          </Grid>
          <br />
          {selectMainTabMenu === "Operation" ? (
            <Grid container justifyContent="flex-start" spacing={1} alignItems="stretch">
              <Grid item lg={6} md={12} sm={12} xs={12} >
                <Paper className={classes.paper} elevation={1}>
                  <Typography style={{ padding: 0 }} className={classes.title} variant="h6" component="div">
                    チェックリスト・Non-Normal Procedure
                  </Typography>
                  <Tabs
                    className={classes.checkListTabs}
                    value={displayCheckListOrProcedureKind}
                    onChange={(event, newValue) => {
                      changeDisplayCheckListKind(newValue);
                      console.log("newValue:" + newValue);
                    }}
                    variant='fullWidth'
                  >
                    <Tab label="FD" value="fd" />
                    <Tab label="離陸サポ" value="takeoff" />
                    <Tab label="着陸サポ" value="landing" />
                    <Tab label="Non-Normal" value="nonNormal" />
                  </Tabs>
                  <Hidden only={["lg", "xl"]}>
                    <div
                      style={{ maxHeight: "300px", overflow: "auto" }}
                      className={classes.displayCheckListPaper}
                    >
                      {displayAllFlightCheckList()}
                    </div>
                  </Hidden>
                  <Hidden only={["md", "sm", "xs"]}>
                    <div
                      style={{ maxHeight: `calc(100vh - 100px)`, overflow: "auto" }}
                      className={classes.displayCheckListPaper}
                    >
                      {displayAllFlightCheckList()}
                    </div>
                  </Hidden>
                </Paper>
              </Grid>
              <Grid item lg={6} md={12} sm={12} xs={12} >
                <Paper className={classes.paper} elevation={1}>
                  <Typography className={classes.title} variant="h6" component="div">
                    フライトの進捗{isReturnTrip(flightInfo.status) ? "(復路)" : "(往路)"}
                  </Typography>
                  <FlightStatusStepBar
                    isReadOnly={!havePrivilege(FunctionPrivilege.PrivilegeFunctionChangeFlightStatus, userInfo)}
                    status={initStatus}
                    onChangeStatus={handleStatusChange} />
                </Paper>
              </Grid>
            </Grid>
          ) :
            <Grid container justifyContent="center" spacing={1} alignItems="stretch">
              <Grid item lg={6} md={6} sm={12} xs={12} >
                <Paper className={classes.paper} elevation={1}>
                  <Grid container justifyContent="center" spacing={1}>
                    <Grid item xs={12} sm={12} >
                      <Typography className={classes.title} variant="h6" component="div">
                        フライト情報
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} >
                      <FlightCardForFlightDetailView
                        flightInfo={flightInfo}
                        changeRemarksTextfieldValue={changeRemarksTextfieldValue}
                        inputRemarks={inputRemarks}
                        changeUnsafeEventTextfieldValue={changeUnsafeEventTextfieldValue}
                        unsafeEvent={unsafeEvent}
                        userInfo={userInfo}
                        handleStatusClick={handleStatusClick}
                        handleStatusClose={handleStatusClose}
                        handleStatusChange={handleStatusChange}
                        anchorEl={anchorEl} />

                    </Grid>
                    <Grid item xs={12} sm={12} >
                      <Typography className={classes.title} variant="h6" component="div">
                        フライトレポート
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} >
                      <ReportCardForFlightDetailView
                        flightInfo={flightInfo}
                        squawkDate={squawkDate}
                        changeSquawkDateValue={changeSquawkDateValue}
                        flightSquawk={flightSquawk}
                        changeFlightSquawkTextfieldValue={changeFlightSquawkTextfieldValue}
                        actionDate={actionDate}
                        changeActionDateValue={changeActionDateValue}
                        correctiveAction={correctiveAction}
                        changeCorrectiveActionTextfieldValue={changeCorrectiveActionTextfieldValue}
                        confirmer={confirmer}
                        changeConfirmerTextfieldValue={changeConfirmerTextfieldValue}
                        flightReportErrorMessage={flightReportErrorMessage}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>

              {/** 受け取り人情報 */}
              <Grid item lg={6} md={6} sm={12} xs={12} >
                <Paper className={classes.paper} elevation={1}>
                  <Typography className={classes.title} variant="h6" component="div">
                    配送依頼情報
                  </Typography>
                  <TableContainer component={Paper} elevation={0}>
                    <Table className={classes.table} aria-label="simple table">
                      <TableBody>
                        {havePrivilege(FunctionPrivilege.PrivilegeFunctionLinkOrDeleteOrder, userInfo) ?
                          <TableRow >
                            {addedOrder.length === 0 ? (
                              <TableCell>このフライトで配送する依頼を設定してください</TableCell>
                            ) : (
                              <TableCell><Typography variant="h5" color="secondary">合計重量：{totalPayload()}</Typography></TableCell>
                            )}
                            <TableCell component="th" scope="row"></TableCell>
                            {/** 既に配送情報が紐づけられている場合、配送情報追加ボタンを表示しない */}
                            {addedOrder.length === 0 ?
                              <TableCell align="right">
                                <Zoom in={isPreparationOrLater(flightInfo.status)} style={{ transitionDelay: '500ms' }}>
                                  <Fab color="primary" aria-label="Edit" size="small" onClick={handleFOOpenDialog}>
                                    <AddOutlinedIcon />
                                  </Fab>
                                </Zoom>
                              </TableCell>
                              : undefined}
                          </TableRow>
                          :
                          undefined
                        }
                        {addedOrder.length === 0 ? (
                          <TableRow >
                            <TableCell colSpan={3}>
                              <Alert severity="warning">配送依頼が設定されていません</Alert>
                            </TableCell>
                          </TableRow>) : null}
                        <TableRow >
                          <TableCell colSpan={3}>
                            {havePrivilege(FunctionPrivilege.PrivilegeFunctionLinkOrDeleteOrder, userInfo) ?
                              addedOrder.map((v) => (
                                <OrderCard key={v.orderID} order={v} onDelete={handleDeleteOrder} />
                              ))
                              :
                              addedOrder.map((v) => (
                                <OrderCard key={v.orderID} order={v} />
                              ))
                            }
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Grid>
            </Grid>
          }

          {/** 環境センサー情報 */}
          <EnvSensorInfoBar depPort={depPort} arrPort={arrPort} depPortName={depPort.name} arrPortName={arrPort.name} depPortDeviceId={depPort.envSensorDeviceID} arrPortDeviceId={arrPort.envSensorDeviceID} />

          {/** 新しい配送情報を作成するダイアログ */}
          <FlightOrderCombinationDialog addedOrder={addedOrder} onAddOrder={handleAddOrder} flight={flightInfo} isOpen={isFOCombinationDialogOpen} onClose={handleFOCloseDialog} />
          {/* チェックリストを表示するダイアログ */}
          <FlightCheckListDialog
            isOpen={isCheckListDialogOpen}
            setIsOpen={setCheckListDialogOpen}
            displayCheckListMap={displayCheckListMap}
            handleSave={handleSave}
          />

          <Backdrop className={classes.backdrop} open={isDisplayLoadingMark || isDisplayLoadingMarkForUserInfo}>
            <CircularProgress color="inherit" />
          </Backdrop>

          <ErrorDialog
            isOpen={isErrorDialogOpen}
            setIsOpen={setErrorDialogOpen}
            errorMessage={"権限がありません"}
          />
        </div>
      </main >
    </div >
  );
}