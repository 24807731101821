import { Dialog, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";
import { makeStyles } from 'tss-react/mui';
import React from "react";

type ErrorDialogProps = {
    isOpen: boolean,
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
    errorMessage: string
}

const useStyles = makeStyles()((theme: any) => ({
    dialogHeader: {
        color: 'black',
        margin: theme.spacing(1),
        whiteSpace: 'pre-wrap'
    },
    alert: {
        margin: theme.spacing(1)
    },
    scheduleTextField: {
        margin: theme.spacing(1)
    },
    remarksTextfield: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '96.4%'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    }
}));

export const ErrorDialog: React.FC<ErrorDialogProps> = (props) => {
    const { classes } = useStyles();

    const handleClickButton = () => {
        props.setIsOpen(false)
    };    

    return <Dialog open={props.isOpen} aria-labelledby="form-dialog-title">
        <DialogContent>
            <DialogContentText className={classes.dialogHeader}>
                {props.errorMessage}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClickButton} color="primary">
                OK
            </Button>
        </DialogActions>
    </Dialog>
}