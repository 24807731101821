import { Alert, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { DataPrivilegeData, FunctionPrivilegeData, GroupInfo, PrivilegeInfo } from 'adoms-common-lib/build/model/PrivilegeInfomation';
import React from 'react';
import { PrivilegeDetailTableRow } from '../molecule/PrivilegeDetailTableRow';

const useStyles = makeStyles()((theme: any) => ({
    tableContainer: {
        minWidth: 1024,
        width: "100%",
        tableLayout: "fixed",
        border: `1px solid ${theme.palette.divider}`,
    },
    tableCell: {
        border: `1px solid ${theme.palette.divider}`,
    },
}));

type privilegeDetailTableProps = {
    groupList: GroupInfo[];
    privilegeInfoList: PrivilegeInfo<FunctionPrivilegeData | DataPrivilegeData>[];
    privilegeListPerGroup: PrivilegeInfo<FunctionPrivilegeData | DataPrivilegeData>[][];
    errorMessage: string | undefined;
    isDisplayLoadingMark: boolean;
};

/**
 * 権限詳細情報テーブル表示用コンポーネント
 * @param props:参照用プロパティ
 */
export const PrivilegeDetailTable: React.FC<privilegeDetailTableProps> = (props) => {

    const { classes } = useStyles();

    /**
    * TableBodyを表示する
    * @returns TableBody
    */
    const displayTable = () => {

        if (props.privilegeInfoList.length !== 0) {
            return (
                <Table className={classes.tableContainer} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableCell}><b>権限</b></TableCell>
                            {props.groupList?.map((group, i) => (
                                <TableCell className={classes.tableCell} component="th" scope="row" align="center" key={group.id}><b>{group.data.displaySubId}</b></TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.privilegeInfoList.map((privilegeInfo, i) => (
                            <PrivilegeDetailTableRow
                                key={privilegeInfo.data.sortNumber}
                                masterPrivilege={privilegeInfo}
                                privilegeListPerGroup={props.privilegeListPerGroup}
                            />
                        ))}
                    </TableBody>
                </Table>
            );
        } else if (props.errorMessage) {
            return (
                <Table aria-label="simple table">
                    <TableBody>
                        <TableRow>
                            <Alert severity="error">{props.errorMessage}</Alert>
                        </TableRow>
                    </TableBody>
                </Table>
            );
        } else if (!props.isDisplayLoadingMark) {
            return (
                <Table aria-label="simple table">
                    <TableBody>
                        <TableRow>
                            <Alert severity="info">権限情報はありません</Alert>
                        </TableRow>
                    </TableBody>
                </Table >
            );
        } else {
            return null;
        }
    };

    return displayTable();
};