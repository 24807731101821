import { Alert, Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Divider, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { FlightInfo, getTotalWeight, OrderInfo } from 'adoms-common-lib';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { APIConnector } from "../../connector/APIConnector";

const useStyles = makeStyles()((theme: any) => ({
    root: {
        display: 'flex',
        backgroundColor: theme.palette.background.default,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },


    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },

    selectEmpty: {
        marginTop: theme.spacing(2),
    },

    deliveryTable: {
        minWidth: 1024,
    },

    flightInfoInputTable: {
        minWidth: 512,
    },

    newButton: {
        position: 'fixed',
        top: theme.spacing(9),
        right: theme.spacing(3),
    },

    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },


    select: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },

    stepper: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,

    },
    table: {
        minWidth: 100,
    },
}));



type FlightOrderCombinationDialogProps = {
    /**
     * 配送依頼を紐付けるフライト
     */
    flight: FlightInfo,
    /**
     * ダイアログがオープンしているかどうか
     */
    isOpen: boolean,
    /**
     * ダイアログをクローズする際のハンドラ
     */
    onClose: (event: any) => void,
    onAddOrder: (order: OrderInfo | undefined) => void,
    addedOrder: OrderInfo[],
}

const FlightOrderCombinationDialog: React.FC<FlightOrderCombinationDialogProps> = (props) => {
    const { classes } = useStyles();
    const [scroll, setScroll] = React.useState<DialogProps['scroll']>('body');
    const [isErrorFlag, setErrorFlag] = React.useState(false);
    const flight = props.flight as FlightInfo;
    const [orderList, setOrderList] = React.useState<OrderInfo[]>([]);
    const [selectedOrder, setSelectedOrder] = React.useState("");
    const order = orderList.find((v) => v.orderID === selectedOrder) as OrderInfo;
    const [isButtonDisable, setButtonDisable] = React.useState(true);

    /**
     * ダイアログをクローズする
     */
    const handleDialogClose = (event: any) => {
        props.onClose(event);
    };


    const handleChangeOrder = (event: any) => {
        setSelectedOrder(event.target.value);
        setButtonDisable(false);
    }

    const handleAddOrder = (event: React.MouseEvent<HTMLButtonElement>) => {
        props.onAddOrder(order);
        props.onClose(event);
        setSelectedOrder("");
        setButtonDisable(true);
        //fetchData();
    }




    useEffect(() => {
        const fetchData = async () => {
            let c: APIConnector = APIConnector.instance;
            /**
             * 配送依頼一覧を取得
             */
            const currentFlight: Promise<OrderInfo[]> = c.getOrderListForNoAssignedFlight()
            let allorderList = [] as OrderInfo[];
            await currentFlight.then((value: any) => {
                allorderList = value as OrderInfo[];
            });

            /**
             * 発着地に合う配送依頼のみのオーダーだけ取得
             */
            let filteredOrderList = allorderList.filter(
                (v) => (v.departure.id === flight.departure.id &&
                    v.arrival.id === flight.arrival.id
                )
            );

            /**
            * addedOrderに格納され、選択済みのものはリストから外す
            */
            filteredOrderList = filteredOrderList.filter(
                (v) => !props.addedOrder.some(
                    (v2) => v2.orderID === v.orderID
                )
            );

            setOrderList(filteredOrderList);
        }
        fetchData();
    }, [flight, props.isOpen]);

    return (
        <div className={classes.root}>
            {/** フライト新規作成ダイアログ */}
            <Dialog
                open={props.isOpen}
                onClose={handleDialogClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                style={{ zIndex: 1100 }}
            >
                <DialogTitle id="scroll-dialog-title">配送依頼選択<Divider /></DialogTitle>
                <DialogContent dividers={scroll === 'paper'}>
                    {isErrorFlag ? (
                        <Alert severity="error">配送依頼の取得中にエラーが発生しました。再試行してもエラーが発生する場合、システム管理者へご連絡ください</Alert>
                    ) : null}
                    {orderList.length === 0 ? (
                        <Alert severity="info">このフライトで配送可能な依頼がありませんでした。</Alert>
                    ) : (
                        <div>
                            <InputLabel className={classes.select} id="demo-simple-select-outlined-label">配送依頼</InputLabel>
                            <Select
                                variant="standard"
                                className={classes.textField}
                                labelId="drone"
                                onChange={handleChangeOrder}
                                value={selectedOrder}
                                id="order"
                                label="配送依頼">
                                {orderList.map((order: OrderInfo, i: number) => (
                                    <MenuItem key={order.orderID} value={order.orderID}>{order.preferredDeliveryDate + " " + order.preferredDeliveryTime + "" + "（" + order.orderID + "）"}</MenuItem>
                                ))}
                            </Select>
                        </div>
                    )}

                    {orderList.some((v) => v.orderID === selectedOrder) ? (
                        <TableContainer component={Paper} elevation={0}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableBody>
                                    <TableRow >
                                        <TableCell>ビジネスパートナーID</TableCell>
                                        <TableCell>{order.businessPartnerId}</TableCell>
                                    </TableRow>
                                    <TableRow >
                                        <TableCell>確認番号</TableCell>
                                        <TableCell>{order.orderID}</TableCell>
                                    </TableRow>
                                    <TableRow >
                                        <TableCell>外部連携ID</TableCell>
                                        <TableCell>{order.externalOrderID !== undefined ? order.externalOrderID : "設定なし"}</TableCell>
                                    </TableRow>
                                    <TableRow >
                                        <TableCell>配送希望時間</TableCell>
                                        <TableCell> {order.preferredDeliveryDate + " " + order.preferredDeliveryTime} </TableCell>
                                    </TableRow>
                                    <TableRow >
                                        <TableCell>受け取り人</TableCell>
                                        <TableCell>{order.destination.lastname + " " + order.destination.firstname}様</TableCell>
                                    </TableRow>
                                    <TableRow >
                                        <TableCell>ペイロード</TableCell>
                                        <TableCell>{getTotalWeight(order.goods)}g</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : null}

                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={handleAddOrder} disabled={isButtonDisable}>
                        選択
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
export default FlightOrderCombinationDialog;