import CancelIcon from '@mui/icons-material/Cancel';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import { Alert, Avatar, Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItemAvatar, ListItemButton, ListItemText, Typography } from '@mui/material';
import { blue } from '@mui/material/colors';
import { FlightInfo, FlightStatus, OrderInfo } from 'adoms-common-lib';
import React, { useEffect } from "react";
import { APIConnector } from '../../connector/APIConnector';
import { LoadingMark } from '../atoms/LoadingMark';

type FlightStatusChangeDialogProps = {
    isFlightStatusDialogOpen: boolean
    setFlightStatusDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
    selectedFlightInfo: FlightInfo
    setSavedFlightInfo: React.Dispatch<React.SetStateAction<boolean>>
    orderInfo?: OrderInfo
}

/**
 * フライトステータス変更ダイアログ
 * @param props 
 * @returns 
 */
export const FlightStatusChangeDialog: React.FC<FlightStatusChangeDialogProps> = (props) => {
    const flightStatusList: FlightStatus[] = [
        FlightStatus.OBStandby,
        FlightStatus.OBBeforeTakeoff,
        FlightStatus.OBTakeoff,
        FlightStatus.OBAfterLanding,
        FlightStatus.RTTakeoff,
        FlightStatus.RTAfterLanding,
        FlightStatus.Completed
    ];
    const [selectedFlightStatus, setSelectedFlightStatus] = React.useState<FlightStatus | undefined>(props.selectedFlightInfo.status);
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
    const [selectedFlightId, setSelectedFlightId] = React.useState<string | undefined>(undefined);
    const [isDisplayLoadingMark, setDisplayLoadingMark] = React.useState<boolean>(false);

    useEffect(() => {
        setSelectedFlightId(props.selectedFlightInfo.id);
        if (selectedFlightId !== props.selectedFlightInfo.id) {
            setSelectedFlightStatus(props.selectedFlightInfo.status)
        };
    }, [props.selectedFlightInfo]);

    const flightStatusAvatar = (flightStatus: FlightStatus, avatarValue: JSX.Element | number) => {
        return (
            <ListItemButton
                key={flightStatus}
                onClick={() => setSelectedFlightStatus(flightStatus)}>
                <ListItemAvatar>
                    <Avatar sx={{ bgcolor: selectedFlightStatus === flightStatus ? blue[500] : undefined, width: 30, height: 30 }}>{avatarValue}</Avatar>
                </ListItemAvatar>
                <ListItemText primary={flightStatus} />
            </ListItemButton>
        )
    }

    const handleFlightStatusDialogClose = () => {
        setSelectedFlightStatus(props.selectedFlightInfo.status);
        props.setFlightStatusDialogOpen(false);
    };

    /**
     * フライトステータスを更新する
     * @param newFlightInfo 
     */
    const handleUpdateFlightInfo = () => {
        const saveFlightInfo = async () => {
            if (selectedFlightStatus) {
                setDisplayLoadingMark(true);
                setErrorMessage(undefined);

                let newFlightInfo = structuredClone(props.selectedFlightInfo);
                newFlightInfo.status = selectedFlightStatus;

                let orderIdList = new Array<string>();
                if (props.orderInfo) {
                    orderIdList.push(props.orderInfo.orderID);
                };

                let apiConnector: APIConnector = APIConnector.instance;
                await apiConnector.putFlightStatusChange(newFlightInfo, orderIdList).then(async value => {
                    props.setSavedFlightInfo(true);
                    props.setFlightStatusDialogOpen(false);
                    setDisplayLoadingMark(false);
                }).catch(error => {
                    console.log(error);
                    setErrorMessage("フライト情報を更新できませんでした。");
                    setDisplayLoadingMark(false);
                });
            };
        };
        saveFlightInfo();
    };

    return (
        <Dialog
            open={props.isFlightStatusDialogOpen}
            onClose={handleFlightStatusDialogClose}
        >
            <DialogTitle id="dialog-title">
                フライトステータス選択
            </DialogTitle>
            <DialogContent sx={{ paddingBottom: 0 }}>
                {errorMessage ?
                    <Alert severity="error" >{errorMessage}</Alert>
                    : undefined
                }
                <List>
                    {flightStatusList.map((flightStatus, index) => (
                        flightStatusAvatar(flightStatus, index + 1)
                    ))}
                    {flightStatusAvatar(FlightStatus.Canceled, <CancelIcon />)}
                    {flightStatusAvatar(FlightStatus.Irregular, <ReportGmailerrorredIcon />)}
                </List>
                <LoadingMark isDisplayLoadingMark={isDisplayLoadingMark} />
            </DialogContent>
            <DialogActions>
                <Button
                    color="secondary"
                    disabled={selectedFlightStatus === props.selectedFlightInfo.status}
                    onClick={handleUpdateFlightInfo}>
                    登録
                </Button>
            </DialogActions>
        </Dialog>
    )
}