import { Grid, Typography } from '@mui/material';
import React from 'react';

type DisplayInfoGridProps = {
    icon?: any
    title?: string
    value: any
    xl?: number
    lg?: number
    md?: number
    sm?: number
    xs?: number
};

/**
 * 詳細情報を表示するGrid
 * @param props 
 */
export const DisplayInfoGrid: React.FC<DisplayInfoGridProps> = (props) => {
    if (props.icon) {
        return (
            <Grid item xl={props.xl ?? 3} lg={props.lg ?? 3} md={props.md ?? 4} sm={props.sm ?? 4} xs={props.xs ?? 6}>
                <Grid item container direction="row" alignItems={"center"}>
                    <Grid item>
                        {props.icon}
                    </Grid>
                    <Grid item textAlign={"left"}>
                        <Typography variant="caption" color="textSecondary" component="div" style={{ marginLeft: "3px" }}>
                            {props.title}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item textAlign={"left"}>
                    <Typography variant="subtitle1" color="textPrimary" component="div" style={{ marginLeft: "23px" }}>
                        {props.value}
                    </Typography>
                </Grid>
            </Grid>
        )
    } else {
        return (
            <Grid item xl={props.xl ?? 3} lg={props.lg ?? 3} md={props.md ?? 4} sm={props.sm ?? 4} xs={props.xs ?? 6}>
                <Grid item textAlign={"left"}>
                    <Typography variant="caption" color="textSecondary" component="div">
                        {props.title}
                    </Typography>
                </Grid>
                <Grid item textAlign={"left"}>
                    <Typography variant="subtitle1" color="textPrimary" component="div">
                        {props.value}
                    </Typography>
                </Grid>
            </Grid>
        )
    }
}