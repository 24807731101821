import { GeoInformation } from "adoms-common-lib";

export type AmplifyGeoOptions = {
    maps?: {
        neutral: string,
        satellite: string
    };
    region: string;
};

export type AmplifyGeoConfig = {
    geo?: {
        amazon_location_service?: AmplifyGeoOptions;
        AmazonLocationService?: AmplifyGeoOptions;
    };
};

export type MinMaxLanLng = {
    minLat: number,
    maxLat: number,
    minLng: number,
    maxLng: number
};


export type GeoInformationMap =
    Map<string, {
        last1MinuteGeoInformations?: GeoInformation[] | undefined;
        last8HoursGeoInformation?: GeoInformation | undefined;
    }>;

/**
 * 位置情報のアイコンの色の明るさを決める
 */
export const getLocationIconColorBrightness = (index: number): number => {
    let colorBrightness
    if (index === 0) {
        colorBrightness = 0;
    } else if (index === 1 || index === 2) {
        colorBrightness = 0.2;
    } else if (index === 3 || index === 4) {
        colorBrightness = 0.3;
    } else if (index === 5 || index === 6) {
        colorBrightness = 0.4;
    } else {
        colorBrightness = 0.5;
    };
    return colorBrightness;
};