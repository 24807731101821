import { Alert, Theme, Avatar, Backdrop, CircularProgress, Container, Popover, Typography } from "@mui/material";
import { makeStyles } from 'tss-react/mui';
import { BusinessPartnerInfo, OperationPartnerInfo, PartnerKind, UserInfo } from "adoms-common-lib";
import React from "react";

const useStyles = makeStyles()((theme: Theme) => ({
    dialogHeader: {
        color: 'black',
        margin: theme.spacing(1),
        whiteSpace: 'pre-wrap'
    },
    alert: {
        margin: theme.spacing(1),
        marginBottom: '20px',
    },
    scheduleTextField: {
        margin: theme.spacing(1)
    },
    remarksTextfield: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '96.4%'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    main: {
        margin: theme.spacing(2),
        padding: '12px'
    },
    container: {
        padding: '12px'
    },
    avater: {
        margin: 'auto',
        marginBottom: '10px',
        alignItems: 'center'
    },
    nameTypography: {
        whiteSpace: 'nowrap',
        fontWeight: 600
    },
    emailTypography: {
        whiteSpace: 'nowrap',
        marginBottom: '20px'
    },
    userDetailTitleTypography: {
        whiteSpace: 'nowrap',
        margin: 'auto',
        fontWeight: 600
    },
    userDetailTypography: {
        whiteSpace: 'nowrap',
        margin: 'auto'
    }
}));

type ProfilePopoverProps = {
    userInfo: UserInfo | undefined,
    handleCloseProfile: () => void,
    anchorEl: HTMLElement | null,
    isUserInfoError: boolean
}

export const ProfilePopover: React.FC<ProfilePopoverProps> = (props) => {
    const { classes } = useStyles();

    /**
     * 表示するパートナーIDを設定する
     * @returns パートナー種別、パートナーID
     */
    const displayPartnerId = () => {
        if (props.userInfo?.userPartnerInfo.partnerKind === PartnerKind.Operation) {
            return "オペレーションパートナー： " + (props.userInfo.userPartnerInfo.partnerInfo as OperationPartnerInfo).operationPartnerId
        } else if (props.userInfo?.userPartnerInfo.partnerKind === PartnerKind.Business) {
            return "ビジネスパートナー： " + (props.userInfo.userPartnerInfo.partnerInfo as BusinessPartnerInfo).businessPartnerId
        } else {
            return "登録なし"
        }
    }

    return (
        <React.Fragment>
            <Popover
                className={classes.main}
                open={Boolean(props.anchorEl)}
                aria-labelledby="form-dialog-title"
                id="menu-appbar"
                anchorEl={props.anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                onClose={props.handleCloseProfile}>
                <Container className={classes.container}>
                    {props.isUserInfoError ?
                        <Alert className={classes.alert} severity="error" >ユーザー情報が取得できませんでした</Alert>
                        :
                        undefined
                    }
                    <Avatar className={classes.avater}>
                        {props.userInfo?.name?.substring(0, 1)}
                    </Avatar>
                    <Typography className={classes.nameTypography} align='center'>
                        {props.userInfo?.name ? props.userInfo?.name : "氏名設定なし"}
                    </Typography>
                    <Typography className={classes.emailTypography} align='center' variant="body2">
                        {props.userInfo?.email}
                    </Typography>
                    <Typography className={classes.userDetailTitleTypography} variant="caption" paragraph>
                        パートナー情報
                    </Typography>
                    <Typography className={classes.userDetailTypography} variant="caption" paragraph>
                        {displayPartnerId()}
                    </Typography>
                    <Typography className={classes.userDetailTitleTypography} variant="caption" paragraph>
                        所属グループ
                    </Typography>
                    {(typeof props.userInfo?.groupInfoList !== "undefined" && props.userInfo.groupInfoList?.length !== 0) ?
                        (props.userInfo.groupInfoList?.map((groupInfo, i) =>
                            <Typography key={i} className={classes.userDetailTypography} variant="caption" paragraph>
                                {groupInfo.data.displaySubId}
                            </Typography>
                        ))
                        : ("登録なし")}
                    <Backdrop className={classes.backdrop} open={!props.userInfo && !props.isUserInfoError}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </Container>
            </Popover>
        </React.Fragment>
    )
}