import { Alert, Avatar, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { APIConnector } from "../../connector/APIConnector";
import { UserInfo } from 'adoms-common-lib';
import axios from 'axios';
import React from 'react';

const useStyles = makeStyles()((theme: any) => ({
    list: {
        padding: "16px 24px"
    },
    alert: {
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2)
    },
}));

type UserDeleteDialogProps = {
    isUserDeleteDialogOpen: boolean;
    handleClickUserDeleteDialogClose: () => void;
    selectedUserInfoList: UserInfo[];
    setSelectedUserInfoList: React.Dispatch<React.SetStateAction<UserInfo[]>>;
    setSaveUserInfo: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UserDeleteDialog: React.FC<UserDeleteDialogProps> = (props) => {
    const { classes } = useStyles();
    const [userDeleteErrorMessage, setUserDeleteErrorMessage] = React.useState<string | undefined>();

    /**
     * 削除ボタンを押した時
     */
    const handleClickUserDeleteButton = () => {
        const apigw: APIConnector = APIConnector.instance;
        let subList = [];
        for (let selectedUserInfo of props.selectedUserInfoList) {
            subList.push(selectedUserInfo.sub);
        };
        apigw.deleteUserInfoList(subList).then(() => {
            // ユーザー一覧を更新する
            props.setSaveUserInfo(true);

            // selectedUserInfoListを空にする
            props.setSelectedUserInfoList([]);

            // ダイアログを閉じる
            props.handleClickUserDeleteDialogClose();

        }).catch((error) => {
            if (axios.isAxiosError(error)
                && typeof error.response !== "undefined"
                && error.response.status === 403) {
                setUserDeleteErrorMessage("ユーザー削除の権限がありません");
            } else {
                setUserDeleteErrorMessage("ユーザー削除に失敗しました");
            };
        });
    };

    /**
     * ダイアログを閉じた時
     */
    const handleCloseUserDeleteDialog = () => {
        setUserDeleteErrorMessage(undefined);
        props.handleClickUserDeleteDialogClose();
    }

    return (
        <div>
            <Dialog
                open={props.isUserDeleteDialogOpen}
                onClose={handleCloseUserDeleteDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"下記のユーザーを削除しますか？"}</DialogTitle>
                {userDeleteErrorMessage ?
                    <Alert
                        severity="error"
                        className={classes.alert}>{userDeleteErrorMessage}</Alert>
                    : undefined
                }
                <List className={classes.list}>
                    {props.selectedUserInfoList.map((userInfo) => (
                        <ListItem key={userInfo.sub}>
                            <ListItemAvatar>
                                <Avatar>
                                    {userInfo.name.substring(0, 1)}
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={userInfo.name} />
                        </ListItem>
                    ))}
                </List>
                <DialogActions>
                    <Button onClick={handleCloseUserDeleteDialog} color="primary" autoFocus>
                        いいえ
                    </Button>
                    <Button onClick={handleClickUserDeleteButton} color="secondary">
                        はい
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};