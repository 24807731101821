import { Button, Container, FormControl, InputLabel, MenuItem, Select, Typography, Grid, CircularProgress, Backdrop, Paper, Alert } from "@mui/material";
import CssBaseline from '@mui/material/CssBaseline';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import OperationMenuBar from "../../components/organisms/OperationMenuBar";
import { InitMode } from "adoms-common-lib";
import { APIConnector } from "../../connector/APIConnector";
import axios from 'axios';

const drawerWidth = 240;

const useStyles = makeStyles()((theme: any) => ({
    root: {
        display: 'flex',
        backgroundColor: theme.palette.background.default
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth
    },
    /**
     * メニューヘッダー
     */
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    main: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(2),
        textAlign: 'center'
    },
    typography: {
        whiteSpace: "break-spaces"
    },
    formControl: {
        minWidth: 200,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    errorMessage: {
        padding: "10px",
        margin: "10px"
    }
}));

export default function AdminView() {
    const { classes, cx } = useStyles();

    const modeList = [InitMode.KUMEJIMA];
    const [selectMode, setSelectMode] = React.useState<InitMode>(InitMode.KUMEJIMA);
    const [isMenuOpen, setMenuOpen] = React.useState(false);
    const [isCallingInit, setIsCallingInit] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>();
    /**
     * メニューバーが開かれたときのハンドラ
    */
    const handleDrawerOpen = () => {
        setMenuOpen(true);
    };

    /**
     * メニューバーが閉じられた時のハンドラ
     */
    const handleDrawerClose = () => {
        setMenuOpen(false);
    };

    /**
     * 表示するパートナーIDが変更された時のハンドラ
     */
    const handleChangePartnerId = (event: any) => {
        setSelectMode(event.target.value);
    };

    const handleSetClick = () => {
        let apigw: APIConnector = APIConnector.instance;
        setIsCallingInit(true)
        apigw.init(selectMode).then(result => {
            setErrorMessage(undefined);
        }).catch(error => {
            console.log(error);
            console.log('error catch');
            if (
                axios.isAxiosError(error) &&
                error.response != null
            ) {
                setErrorMessage("エラーが発生しました。ログを確認してください。エラーコード: " + error.response.status);
            } else {
                setErrorMessage("不明なエラーが発生しました。ログを確認してください");
            }

        }).finally(() => {
            setIsCallingInit(false)
        })
    }

    return (
        <div className={classes.root}>
            <CssBaseline />
            {/**メニューバーを表示する */}
            <OperationMenuBar
                onChangeDrawerOpen={handleDrawerOpen}
                onChangeDrawerClose={handleDrawerClose}
                open={isMenuOpen}
                title="管理用ページ"
            />

            {/** コンテンツ部分 */}
            <main
                className={cx(classes.content, {
                    /** メニューバーがオープン・クローズされたときのスタイルの変更*/
                    [classes.contentShift]: isMenuOpen,
                })}
            >
                <div className={classes.drawerHeader} />
                {errorMessage != null ? (
                    <Paper className={classes.errorMessage} elevation={1}>
                        <Alert severity="error">{errorMessage}</Alert>
                    </Paper>
                ) : 
                (undefined)}
                <Container component="main" className={classes.main} maxWidth="sm">
                    <FormControl className={classes.formControl} variant="filled" size="small">
                        <InputLabel id="selectModeLabel">MODE</InputLabel>
                        <Select
                            variant="filled"
                            label="MODE"
                            value={selectMode}
                            onChange={handleChangePartnerId}>
                            {modeList.map((mode: string, i: number) => (
                                <MenuItem value={mode}>
                                    {mode}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={handleSetClick}
                        sx={{
                            wigth: "auto",
                            height: "48px",
                            marginLeft: "6px"
                        }}
                    >
                        設定
                    </Button>
                </Container>
                <Backdrop
                    className={classes.backdrop}
                    open={isCallingInit}>
                    <CircularProgress color="inherit" />
                </Backdrop>

            </main>
        </div>
    );
}