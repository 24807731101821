import React from 'react';
import { Alert, AlertTitle, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { GoodsInfo, OrderInfo } from "adoms-common-lib"

const useStyles = makeStyles()((theme: any) => ({
    table: {
        minWidth: 100,
    },
    title: {
        flexGrow: 1,
        textAlign: 'center',
        backgroundColor: theme.palette.primary.main,
        color: 'white',
    },
}));



/**
 * 合計重量
 * @param goodsList 配送物リスト
 */
function getTotal(goodsList: Array<GoodsInfo>): any {
    let weight = 0;
    let count = 0;
    for (let goods of goodsList) {
        weight += goods.weight * goods.count;
        count += goods.count;
    }
    return { weight: weight, count: count };
}


type OrderGoodsTableProps = {
    order: OrderInfo,
}

/**
 * オーダーの商品一覧テーブルを表示する
 * @param props order:OrderInfo
 */
const OrderGoodsTable: React.FC<OrderGoodsTableProps> = (props) => {
    const { classes } = useStyles();
    const order = props.order as OrderInfo;
    return (
        <div>
            <TableContainer component={Paper} elevation={0}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell component={"th"} colSpan={2}><b>商品</b></TableCell>
                            <TableCell style={{ width: "30" }} align="right"><b>重量&nbsp;(g)</b></TableCell>
                            <TableCell style={{ width: "30" }} align="right"><b>個数</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            order.goods.map((row, i) => (
                                <TableRow key={i}>
                                    <TableCell scope="row" colSpan={2}>
                                        {row.name}
                                    </TableCell>
                                    <TableCell align="right">{row.weight}</TableCell>
                                    <TableCell align="right">{row.count}</TableCell>
                                </TableRow>))
                        }
                        <TableRow>
                            <TableCell colSpan={2} align="right"><b>合計</b></TableCell>
                            <TableCell align="right"><b>{getTotal(order.goods).weight}</b></TableCell>
                            <TableCell align="right"><b>{getTotal(order.goods).count}</b></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            {(props.order.remarks !== undefined && props.order.remarks !== "") ?
                (
                    <div style={{ textAlign: "left" }} >
                        <Alert style={{ marginTop: "5px", marginBottom: "12px" }} severity="info">
                            <AlertTitle><Typography variant="h6">備考</Typography></AlertTitle>
                            {props.order.remarks}
                        </Alert>
                    </div>
                ) : null
            }
        </div >
    );


}
export default OrderGoodsTable;