import { Alert, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { OrderInfo } from 'adoms-common-lib';
import React, { useEffect } from "react";
import { APIConnector } from '../../connector/APIConnector';

type OrderImageDialogProps = {
    isOrderImageDialogOpen: boolean
    setOrderImageDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
    displayOrderInfo: OrderInfo
}

/**
 * 注文内容画像表示ダイアログ
 * @param props 
 * @returns 
 */
export const OrderImageDialog: React.FC<OrderImageDialogProps> = (props) => {

    const [orderReceiptImageBase64, setOrderReceiptImageBase64] = React.useState<string>('');
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
    const [displayCircularProgress, setDisplayCircularProgress] = React.useState(false);

    useEffect(() => {
        const fetchOrderImage = async () => {
            if (props.displayOrderInfo.receiptFileName) {
                let apiConnector: APIConnector = APIConnector.instance;
                setDisplayCircularProgress(true);
                await apiConnector.getReciept(props.displayOrderInfo.orderID, props.displayOrderInfo.receiptFileName).then(response => {
                    setOrderReceiptImageBase64(response);
                    setErrorMessage(undefined);
                    setDisplayCircularProgress(false);
                }).catch(error => {
                    setErrorMessage("注文内容が取得できませんでした。");
                    console.log(error);
                    setDisplayCircularProgress(false);
                });
            };
        };
        if (props.isOrderImageDialogOpen) {
            fetchOrderImage();
        };
    }, [props.isOrderImageDialogOpen]);

    return (
        <Dialog
            open={props.isOrderImageDialogOpen}
            onClose={() => props.setOrderImageDialogOpen(false)}
        >
            <DialogTitle id="dialog-title">
                注文内容
            </DialogTitle>
            <DialogContent
                sx={{
                    paddingBottom: 0,
                    minWidth: 300,
                    minHeight: 100,
                    textAlign: "center"
                }}>
                {errorMessage ?
                    <Alert severity="error" >{errorMessage}</Alert>
                    : undefined
                }
                {displayCircularProgress ?
                    <Grid item xs={12} textAlign={"center"}>
                        <CircularProgress />
                    </Grid>
                    :
                    <img
                        id="receiptImage"
                        src={orderReceiptImageBase64}
                        style={
                            navigator.userAgent.match(/iPhone|Android.+Mobile/) ?
                                { width: '100%' } :
                                { width: '50%' }
                        }
                    />
                }
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={() => props.setOrderImageDialogOpen(false)}>
                    閉じる
                </Button>
            </DialogActions>
        </Dialog>
    );
};