import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, TextField } from '@mui/material';
import React from "react";

type UserCertificateNumEditDialogProps = {
    certificateNum: string;
    setCertificateNum: React.Dispatch<React.SetStateAction<string>>;
    isCertificateNumEditDialogOpen: boolean;
    handleCertificateNumEditDialogClose: () => void;
    certificateNumErrorMessage: string | undefined;
    checkCertificateNumSaveDisabled: () => boolean;
    handleConfirmCertificateNum: () => void
}

export const UserCertificateNumEditDialog: React.FC<UserCertificateNumEditDialogProps> = (props) => {

    /**
     * 技能証明書番号が変更された時
     * @param event 
     */
    const handleCertificateNumChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        props.setCertificateNum(event.target.value as string);
    };

    return (
        <Dialog
            open={props.isCertificateNumEditDialogOpen}
            onClose={props.handleCertificateNumEditDialogClose}>
            <DialogTitle
                sx={{ padding: "16px 24px 0px" }}
                id="dialog-title">技能証明書番号登録
                <Divider />
            </DialogTitle>
            <DialogContent
                id="dialog-content"
                style={{ padding: "16px 24px 20px" }}
            >
                <Grid container spacing={2} direction="column">
                    {props.certificateNumErrorMessage ?
                        <Grid item>
                            <Alert
                                severity="error">{props.certificateNumErrorMessage}</Alert>
                        </Grid>
                        : undefined
                    }
                    <Grid item>
                        <TextField
                            value={props.certificateNum}
                            onChange={event => { handleCertificateNumChange(event) }}
                            multiline
                            maxRows="2"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    color="secondary"
                    disabled={props.checkCertificateNumSaveDisabled()}
                    onClick={props.handleConfirmCertificateNum}
                >
                    登録
                </Button>
            </DialogActions>
        </Dialog >
    );
}