import { FlightInfo, flightStatusForCheckList } from "adoms-common-lib";
import { FlightCheckListItem } from "../pages/operation/flight/FlightDetailView";

/**
 * publicフォルダ内のCSVファイルを読み込むハンドラ
 * 指定されたフライトのドローンの機種のチェックリストを取得する
 * @param currentFlight 
 * @returns fdCheckListMap,landingSupporterCheckListMap,
 *          takeoffSupporterCheckListMap,nonNormalProcedureList
 */
export const csvReader = async (currentFlight: FlightInfo) => {
    let fdCheckListMap = new Map<string, FlightCheckListItem[]>();
    let landingSupporterCheckListMap = new Map<string, FlightCheckListItem[]>();
    let takeoffSupporterCheckListMap = new Map<string, FlightCheckListItem[]>();
    let nonNormalProcedureList: FlightCheckListItem[] = new Array();

    //並列処理実施のためPromise待機用の配列を準備
    let getCheckListPromiseList: Promise<void>[] = [];
    let decodeCheckListCSVtoArray = async (response: Response): Promise<FlightCheckListItem[]> => {
        let contentType = response.headers.get('Content-Type') || response.headers.get('content-Type');
        console.log(`contentType: ${contentType}`);
        if (response.status === 200 &&
            (
                contentType == null ||
                /application\/vnd.ms-excel*/.test(contentType) ||
                /text\/csv*/.test(contentType)
            )
        ) {
            //取得したファイルの中身をテキストとしてデコードする
            // let csvFileText = await response.text();
            const arrayBuffer = await response.arrayBuffer();
            const csvFileText = new TextDecoder('utf-8').decode(arrayBuffer);
            //後ろの行改行のみで分割する。/nが本文にも含まれるため/nだけで分割しない
            let csvFlightTextArray = csvFileText.split('\r\n');
            //ヘッダー部と最後の空行を取り除く
            csvFlightTextArray = csvFlightTextArray.slice(1, csvFlightTextArray.length - 1);
            let checkListArray: FlightCheckListItem[] = [];
            //本文をチェックリストのオブジェクトに対応するよう割り当てる
            //Mapのcallback内でエラーが発生した時、関数直下までエラーが戻らないのが課題
            csvFlightTextArray.map((row) => {
                let arraySplitLine = row.split(',');
                let flightCheckListItem: FlightCheckListItem = {
                    CheckList: arraySplitLine[0].replace(/"/g, ""),
                    Value: arraySplitLine[1].replace(/"/g, ""),
                    Input: arraySplitLine[2].toLowerCase() === 'true',
                    Critical: arraySplitLine[3].toLowerCase() === 'true'
                }
                checkListArray.push(flightCheckListItem);
            });

            return checkListArray;
        } else {
            throw new Error('404');
        }
    }

    /**
     * 下記fetch用のerrorHandler
     * 手動で振り分けた404エラーかどうかを判断し、
     * そうではない場合は関数の終端のPromise.allへエラーを投げる
     * @param err 
     */
    const errorHandler = (error: Error) => {
        if (error.message !== '404') {
            throw error;
        }
    }
    //配列内の要素を回すためfor~ofを使う
    for (let status of flightStatusForCheckList) {
        /*
         * fetch APIを使用してpublicフォルダ内のローカルファイルを取得、
         * thenブロックで後処理定義
         */
        //名前：PF-Next
        let getFdCheckListPromise = fetch(
            `checkList/FD/${currentFlight.drone.kind}/${currentFlight.drone.kind}_FD_${status}.csv`,
            {
                headers: {
                    'Accept': 'text/csv'
                }
            }
        ).then(async response => {
            let fdCheckListArray = await decodeCheckListCSVtoArray(response);
            if (fdCheckListArray.length !== 0) {
                fdCheckListMap.set(status, fdCheckListArray);
            }
        }).catch(reason => {
            errorHandler(reason);
        });

        let getLandingSuppoterCheckListPromise = fetch(
            `checkList/LandingSuppoter/${currentFlight.drone.kind}/${currentFlight.drone.kind}_LandingSuppoter_${status}.csv`,
            {
                headers: {
                    'Accept': 'text/csv'
                }
            }
        ).then(async response => {
            let landingSuppoterCheckListArray = await decodeCheckListCSVtoArray(response);
            if (landingSuppoterCheckListArray.length !== 0) {
                landingSupporterCheckListMap.set(status, landingSuppoterCheckListArray)
            }
        }).catch(reason => {
            errorHandler(reason);
        });

        let getTakeoffSuppoterCheckListPromise = fetch(
            `checkList/TakeoffSuppoter/${currentFlight.drone.kind}/${currentFlight.drone.kind}_TakeoffSuppoter_${status}.csv`,
            {
                headers: {
                    'Accept': 'text/csv'
                }
            }).then(async response => {
                let takeoffSuppoterCheckListArray = await decodeCheckListCSVtoArray(response);
                if (takeoffSuppoterCheckListArray.length !== 0) {
                    takeoffSupporterCheckListMap.set(status, takeoffSuppoterCheckListArray)
                }
            }).catch(reason => {
                errorHandler(reason);
            });
        //発行したPromiseを配列にセット
        getCheckListPromiseList.push(getFdCheckListPromise);
        getCheckListPromiseList.push(getLandingSuppoterCheckListPromise);
        getCheckListPromiseList.push(getTakeoffSuppoterCheckListPromise);
    };

    let getNonNormalProcedurePromise = fetch(
        `checkList/NonNormalProcedure/${currentFlight.drone.kind}/${currentFlight.drone.kind}_NonNormalProcedure.csv`,
        {
            headers: {
                'Accept': 'text/csv'
            }
        }
    ).then(async response => {
        let nonNormalProcedureArray = await decodeCheckListCSVtoArray(response);
        if (nonNormalProcedureArray.length !== 0) {
            nonNormalProcedureList = nonNormalProcedureArray;
        }
    }).catch(reason => {
        errorHandler(reason);
    });

    getCheckListPromiseList.push(getNonNormalProcedurePromise);

    /*
      全部終わるまで待ってから結果を確認する。
      単純な404以外のエラーが発生した時、
      catchブロックへ入る。
    */
    return await Promise.all(getCheckListPromiseList).then(() => {
        console.log('all success');
        return ({
            fdCheckListMap: fdCheckListMap,
            landingSupporterCheckListMap: landingSupporterCheckListMap,
            takeoffSupporterCheckListMap: takeoffSupporterCheckListMap,
            nonNormalProcedureList: nonNormalProcedureList
        })
    }).catch(reason => {
        let error = reason as Error;
        console.log(reason)
        if (error.message !== '404') {
            throw new Error('チェックリストを正しく取得できませんでした。管理者へお問い合わせください。');
        }
    });
};